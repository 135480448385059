import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-showpdf',
  templateUrl: './showpdf.component.html',
  styleUrls: ['./showpdf.component.scss']
})
export class ShowpdfComponent implements OnInit {
  fileName: string;

  constructor(private http : HttpClient) { }

  ngOnInit(): void {
    this.http.get('https://localhost:44335/api/PdfTest', {responseType: 'text'}).subscribe(res => this.handleData(res));
  }

  private handleData(data: string) {
    //console.log(data);
    this.fileName = data;
    window.open(this.fileName, '_blank');
  } 

}
