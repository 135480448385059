<div class="p-4">
  <h4 class="tg-top-h">Add Form</h4>
  <div *ngIf="tableView">
    <input type="text" class="form-control search-field rounded-pill" (keyup)="handleSearchFilter($event)" placeholder="Search..."/>
    <div class="table-container">
      <table mat-table class="tbl" [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="FormName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Form</th>
            <td mat-cell *matCellDef="let form">{{form.FormName}}</td>
        </ng-container>
        <ng-container matColumnDef="FormDesc">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
            <td mat-cell *matCellDef="let form">{{form.FormDesc}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" [class.active-row]="selectedForm.FormId === row.FormId" (click)="onSelectForm(row)"></tr>
      </table>
    </div>
  </div>
  <div class="upload-container" *ngIf="!tableView">
    <div class="form-group mb-1 col-12 col-md-6">
      <label for="EmailAddress" class="my-1">Form Name</label>
      <input type="text" required class="form-control" [(ngModel)]="selectedForm.FormName"/>
    </div>
    <div class="form-group mb-1 col-12 col-md-6">
      <label for="EmailAddress" class="my-1">Form Description</label>
      <textarea type="text" required class="form-control" cols="50" rows="3" [(ngModel)]="selectedForm.FormDesc"></textarea>
    </div>
    <div class="form-group mb-1 col-12 col-md-6">
      <label for="EmailAddress" class="my-1">Form is For</label>
      <select required class="form-select form-control" [(ngModel)]="selectedForm.WhoCaringFor">
        <option *ngFor="let option of whoCaringForOptions">{{option}}</option>
      </select>
    </div>
    <div class="form-group mb-1 col-12 col-md-6">
      <label for="EmailAddress" class="my-1">Location/Region Form Applies To</label>
      <input type="text" required class="form-control" [(ngModel)]="selectedForm.WhoGetsIt" placeholder="location, state, country, etc."/>
    </div>
    <div class="form-group mb-1 col-12 col-md-6">
      <label for="EmailAddress" class="my-1">Eligibility Criteria</label>
      <textarea type="text" required class="form-control" [(ngModel)]="selectedForm.EligibilityCriteria" cols="50" rows="3" placeholder="Hours, Hire Date, etc."></textarea>
    </div>
    <div *ngIf="!uploadedFile" id="drag-area" class="drag-file col-12 col-md-6 my-1" (dragover)="onDragOver($event)" (drop)="onDrop($event)" (dragleave)="onDragLeave($event)">
      <i class="fa-solid fa-arrow-up-from-bracket icon"></i>
      <h6>Drag and Drop File</h6>
      <h6>Or</h6>
      <button class="btn btn-outline-primary rounded-pill px-4 align-items-center" (click)="onChooseFile()">Browse Files</button>
      <input type="file" id="file-upload" hidden (change)="onInputChange($event)">
    </div>
    <div *ngIf="uploadedFile">
      <div class="form-group uploaded-form">
        <i class="fa-solid fa-file icon"></i>
        <div>{{uploadedFile.name}}</div>
        <i class="fa-solid fa-xmark exit" (click)="onRemoveUploadedFile()"></i>
      </div>
    </div>
  </div>
  <div class="mt-1 d-flex flex-column flex-sm-row justify-content-between">
    <button *ngIf="tableView" type="button" class="btn btn-link text-nowrap text-start" (click)="onSwitchTableView()"><i class="fa-solid fa-arrow-up-from-bracket me-2"></i>Upload new form</button>
    <button *ngIf="!tableView" type="button" class="btn btn-link" (click)="onSwitchTableView()"><i class="fa-solid fa-magnifying-glass me-2"></i>Search existing forms</button>
    <div class="d-flex justify-content-end" [class.invisible]="!(selectedForm.FormId || (uploadedFile && selectedForm.FormName && selectedForm.FormDesc))">
      <button class="btn btn-outline-primary rounded-pill me-2 px-4" (click)="onCancel()">Cancel</button>
      <button class="btn btn-primary rounded-pill px-4" (click)="onAddForm()">Add Form</button>
    </div>
  </div>
</div>