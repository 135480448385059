export class Package {
  PackageId: number;
  PackageName: string;
  CompanyId: number;
  PackageCost: number;
  ReminderDays: number;
  SeeData: string;
  CustomerHint: string;
  CustomerStartPage: number;
  AdminHint: string;
  AdminStartPage: number;
  CanCreate: string;
}

export class PackageForm {
  PackageFormId: number;
  PackageId: number;
  FormId: number;
  FormSeq: number;
  SendTo: string;
  SendType: string;
  DistributionList: string;
}

export class PackageForms {
  PackageId: number;
  PackageName: string;
  PackageFormId: number;
  FormSeq: number;
  FormId: number;
  FormName: string;
  CompanyId: number;
  SetupDone: number;
  SendTo: string;
  SendType: string;
  LineType: string;
  DocumentName: string;
}

export class PackageSetting {
  SettingId: number;
  PackageId: number;
  KeyName: string;
  ValueDesc: string;
}

export class Form {
    FormId: number;
    FormName: string;
    FormDesc: string;
    PackageId: number;
    SetupDone: number;
    SendTo: string;
    SendType: string;
    DocumentName: string;
    WhoCaringFor: string;
    WhoGetsIt: string;
    EligibilityCriteria: string;
    UploadedBy: string;
}

export class FieldGroup {
  GroupId: number;
  PackageId: number;
  PageSeq: number;
  GroupTitle: string;
  GroupDesc: string;
  TakerHint: string;
  TakerNextAction: string;
  ProgressTitle: string;
}


export class Field {
  FieldId: number;
  GroupId: number;
  FieldName: string;
  FieldDesc: string;
  FieldSeq: number;
  ColSeq: number;
  LayoutCols: number;
  FieldType: string;
  PdfField: string;
  DataMask: string;
  SizeLimit: number;
  ListValues: string;
  ListDisplays: string;
  ProcessingCode: string;
  SpecialUse: string;
  Placeholder: string;
  DefaultValue: string;
}

export class FieldData {
  FieldId: number;
  GroupId: number;
  FieldName: string;
  FieldDesc: string;
  FieldSeq: number;
  ColSeq: number;
  LayoutCols: number;
  FieldType: string;
  PdfField: string;
  DataMask: string;
  SizeLimit: number;
  ListValues: string;
  ListDisplays: string;
  ProcessingCode: string;
  SpecialUse: string;
  Placeholder: string;
  DefaultValue: string;
  DataId: number;
  OrderId: number;
  FieldValue: string;

  ListItems: FormListItem[];
  ItemIndex: number;
}

export class FieldDataCols {
  watchField: string = '';
  watchValue: string = '';
  hideThis: boolean = false;
  Columns: FieldData[];
}

export class FormListItem {
  ItemValue: string;
  ItemLabel: string;

  constructor(val: string, lbl: string) {
    this.ItemValue = val;
    this.ItemLabel = lbl;

    //console.log(this.ItemValue + ' ' + this.ItemLabel);
  }
}

export class FormFieldDataChange {
  fieldName: string;
  fieldValue: string;
}

export class ExternalList {
  ListId: number;
  ListCode: string;
  ListName: string;
}