<div>
    <app-appheader [selectedPic]="selectedHdr"></app-appheader>
    <div class="page-container">
        <div class="page-content container">
            <div class="d-flex justify-content-between align-items-center">
                <h4>{{userService.currentCompany.CustomerTitle}}s</h4>
                <div class="d-flex">
                    <input type="file" name="fileToUpload" id="file-upload" hidden accept="text/csv" (change)="handleFileInput($event.target.files)">
                    <div class="dropdown-center">
                        <button class="btn btn-primary rounded-pill px-3 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        + Add {{userService.currentCompany.CustomerTitle}}s
                        </button>
                        <ul class="dropdown-menu align-items-end">
                            <li><button class="dropdown-item text-end" (click)="newCustomer()">New {{userService.currentCompany.CustomerTitle}}</button></li>
                            <li><hr class="dropdown-divider"></li>
                            <li><button class="dropdown-item text-end" (click)="onChooseFile()">Upload {{userService.currentCompany.CustomerTitle}}s</button></li>
                            <li><a class="dropdown-item text-end text-secondary" href="../../../assets/ExpidocUserUploadSample.xlsx" target="_blank"><i class="fa-solid fa-download fa-sm"></i> Sample File</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <input type="text" class="form-control search-field rounded-pill mt-5" (keyup)="handleSearchFilter($event)" placeholder="Search..."/>
            <div class="table-container">
                <table mat-table class="tbl" [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="FirstName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
                        <td mat-cell *matCellDef="let employee">{{employee.FirstName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="LastName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
                        <td mat-cell *matCellDef="let employee">{{employee.LastName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="EmailAddress">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                        <td mat-cell *matCellDef="let employee">{{employee.EmailAddress}}</td>
                    </ng-container>
                    <ng-container matColumnDef="GroupDes">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Group</th>
                        <td mat-cell *matCellDef="let employee">{{employee.GroupDes}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="editCustomer(row.CustomerId)"></tr>
                </table>
            </div>
        </div>
    </div>
</div>
