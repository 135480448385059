import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CompanyService } from '../services/company.service';
import { LoaderService } from '../services/loader.service';

@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.scss']
})
export class GuestComponent implements OnInit {
  private sub: any;

  constructor(private route: ActivatedRoute, public companyService: CompanyService, public loaderService: LoaderService) { }

  ngOnInit(): void {
    this.companyService.logoLink = 'thanks';
    this.sub = this.route.params.subscribe(params => {
      let sid = params['id'];
      //this.loaderService.loadPage(sid.slice(0,5), sid.slice(5,10), '/pages/0');
      this.loaderService.checkTO(sid.slice(0,5), sid.slice(5,10), '/pages/0');
    });
  }
}
