import { Component } from '@angular/core';
import { Customer } from './models/customer';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { OrderService } from './services/order.service';
import { UserService } from './services/user.service';
import { ToastService } from './services/toast.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'formbuilder';
  currentUser: Customer;
  private sub: any;

  constructor(private router: Router, private route: ActivatedRoute, private authenticationService: AuthenticationService,
              public orderService: OrderService, private userService: UserService, public toastService: ToastService) {
      this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit(): void {
    this.sub = this.route.queryParams.subscribe(params => {
      let sid = params['guestid'];
      //console.log('guest id is: ' + sid);
      if(sid) {
        if(sid.length >= 1) {
          //console.log('redirecting to invite');
          this.router.navigateByUrl('invite/' + sid);
        }
      }

      let cid = params['compnew'];
      //console.log('compnew id is: ' + cid);
      if(cid) {
        let pid = params['pid'];
        //console.log('redirecting to compnew');
        this.router.navigateByUrl('compnew/' + cid + '/' + pid);
      }

      let trd = params['3rd'];
      //console.log('3rd is: ' + trd);
      if(trd) {
        this.router.navigateByUrl('3rdparty/test');
      }

      let gid = params['groupid'];
      //console.log('test GID is: ' + gid);
      if(gid) {
        this.router.navigateByUrl('grouppreview/' + gid);
      }
      
      let clid = '' + params['complogin'];
      //console.log('complogin is: ' + clid);
      if(clid) {
        if(clid.length > 5 && clid.substring(0,5) == 'rtsys') {
          this.userService.forceLogin(clid.substring(5)).then(value => {
            //console.log("complogin:" + value);
            if(value != null) {
              this.router.navigateByUrl('/dashboard/' + this.orderService.dashReturnId);
            }
          });
        }
      }

      let prid = params['resetpassword'];
      //console.log('test GID is: ' + gid);
      if(prid) {
        this.router.navigateByUrl('resetpassword/' + prid);
      }
    });
  }

}
