import { FormService } from "../services/form.service";

export class FormfieldCalc {

    calc(proc: string, formSvc: FormService): string {
        let rtn = '';

        let parts = proc.split(';');

        let fmla = parts[0];
        //console.log(fmla);

        if(parts.length > 1) {
            let cols = parts[1].split(',');
            
            for(let a = 0; a < cols.length; a++) {
                let f = 'v' + (a + 1);
                let v = formSvc.getvalue(cols[a]);
                fmla = fmla.replace(f, v);
                //console.log('calc ' + a + ' : ' + f + ' = ' + v);
            }
        }

        //console.log(fmla);
        rtn = eval(fmla);

        return rtn;
    }
}
