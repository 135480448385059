<app-appheader></app-appheader>
<div class="container">
    <div class="row">
        <!--div class="col-sm-10"-->
        <div class="col-sm-12">
            <div class="pages-body">
            <div class="pages-questions">
                <h3>{{formGroup.GroupTitle}}</h3>
                <p>{{formGroup.GroupDesc}}</p>
                <ng-container *ngFor="let cols of formService.cols">
                    <app-form-field-cols [cols]="cols"></app-form-field-cols>
                </ng-container>
                <br>
                <div class="form-group page-row-buttons">
                    <button [disabled]="loading" type="button" class="btn btn-primary" (click)="onNext()">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Next
                    </button>
                    &nbsp;
                    <button [disabled]="loading" type="button" class="btn btn-danger" (click)="onCancel()">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Cancel
                    </button>
                </div>
            </div>
            </div>
        </div>
        <!--div class="col-sm-2">
            <a class="ff-title-a" (click)="onOpenPdf()" title="View Document"><i class="far fa-file-pdf ff-title-fa"></i> View</a><br>
            <a class="ff-title-a" (click)="onDownloadPdf()" title="Download Document"><i class="fas fa-download ff-title-fa"></i> Download</a><br>
            <a class="ff-title-a" (click)="onDownloadPdf()" title="Print Document"><i class="fas fa-print ff-title-fa"></i> Print</a><br>
        </div-->
    </div>
</div>
