<div class="p-4 mb-5">
  <h4 class="tg-top-h">Manage Subscription</h4>
  <br>
  <form>
    <div class="sub-container" *ngFor="let sub of subscriptions">
      <label class="card sub-card" [class.sub-card-selected]="sub.SubscriptionId == companySubscription.SubscriptionId" (click)="onFormChange()">
        <input class="form-check-input hidden" #SubscriptionId="ngModel" [(ngModel)]="companySubscription.SubscriptionId" type="radio" [value]="sub.SubscriptionId" name="SubscriptionId">
        <div class="d-flex justify-content-between flex-column flex-md-row">
          <div class="text-left">
              <h6>{{sub.SubscriptionName}}</h6>
              <div class="text-secondary" [innerHtml]="sub.SubscriptionDesc"></div>
          </div>
          <div class="pt-3 pt-md-0 pe-0">
            <b>${{sub.SubscriptionPrice?.toFixed(0)}}</b>
            <div class="text-secondary">{{sub.ChargeFreq === 'M' ? '/month' : '/year'}}</div>
          </div>
        </div>
        <div class="d-flex justify-content-end my-1 text-end" *ngIf="sub.SubscriptionId == companySubscription.SubscriptionId">
          <button class="btn btn-link" (click)="onUnsubscribe()">Unsubscribe</button>
      </div>
      </label>
    </div>
    <div class="button-row">
      <button class="btn btn-outline-primary rounded-pill px-4 paddr" (click)="onCancel()" disabled>Cancel</button>
      <button class="btn btn-primary rounded-pill px-4 paddr" (click)="onUpdate()" disabled>Update</button>
    </div>
  </form>
</div>