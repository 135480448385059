import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { GenericResponse, Taker } from '../models/order';
import { AlertService } from '../services/alert.service';
import { FormService } from '../services/form.service';
import { OrderService } from '../services/order.service';

@Component({
  selector: 'app-next-taker',
  templateUrl: './next-taker.component.html',
  styleUrls: ['./next-taker.component.scss']
})
export class NextTakerComponent implements OnInit {
  taker: Taker;
  loading: boolean = false;
  submitted: boolean = false;
  sending: boolean = false;
  nextNotify: NextNotify;

  constructor(private http: HttpClient, private alertService: AlertService,
    public orderService:OrderService, public formService: FormService,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.taker = JSON.parse(JSON.stringify(this.formService.nextTaker));
    this.nextNotify = new NextNotify();
    this.nextNotify.NotifyBy = 'email';
  }

  onSubmit(form:NgForm) {
    this.alertService.clear();
    this.submitted = true;
    this.sending = true;

    // stop here if form is invalid
    if (form.invalid) {
      return;
    }

    this.loading = true;

    this.putTaker(this.taker).subscribe(
      res => {
      //this.alertService.success('Saved');
      this.formService.nextTaker = JSON.parse(JSON.stringify(this.taker));
      this.submitted = false;
      this.loading = false;

      let rb = this.nextNotify.NotifyBy;

      if(rb == 'email' || rb == 'both') {
        this.sendEmail();
      } else if (rb == 'fax' || rb == 'both') {
        this.sendFax();
      } else {
        this.router.navigate(['thanks']);
      }
    },
    error => {
      this.showError(error);
    });
  }

  sendEmail() {
    let eurl = `${environment.apiUrl}/Mail/invite/` + this.taker.TakerId;
    //console.log(eurl);
    this.http.get(eurl).toPromise().then(res => {
      let rb = this.nextNotify.NotifyBy;
      if (rb == 'fax' || rb == 'both') {
        this.sendFax();
      } else {
        //this.showError('You did not enter a number for the Doctor');
        this.router.navigate(['thanks']);
      }
    },
    error => {
      this.showError(error);
    });
  }

  sendFax() {
    let eurl = `${environment.apiUrl}/Mail/fax/` + this.orderService.currentOrderId + "/" + this.taker.FaxNumber;
    this.http.get(eurl).toPromise().then(res => {
      let resp = res as GenericResponse;
      if(resp.ReturnCode) {
        this.router.navigate(['thanks']);
      } else {
        this.showError(resp.DetailMessage);
      }
    },
    error => {
      this.showError(error);
    });
  }

  onBack() {
    this.router.navigate(['thanks']);
  }

  onReset() {
    this.taker = JSON.parse(JSON.stringify(this.formService.nextTaker));
  }
  
  showError(error: string) {
    this.alertService.error(error);
    this.loading = false;
    //this.sending = false;
  }

  putTaker(t: Taker) {
    return this.http.put(`${environment.apiUrl}/Taker/` + this.taker.TakerId, t);
  }
}

export class NextNotify {
  NotifyBy: string;
}
