import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  public empId: number = 0;
  public compId: number = 0;
  public hrId: number = 0;
  public supId: number = 0;
  public addrStep: number = 0;

  constructor() { }
}
