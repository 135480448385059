export class Customer {
  CustomerId: number;
  CompanyId: number;
  EmailAddress: string;
  PasswordCd: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  PhoneNbr: string;
  Address1: string;
  Address2: string;
  CityName: string;
  StateCd: string;
  ZipCd: string;
  Roles: string;
  GroupDes: string;
  ResetUqId: string;
  ResetRequestDate: Date;
  BirthDt: string;
  HireDt: string;
  WorkState: string;
  UnionName: string;
  SupervisorName: string;
  ActiveFlg: string;
  
  // not mapped
  Token: string;
}

export class Company {
  CompanyId: number;
  CompanyName: string;
  Address1: string;
  Address2: string;
  CityName: string;
  StateCd: string;
  ZipCd: string;
  ActiveFlg: string;
  ActivationCd: string;
  DiscountType: string;
  DiscountAmt: number;
  LogoFile: string;
  PasswordCd: string;
  CustomerGroupTitle: string;
  CustomerTitle: string;
  NewCustMethod: string;
  EmailDomain: string;
}

export class CompanyDistribution {
  CompanyDistId: number;
  CompanyId: number;
  DistributionGroupId: number;
  EmailAddress: string;
}

export class CompanyFormData {
  CompanyDataId: number;
  CompanyId: number;
  PdfField: string;
  FieldValue: string;
}

export class CompanyFieldXref {
  PdfField: string;
  FieldName: string;
  ListValues: string;
  ListDisplays: string;
}

export class CompanyLogin {
  CompanyLoginId: number;
  CompanyId: number;
  EmailAddress: string;
  FirstName: string;
  LastName: string;
  PhoneNbr: string;
  PasswordDes: string;
  AccessValues: string;
  PrimaryContact: string;
  ResetUqId: string;
  ResetRequestDate: Date;
  Token: string;
}

export class CompanySubscription {
  CompanySubscriptionId: number;
  SubscriptionId: number;
  CompanyId: number;
  StartDate: Date;
  EndDate: Date;
  CancelRequest: string;
}

export class Subscription {
  SubscriptionId: number;
  SubscriptionName: string;
  SubscriptionDesc: string;
  SubscriptionPrice: number;
  ChargeFreq: string;
}

export class CompanyDisp {
  CompanyId: number;
  CompanyName: string;
  DiscountType: string;
  DiscountAmt: number;
  LogoFile: string;
  CustomerGroupTitle: string;
  CustomerTitle: string;
  AccessValues: string;
  CompanyLoginId: number;
  Token: string;
}

export class DistributionGroup {
  DistributionGroupId: number;
  DistributionCode: string;
  DistributionName: string;
}

export class LoginParams {
  EmailAddress: string;
  PasswordCd: string;
}

export class CompanyOrders {
  PackageName: string;
  CustomerName: string;
  OrderId: number;
  ReferenceNo: string;
  Taker1: string;
  Taker2: string;
  Taker3: string;
  Taker1Status: string;
  Taker2Status: string;
  Taker3Status: string;
  Taker1Date: string;
  Taker2Date: string;
  Taker3Date: string;
  Taker1Email: string;
  Taker2Email: string;
  Taker3Email: string;
  FinalSent: string;
  DisplayCode: string;
}

export class UserCriteria
{
  CName: string;
}

export class GenericInt
{
  IntValue: number;
}

export class GenericLong
{
  LongValue: number;
}

export class GenericString
{
  ValueDes: string;
}

export interface DialogInputData {
  title: string;
  message: string;
  textValue: string;
  cancelOk: boolean;
  textRequired: boolean;
  cancelled: boolean;
  link: string;
  idx: number;
}

export class EmailMessage {
  Tos: string;
  CCs: string;
  Subject: string;
  Body: string;
  Template: string;
  Data: string;
  Attachment: string;
}

export class CalendarEventType {
  CalendarEventTypeId: number;
  CompanyId: number;
  EventTypeName: string;
  CalendarColor: string;
}

export class CompanyCalendarEventType {
  CalendarEventTypeId: number;
  CompanyId: number;
  EventTypeName: string;
  CalendarColor: string;
  Events: number;
}

export class CalendarEvent {
  CalendarEventId: number;
  CustomerId: number;
  CalendarEventTypeId: number;
  StartDate: Date;
  EndDate: Date;
  Comments: string;
}
export class CalendarFlatEvent {
  title: string;
  start: string;
  end: string;
  color: string;
  type: string;
  description: string;
  refid: number;
}