import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { WnWCompany } from '../models/work-well';
import { CompanyService } from '../services/company.service';
import { EmployeeService } from '../services/employee.service';

@Component({
  selector: 'app-wnwdemo',
  templateUrl: './wnwdemo.component.html',
  styleUrls: ['./wnwdemo.component.scss']
})
export class WnwdemoComponent implements OnInit {
  isError: boolean = false;
  isNewError: boolean = false;
  compCode: string;

  constructor(public companyService: CompanyService, private router: Router, private http: HttpClient, public empService: EmployeeService) { }

  ngOnInit(): void {
    this.companyService.showHeader = false;
  }

  onNewCase() {
    this.isNewError = false;
    console.log('compcode: ' + this.compCode);
    this.http.get(`${environment.apiUrl}/Employee/CompanyCode/` + this.compCode).toPromise().then(res => {
      let comps = res as WnWCompany[];
      console.log('found comps:' + comps.length);

      if(comps.length > 0) {
        let comp = comps[0];
        //console.log('found comp is ' + JSON.stringify(comp));

      //if(comp != null) {
        this.empService.compId = comp.Company_Id;
        //console.log('found comp is ' + JSON.stringify(comp));

        //this.router.navigate(['compnew/8/4']);
        this.router.navigate(['wnwemp']);
      } else {
        this.isNewError = true;
      }
    });
  }

  onSubmit() {
    /*let parms = new HttpParams().set('case', this.login.CaseId).set('birthdt', '');

    this.http.get(`${environment.apiUrl}/Employee/login/`, {params: parms}).toPromise().then(res => {
    });*/
  }
}
