import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { FieldData, FormFieldDataChange, FormListItem } from '../models/form';
import { OFormData } from '../models/order';
import { NgForm } from '@angular/forms';
import { FormService } from '../services/form.service';
import { DatePipe, formatDate } from '@angular/common';
import { SubscriptionLike as ISubscription } from "rxjs";
import { environment } from 'src/environments/environment';
import { FormfieldCalc } from '../helpers/formfield-calc';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss']
})
export class FormFieldComponent implements OnInit {
  formField: FieldData = new FieldData();
  ffDate: FfDate;
  sigField: FieldData;
  checkboxes: string[];
  //fieldForm: FormGroup;
  checked: boolean = false;
  isVisible: boolean = true;
  isDisabled: boolean = false;
  watcher: boolean = false;
  isRequired: boolean = false;
  maskMask: string = '';
  maskHide: string = 'false';
  maskDropSC: string = 'false';
  watchHasClear: boolean = false;
  watchField: string;
  watchVisibleValue: string = '';
  watchClearValue: string = '';
  watchIsNotFlag = '';
  changeSub: ISubscription;
  confirmAgainst: string = '';
  validationParams: string;
  fieldTextType: boolean = false;
  sigUrl: string = '../../assets/blank-sig.png';
  sigDateCol: string = '';
  cbxCols: string = '';
  syslist: Array<FormListItem>;

  @ViewChild('frm') frm: NgForm;

  @Input() index: number;
  @Input() public set fieldVisible(vis: boolean) {
    this.isVisible = !vis;
  }
  @Input() public set formFieldObj(value: FieldData) {
    this.formField = value;
    if(this.formField.FieldType == 'mask') {
      let pms = this.formField.DataMask.split('|');

      this.maskMask = pms[0];
      if(pms.length > 1) {
        if(pms[1] == 'Y') this.maskHide = 'true';
      }
      if(pms.length > 2) {
        if(pms[2] == 'Y') this.maskDropSC = 'true';
      }

      //console.log('mask: ' + this.maskMask + ' ' + this.maskHide + ' ' + this.maskDropSC);
    }

    if(this.formField.FieldType == 'date') {
      //console.log('date is ' + this.formField.FieldValue);
      this.ffDate.DateValue = new Date(this.formField.FieldValue  + ' 06:00:00 UTC');
    }

    if(this.formField.FieldType == 'signature') {
      this.sigField = value;
      //reqd|signdate:docsigdt
      let idx = this.formField.ProcessingCode.indexOf('signdate;');
      //console.log('sig date: ' + idx + ' from ' + this.formField.ProcessingCode);
      if(idx >= 0) {
        let rest = this.formField.ProcessingCode.substring(idx + 9);
        let idx2 = rest.indexOf('|');
        if(idx2 < 0) idx2 = rest.length;
        this.sigDateCol = rest.substring(0, idx2);
        //console.log('sig date: ' + rest + ' : ' + idx + ' : ' + idx2 + ' = ' + this.sigDateCol + ' from ' + this.formField.ProcessingCode);
      }
    }
    
    if(this.formField.FieldType == 'syslist') {
      if(this.formField.ListValues == 'state-code') this.syslist = this.formService.sysliststcd;
      if(this.formField.ListValues == 'state-name') this.syslist = this.formService.sysliststnam;
      if(this.formField.ListValues == 'state-codename') this.syslist = this.formService.sysliststcdnam;
      //console.log('syslist: ' + this.syslist.length + ' ' + this.syslist[0].ItemValue + ' ' + this.syslist[0].ItemLabel);
    }

    if(value.ListDisplays) this.checkboxes = new Array(value.ListItems.length);
    //console.log(value.FieldName + ': ' + value.ListDisplays);
    //if(value.ListDisplays) console.log( "   = " + value.ListItems.length);

    //if(this.formField.FieldType == 'time') this.formField.FieldValue = this.datePipe.transform(this.formField.FieldValue, 'HH:mm');
    
    let pc = this.formField.ProcessingCode;
    if(pc == null) pc = '';
    //console.log('pc: ' + this.formField.FieldId + ' ' + pc);
    if(pc.indexOf('reqd') >= 0) {
      let specs = pc.split('|');
      for(let a = 0; a < specs.length; a++) {
        let parts = specs[a].split(';');
        if(parts[0] == 'reqd') {
          this.isRequired = true;
          //console.log(this.formField.PdfField + ' is required');
          this.validationParams = specs[a];
        }
      }
      if(pc.indexOf('cols') >= 0)  {
        let specs = pc.split('|');
        for(let a = 0; a < specs.length; a++) {
          let parts = specs[a].split(';');
          if(parts[0] == 'cols') {
            if(parts[1] != '0') {
              this.cbxCols = 'col-sm-' + parts[1];
              //console.log('cbx cols is ' + this.cbxCols);
            }
          }
        }
      }
    }

    if(pc.indexOf('disabled') >= 0) this.isDisabled = true;

    if(pc.indexOf('confirm') >= 0) {
      let specs = pc.split('|');
      for(let a = 0; a < specs.length; a++) {
        let parts = specs[a].split(';');
        if(parts[0] == 'confirm') {
          this.confirmAgainst = parts[1];
        }
      }
    }
    
    if(pc.indexOf('visibleif') >= 0) {
      let specs = pc.split('|');
      for(let a = 0; a < specs.length; a++) {
        let parts = specs[a].split(';');
        if(parts[0] == 'visibleif') {
          if(parts[1] == 'source') {
            this.watcher = true;

            let ffdc = new FormFieldDataChange();
            ffdc.fieldName = this.formField.PdfField;
            ffdc.fieldValue = this.formField.FieldValue;
            
            this.formService.fieldUpdated(ffdc);
            //console.log('source ' + this.formField.PdfField + ' = ' + this.formField.FieldValue);
          } else {
            //console.log("checking for clear - " + parts.length);
            if(parts.length >= 3) {
              this.watchHasClear = true;
              this.watchField = parts[1];
              this.watchVisibleValue = parts[2];
              //this.watchClearValue = parts[3];
              this.watchIsNotFlag = parts[3];
              //console.log('    set');
            }
          }
        }
      }
    }
  }

  constructor(public formService: FormService, private datePipe: DatePipe, public http: HttpClient) {
    this.ffDate = new FfDate();
    /*this.changeSub = this.formService.output.subscribe(v => {
      if(v) {
          if(v.fieldName == this.formField.PdfField) {
            //console.log('changed: ' + this.watchField + ' = ' + v.fieldName + ' to ' +v.fieldValue);
            this.hideThis = !(v.fieldValue == this.watchValue)
          }
        }
      }
    });*/

   }

  ngOnInit(): void {
    //this.syslist = new Array<FormListItem>();

    this.formService.registerField(this.index, this);

    this.changeSub = this.formService.output.subscribe(v => {
      if(v) {
        if(this.watchHasClear) {
          //console.log('field changed: ' + v.fieldName + ' = ' + this.watchField);
          if(v.fieldName == this.watchField) {
            //console.log('    to ' + v.fieldValue + ' = ' + this.watchVisibleValue);
            /*if(v.fieldValue != this.watchVisibleValue) {
              //console.log('  value not as expceted - resetting to ' + this.watchClearValue);
              this.formField.FieldValue = this.watchClearValue;
              if(this.watcher) this.valueChanged(this.watchClearValue);
            }*/
          }
        }
      }
    });

    /*this.fieldForm = this.fb.group({
      cbxs: this.fb.array([])
    });*/

    //console.log(JSON.stringify(this.formField));
  }

  /*ngOnChanges(changes: SimpleChanges): void {
    if(this.watcher) {
      console.log(changes);
      if(changes.FieldData) {
        console.log(changes.FieldData.currentValue.FieldValue);
      }
    }
  }*/

  dateChanged() {
    if(this.ffDate.DateValue == null) {
      this.formField.FieldValue = null;  
    } else {
      this.formField.FieldValue = formatDate(this.ffDate.DateValue, 'MM/dd/yyyy', 'en_US');
    }

    //console.log('date changed to ' + this.formField.FieldValue);

    let ffdc = new FormFieldDataChange();
    ffdc.fieldName = this.formField.PdfField;
    ffdc.fieldValue = this.formField.FieldValue;
    
    this.formService.fieldUpdated(ffdc);
  }

  valueChanged(event) {
    if(this.watcher) {
      //console.log('changing: ' + this.formField.PdfField + ' : ' + event + ' = ' + this.formField.FieldValue); // logs model value
      let ffdc = new FormFieldDataChange();
      ffdc.fieldName = this.formField.PdfField;
      ffdc.fieldValue = event;
      
      this.formService.fieldUpdated(ffdc);
    }
}

  cbxChanged(val: string, isChecked: boolean) {
    //console.log(val + ' ' + isChecked);
    // set array value
    for(let a = 0; a < this.formField.ListItems.length; a++) {
      if(val == this.formField.ListItems[a].ItemValue) {
        //console.log('     ' + a);
        if(isChecked) {
          this.checkboxes[a] = val;
        } else {
          this.checkboxes[a] = '';
        }
      }
    }

    // load into field
    let rtn = '';
    for(let b = 0; b < this.checkboxes.length; b++) {
      if(this.checkboxes[b] != '' && this.checkboxes[b] != null) {
        if(rtn != '') rtn += ',';
        rtn += this.checkboxes[b];
      }
    }

    if(rtn.length > 0) rtn = ',' + rtn + ',';
    
    this.formField.FieldValue = rtn;

    this.valueChanged(rtn);
  }

  isValid(): boolean {
    // if the field is not visible, return true
    if (!this.isVisible) return true;

    // if nothing is entered, return true if the field is not required and false if required
    if (this.formField.FieldValue === '' || this.formField.FieldValue === null) return !this.isRequired;

    // if ngx-mask field is invalid, return false
    if (this.frm?.invalid) return false;

    // further validation for required fields
    if (this.isRequired) {
      let val = '' + this.formField.FieldValue;
      if (val == null || val == 'null') val = '';
  
      let parts = this.validationParams.split(';');
      if (parts.length > 1 && parts[1] == 'not') {
        if (val == parts[2]) {
          this.formField.FieldValue = null;
          return false;
        }
      }
  
      if (this.confirmAgainst == '') {
        return val.length > 0;
      } else {
        let vc = '' + this.formService.getvalue(this.confirmAgainst);
        if (vc == null || vc == 'null') vc = '';
  
        let rtn = vc.length > 0 && vc == val;
        if (!rtn) this.formField.FieldValue = null;
        return rtn;
      }
    }

    return true;
  }

  isValidPhone(): boolean {
    if (this.formField.FieldValue?.length === 0 || this.formField.FieldValue === null) return !this.isRequired;
    let re = new RegExp(/^(\d{10}|\d{3}-\d{3}-\d{4}|\(\d{3}\) \d{3}-\d{4}|\d{3}\.\d{3}\.\d{4}|\d{3} \d{3} \d{4})$/);
    return re.exec(this.formField.FieldValue) !== null;
  }

  getValue(): OFormData {
    let rtn = new OFormData();

    let val = this.formField.FieldValue;
    //console.log('value for: ' + this.formField.PdfField + ' = ' + this.formField.FieldValue); // logs model value

    if(this.formField.FieldType=='date' && val != null) val = JSON.parse(JSON.stringify(val)).substring(0, 10);

    rtn.DataId = this.formField.DataId;
    rtn.OrderId = this.formField.OrderId;
    rtn.FieldId = this.formField.FieldId;
    rtn.FieldValue = val;

    //console.log(rtn.FieldValue + ":" + rtn.OrderId);

    //let said = this.formField.SurveyAnswerId;
    /*if(! said) said = 0;

    rtn.SurveyAnswerId = said;
    rtn.SurveyQuestionId = this.item.SurveyQuestionId;
    rtn.SurveyUserId = this.item.SurveyUserId;
    rtn.AnswerDes = this.inputValue;*/

    return rtn;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

  button() {
    let cols = this.formField.ProcessingCode.split(';');
    if(cols[0] == 'calc') {
      let fc = new FormfieldCalc();
      let rtn = fc.calc(this.formField.Placeholder, this.formService)
      this.formService.setValue(cols[1], rtn);
    }
  }

  signed() {
    let sig = this.sigField.FieldValue;
    if(sig == '') return

    // make image
    let url = `${environment.apiUrl}/PDFGen/SigFile/` + encodeURIComponent(sig) + '/180/30';
    //console.log(url);
    this.sigUrl = url;

    
    //console.log('date signed col is ' + this.sigDateCol);
    if(this.sigDateCol != '') {
      let dt = formatDate(new Date(), 'MM/dd/yyyy', 'en_US');
      //console.log(' setting date signed to ' + dt);
      //this.formService.setValue(this.sigDateCol, dt);
      this.formService.setSigDateValue(this.sigDateCol, dt);
    }

    if(this.watcher) {
      //console.log('changing: ' + this.formField.PdfField + ' : ' + event); // logs model value
      let ffdc = new FormFieldDataChange();
      ffdc.fieldName = this.formField.PdfField;
      ffdc.fieldValue = sig;
      
      this.formService.fieldUpdated(ffdc);
    }
  }

  updateDateValue() {
    //console.log('updating date');
    this.ffDate.DateValue = new Date(this.formField.FieldValue  + ' 06:00:00 UTC');
  }

  validateField(): boolean {
    //console.log(this.formField.FieldName);
    this.checked = true;
    return this.isValid();
  }
}

export class FfDate {
  DateValue: Date;
}