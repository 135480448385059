import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmedValidator } from '../helpers/pw-validator';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Company, CompanyLogin, CompanySubscription } from 'src/app/models/customer';
import { DialogService } from '../services/dialog.service';
import { UserService } from '../services/user.service';

@Component({
    templateUrl: 'register.component.html',
    styleUrls: ['./register.component.scss'],
})

export class RegisterComponent {
    loading = false;
    userDetails: UntypedFormGroup = new UntypedFormGroup({});
    companyDetails: UntypedFormGroup = new UntypedFormGroup({});
    registerForm: UntypedFormGroup;
    company: Company = new Company();
    companyLogin: CompanyLogin = new CompanyLogin();
    userContinue = false;
    companyContinue = false;
    disclaimerContinue = false;
    payContinue = false;

    constructor(private http: HttpClient, private router: Router, private formBuilder: UntypedFormBuilder,
                public dialogService: DialogService, private userService: UserService) {
        this.userDetails = formBuilder.group({
            FirstName: ['', [Validators.required]],
            LastName: ['', [Validators.required]],
            Email: ['', [Validators.required, Validators.email]],
            PhoneNumber: ['', [Validators.required, Validators.minLength(10)]],
            Password: ['', [Validators.required, Validators.minLength(6)]],
            PasswordConfirmed: ['', [Validators.required]]
        }, {
            validator: [ConfirmedValidator('Password', 'PasswordConfirmed'), this.checkEmail()],
        });

        this.companyDetails = formBuilder.group({
            CompanyName: ['', [Validators.required]]
        });
    }

    get fUser() { return this.userDetails.controls }

    get fCompany() { return this.companyDetails.controls }

    checkEmail() {
        return (formGroup: UntypedFormGroup) => {
            const control = formGroup.controls['Email'];
            if (control.errors && !control.errors.emailExists) {
                return;
            }
            if (control.value !== '') {
                this.http.get(`${environment.apiUrl}/CompanyLogin/Validate/` + control.value).toPromise().then(res => {
                    if (res != 0) {
                        control.setErrors({ emailExists: true });
                    } else {
                        control.setErrors(null);
                    }
                });
            }
        };
    }

    onContinue(group: string) {
        if (group === 'user') {
            this.userContinue = true;
        } else if (group === 'company') {
            this.companyContinue = true;
        } else {
            this.disclaimerContinue = true;
        }
    }

    onHome() {
        this.router.navigate(['home']);
    }

    onSubmit() {
        this.loading = true;

        this.company.CompanyName = this.companyDetails.value.CompanyName;
        this.company.Address1 = null;
        this.company.Address2 = null;
        this.company.CityName = null;
        this.company.StateCd = null;
        this.company.ZipCd = null;
        this.company.ActiveFlg = 'V';
        this.company.ActivationCd = '-';
        this.company.DiscountType = '0';
        this.company.DiscountAmt  = 0.00;
        this.company.LogoFile = '';
        this.company.PasswordCd = '';
        this.company.CustomerGroupTitle = 'Group';
        this.company.CustomerTitle = 'Employee';
        this.company.NewCustMethod = 'A';
        this.company.EmailDomain = null;

        this.companyLogin.EmailAddress = this.userDetails.value.Email;
        this.companyLogin.FirstName = this.userDetails.value.FirstName;
        this.companyLogin.LastName = this.userDetails.value.LastName;
        this.companyLogin.PasswordDes = this.userDetails.value.Password;
        this.companyLogin.PhoneNbr = this.userDetails.value.PhoneNumber;
        this.companyLogin.AccessValues = 'L';
        this.companyLogin.PrimaryContact = 'Y';

        // Create Company
        this.http.post(`${environment.apiUrl}/Company/`, this.company).toPromise().then(res => {
            this.company = res as Company;
            this.companyLogin.CompanyId = this.company.CompanyId;

            // Create CompanyLogin
            this.http.post(`${environment.apiUrl}/CompanyLogin/Save/`, this.companyLogin).toPromise().then(res => {
                let companySubscription = new CompanySubscription();
                companySubscription.CompanyId = this.company.CompanyId;
                companySubscription.SubscriptionId = 1;
                companySubscription.StartDate = new Date();
                companySubscription.EndDate = new Date(new Date().setFullYear(companySubscription.StartDate.getFullYear() + 100));
                companySubscription.CancelRequest = 'N';

                // Company Subcsription
                this.http.post(`${environment.apiUrl}/CompanySubscription/`, companySubscription).toPromise().then(res => {
                    let url = 'https://freedommerchants.com/expidoc.php?amt=99.00&inum=S1C' + this.company.CompanyId;
                    window.open(url, '_blank');
                    this.payContinue = true;
                },
                error => {
                    this.dialogService.openMessageDialog('Company Subscription registration error', error);
                })
            },
            error => {
                this.dialogService.openMessageDialog('User registration error', error);
            })
        },
        error => {
            this.dialogService.openMessageDialog('Company registration error', error);
        })

        this.loading = false;
    }

}
