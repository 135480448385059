import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FullCalendarComponent } from '@fullcalendar/angular';
import { CalendarOptions, DateSelectArg, DatesSetArg, EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import { CalendarFlatEvent, Customer } from 'src/app/models/customer';
import { formatDate } from '@angular/common';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { DialogCalendarComponent } from '../dialog-calendar/dialog-calendar.component';
import { DialogEventTypeComponent } from '../dialog-event-type/dialog-event-type.component';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  currentStart: string;
  currentEnd: string;
  customers = new Array<Customer>();
  currentCustomerId: number;
  selectedHdr: string = 'calendar';
  showCal: boolean = false;
  userRole: string = this.userService.userRole;
  calendarOptions: CalendarOptions = { 
    headerToolbar: {
      left: 'title',
      right: 'today prev,next'
    },
    initialView: 'dayGridMonth',
    plugins: [dayGridPlugin, interactionPlugin],
    eventClick: this.handleEventClick.bind(this),
    select: this.handleDateSelect.bind(this),
    datesSet: this.handleDateChange.bind(this),
    weekends: true,
    themeSystem: 'bootstrap5',
    editable: true,
    selectable: true,
    eventStartEditable: false
  };


  @ViewChild('EmpCalendar') empCalendarObj: FullCalendarComponent;

  constructor(private http: HttpClient, public userService: UserService, private router: Router, public dialogCal: MatDialog) { }

  ngOnInit(): void {
    if(!this.userService.currentCompany && !this.userService.currentCustomer) this.router.navigateByUrl('/login');
    if(this.userService.currentCompany) this.getCustomers();
    else {
      this.currentCustomerId = this.userService.currentCustomer?.CustomerId;
      this.getEvents();
      this.showCal = true;
    }
  }

  getCustomers() {
    this.http.get(`${environment.apiUrl}/Customer/Company/` + this.userService.currentCompany.CompanyId).subscribe(res => {
      this.customers = res as Customer[];
      this.currentCustomerId = this.customers[0].CustomerId;
      this.getEvents();
      this.showCal = true;
    });
  }

  handleCustomerChange() {
    this.getEvents();
  }

  handleEventClick(clickInfo: EventClickArg) {
    const dialogRef = this.dialogCal.open(DialogCalendarComponent, { minHeight: '300px', minWidth: '40vw', data: { eventId: clickInfo.event.extendedProps.refid, customers: this.customers } });
    
    dialogRef.afterClosed().subscribe(() => {
      this.getEvents();
    });
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    const dialogRef = this.dialogCal.open(DialogCalendarComponent, { minHeight: '300px', minWidth: '40vw', data: { customers: this.customers, currentCustomerId: this.currentCustomerId, start: selectInfo.start, end: selectInfo.end } });
    
    dialogRef.afterClosed().subscribe(() => {
      this.getEvents();
    });
  }

  handleNewEvent() {
    const dialogRef = this.dialogCal.open(DialogCalendarComponent, { minHeight: '300px', minWidth: '40vw', data: { customers: this.customers, currentCustomerId: this.currentCustomerId} });
    
    dialogRef.afterClosed().subscribe(() => {
      this.getEvents();
    });
  }

  handleEditEventType() {
    const dialogRef = this.dialogCal.open(DialogEventTypeComponent, { minHeight: '100px', minWidth: '30vw' });
    
    dialogRef.afterClosed().subscribe(() => {
      this.getEvents();
    });
  }

  handleDateChange(arg: DatesSetArg) {
    this.currentStart = formatDate(arg.view.currentStart, 'yyyy-MM-dd', 'en_US');
    this.currentEnd = formatDate(arg.view.currentEnd, 'yyyy-MM-dd', 'en_US');
    
    this.getEvents();
  }

  getEvents () {
    if (this.currentCustomerId) {
      let params = new HttpParams().append("customerid", this.currentCustomerId).append("startdt", this.currentStart).append("enddt", this.currentEnd);
      this.http.get(`${environment.apiUrl}/CalendarEvent/Customer`, {params: params}).toPromise().then(res => {
        let events =  res as CalendarFlatEvent[];
        this.calendarOptions.events = events;
      });
    }
  }
}
