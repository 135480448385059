<!-- Header -->
<app-public-header></app-public-header>



<section class="home-banner">
  <div class="home-container">           
         <div class="row middle-content-row">              	
              <div class="col-cmn col-lg-6 col-md-6 col-sm-6 one">
                <div class="home-bnr-title">Expidoc <br />re-engineers the medical leave process</div>
                  <p>This comprehensive, user friendly approach saves HR, employees, and healthcare providers precious time</p>
              </div>                        	
              <div class="col-cmn col-lg-6 col-md-6 col-sm-6 two">   
              <img src="assets/bnr-img/home-bnr-right-img.png" class="bnr-right-img" />           
              </div>     
          </div>           
  </div>
</section>

<section class="home-row-two p-t-98 p-b-98">

  <div class="home-container">
  
  <div class="row header-titlerow">
         <div class="col-cmn col-lg-12 col-md-12 col-sm-12 text-center">
            <div class="home-page-title">Eliminate redundancy with custom questions that populate all leave forms with one answer</div>
         </div>
     </div>
  
     <div class="row">
     
         <div class="col-cmn col-lg-3 col-md-6 col-sm-12 service-box text-center">
             <div class="service-box-main">
                <a routerLink="/homewhy" fragment="efficient">
                  <div class="section-img">
                    <img src="assets/icon/home-icon-1.png" />
                   </div>
                  <div class="section-content">
                    <h2>Efficient</h2>
                   </div>
                </a>
              </div>
         </div>
         
         <div class="col-cmn col-lg-3 col-md-6 col-sm-12 service-box text-center">
             <div class="service-box-main">
                <a routerLink="/homewhy" fragment="intuitive">
                  <div class="section-img">
                    <img src="assets/icon/home-icon-2.png" />
                   </div>
                  <div class="section-content">
                    <h2>Easy</h2>
                   </div>
                </a>
              </div>
         </div>
         
         <div class="col-cmn col-lg-3 col-md-6 col-sm-12 service-box text-center">
             <div class="service-box-main">
                <a routerLink="/homewhy" fragment="secure">
                  <div class="section-img">
                    <img src="assets/icon/home-icon-3.png" />
                   </div>
                  <div class="section-content">
                    <h2>Secure</h2>
                   </div>
                </a>
              </div>
         </div>
         
         <div class="col-cmn col-lg-3 col-md-6 col-sm-12 service-box text-center">
             <div class="service-box-main">
                <a routerLink="/pricing">
                  <div class="section-img">
                    <img src="assets/icon/home-icon-4.png" />
                   </div>
                  <div class="section-content">
                    <h2>Affordable</h2>
                   </div>
                </a>
              </div>
         </div>
         
     </div>
     
  </div>
</section>


<section class="home-row-three p-t-98 p-b-98 graybg">

  <div class="home-container">
  
     <div class="row middle-content-row col-middle-gap">
         <div class="col-cmn col-lg-6 col-md-6 col-sm-12 one">
              <div class="section-img"><img src="assets/home-img-1.jpg" class="fullsize borderradius" /></div>
         </div>
         <div class="col-cmn col-lg-6 col-md-6 col-sm-12 two">
            <div class="home-page-title">Expidoc puts efficiency 1st</div>
            <div class="section-content home-mid-p">
                <p>This comprehensive, user friendly approach saves HR, employees, and healthcare providers precious time by circumventing paper forms and the mail/email system.</p>

                <p>Expidoc dramatically reduces the time from case initiation to making a determination, allowing your team and employees to know their leave status timely and expediently.</p>
              </div>
         </div>
     </div>
     
  </div>
</section>




<section class="home-row-reason p-t-98 p-b-98">

  <div class="home-container">
  
  <div class="row header-titlerow">
         <div class="col-cmn col-lg-12 col-md-12 col-sm-12 text-center">
            <div class="home-page-title">3 Reasons to Try Expidoc</div>
         </div>
     </div>
  
     <div class="row">
     
         <div class="col-cmn col-lg-4 col-md-4 col-sm-12 reason-box">
             <div class="reason-box-main">
                  <div class="reason-number">
                    <span>01</span>
                  </div>
                  <div class="section-content">
                    <h4>Accelerated Process</h4>
                    <br>
                    <table>
                      <tr>
                        <td class="rb-bul">&bull;</td>
                        <td class="rb-txt">Employee answers all necessary questions online when opening case – no paper forms to complete<br><br></td>
                      </tr>
                      <tr>
                        <td class="rb-bul">&bull;</td>
                        <td class="rb-txt">No waiting - Employer and Doctor receive only their specific customized questions via a link the same day<br><br></td>
                      </tr>
                      <tr>
                        <td class="rb-bul">&bull;</td>
                        <td class="rb-txt">Avoids mail and printing delays</td>
                      </tr>
                    </table>
                    <br>
                  </div>
              </div>
         </div>
         
         
         
         <div class="col-cmn col-lg-4 col-md-4 col-sm-12 reason-box">
             <div class="reason-box-main">
                  <div class="reason-number">
                    <span>02</span>
                   </div>
                  <div class="section-content">
                    <h4>Reduce Redundancy</h4>
                    <br>
                    <table>
                      <tr>
                        <td class="rb-bul">&bull;</td>
                        <td class="rb-txt">Expidoc asks the question once, and automatically populates the response across multiple forms; i.e. diagnosis<br><br></td>
                      </tr>
                      <tr>
                        <td class="rb-bul">&bull;</td>
                        <td class="rb-txt">The correct completed forms sent directly to the appropriate parties to make determinations</td>
                      </tr>
                    </table>
                   </div>
              </div>
         </div>
         
         
         <div class="col-cmn col-lg-4 col-md-4 col-sm-12 reason-box">
             <div class="reason-box-main">
                  <div class="reason-number">
                    <span>03</span>
                   </div>
                  <div class="section-content">
                    <h4>Get it Right THE FIRST TIME</h4>
                    <br>
                    <table>
                      <tr>
                        <td class="rb-bul">&bull;</td>
                        <td class="rb-txt">Expidoc ensures required information is answered in the correct format everytime<br><br></td>
                      </tr>
                      <tr>
                        <td class="rb-bul">&bull;</td>
                        <td class="rb-txt">Eliminates the need for Clarification Letters or follow-up for incomplete forms</td>
                      </tr>
                    </table>
                   </div>
              </div>
         </div>
         
         
         
     </div>
     
  </div>
</section>











<section class="footer-top-row green-bg">
  <div class="home-container">           
         <div class="row">              	
              <div class="col-cmn col-lg-12 col-md-12 col-sm-12 one text-center"> 
                <div class="footertop-txt">Re-Engineering The Form Management Process</div>                      
              </div>
          </div>           
  </div>
</section>



  <!--section class="hmBnr">
    <div class="bxslider">
        <div class="sliderMain">
          <div class="hmSliderImg">
            <img class="img-fluid " src="../../assets/home-banner.jpg" alt="banner">
          </div>
          <div class="hmSliderContent">
            <div class="container">
              <div class="hmSlidrinnr">
                <h1>Re-Engineering The Form Management Process</h1>
                <a class="btn" routerLink="/pricing">Start Now</a>
              </div>
            </div>
          </div>
        </div>  
    </div>
   </section>
   <section class="abt">
    <div class="container">
      <div class="row">
        <div class="col-md-6">
          <img class="img-fluid" src="../../assets/abt.png" alt="About Banner">
        </div>
        <div class="col-md-6">
          <h2>Expidoc re-engineers the medical leave process</h2>
          <p>This comprehensive, user friendly approach saves HR, employees, and healthcare providers precious time by circumventing paper forms and the mail/email system.</p>
          <p>Expidoc dramatically reduces the time from case initiation to making a determination, allowing your team and employees to know their leave status timely and expediently.</p>
        </div>
      </div>
    </div>
   </section>
   <section class="counts">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="countsInr">
            <h2>01</h2>
            <p>Accelerate the medical leave process with direct on-line forms sent to the employee, Human Resources and the treating healthcare provider, immediately upon eligibility verification avoiding mail delays and printer issues</p>
            <p>Each party receives only what they need to complete</p>
            <p><b>HR avoids seeing diagnosis Minimizing Risk</b></p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="countsInr">
            <h2>02</h2>
            <p>Reduce redundancy with custom questions that populate all leave forms with one answer</p>
          </div>
        </div>
        <div class="col-md-4">
          <div class="countsInr">
            <h2>03</h2>
            <p>Ensure required information is completed the first time saving valuable time and eliminating incomplete documentation</p>
          </div>
        </div>
      </div>
    </div>
   </section>
   <section class="abt2">
    <div class="container">
      <div class="row">
        <div class="col-md-7">
          <h3>Expidoc puts efficiency first using a cloud-based platform allowing an effortless process from form creation to employee, HR and physician submissions</h3>
        </div>
        <div class="col-md-5">
          <img class="img-fluid" src="../../assets/abt2.png" alt="About">
        </div>
      </div>
    </div>
   </section-->
   <!--section class="features">
    <div class="container">
      <div class="owl-carousel" id="featuresSlider">
        <div class="item">
          <div class="featureInnr">
            <div class="ficon">
              <img class="img-fluid" src="../../assets/icon1.png" alt="features icon">
            </div>
            <h3>Cloud Base</h3>
            <p>Google Ad campaigns are an effective target receptive audience they get you.</p>
          </div>
        </div>
        <div class="item">
          <div class="featureInnr">
            <div class="ficon">
              <img class="img-fluid" src="../../assets/icon2.png" alt="features icon">
            </div>
            <h3>Direct</h3>
            <p>Sends required paperwork direct to all parties when case is initiated</p>
          </div>
        </div>
        <div class="item">
          <div class="featureInnr">
            <div class="ficon">
              <img class="img-fluid" src="../../assets/icon3.png" alt="features icon">
            </div>
            <h3>Efficient</h3>
            <p>Streamline approach reduces the case determination cycle dramatically</p>
          </div>
        </div>
        <div class="item">
          <div class="featureInnr">
            <div class="ficon">
              <img class="img-fluid" src="../../assets/icon4.png" alt="features icon">
            </div>
            <h3>Environmentally Friendly</h3>
            <p>Eliminates the need for paper, printers, and postage</p>
          </div>
        </div>
        <div class="item">
          <div class="featureInnr">
            <div class="ficon">
              <img class="img-fluid" src="../../assets/icon1.png" alt="features icon">
            </div>
            <h3>Cloud Base</h3>
            <p>Google Ad campaigns are an effective target receptive audience they get you.</p>
          </div>
        </div>
      </div>
    </div>
   </section-->
<app-footer></app-footer>

<!--header>
    <div class="hero-text-box">
        <h1>Goodbye complicated forms.</h1><h1>Hello simple questions.</h1>
        <a class="btn btn-full js--scroll-to-plans" routerLinkActive="active" routerLink="/dashboard">Let's get started</a>
    </div>
</header-->
