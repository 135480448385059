import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { GenericResponse } from '../models/order';
import { Company } from '../models/customer';

@Component({
  selector: 'app-appheader',
  templateUrl: './appheader.component.html',
  styleUrls: ['./appheader.component.scss']
})
export class AppheaderComponent implements OnInit {
  logoFile: string = null;
  cssCal: string = 'na';
  cssLib: string = 'na';
  cssOrders: string = 'na';
  cssUser: string = 'na';
  custId: number = 0;

  @Input() public set selectedPic(pic: string) {
    if(pic == 'calendar') this.cssCal = 'selected';
    if(pic == 'lib') this.cssLib = 'selected';
    if(pic == 'orders') this.cssOrders = 'selected';
    if(pic == 'usrmgr') this.cssUser = 'selected';
  }

  constructor(private router: Router, public userService: UserService, public http: HttpClient) { }

  ngOnInit(): void {
    if (this.userService.userRole == 'customer') {
      this.custId = this.userService.currentCustomer.CustomerId;
    }
    this.getCompLogo();
  }

  getCompLogo() {
    if (this.userService.userRole == 'customer') {
      let url = `${environment.apiUrl}/Company/${this.userService.currentCustomer.CompanyId}`;
      this.http.get(url).toPromise().then(res => {
        const resp = res as Company;
        this.logoFile = resp.LogoFile;
        if(this.logoFile == null) this.logoFile = '';
      },
      error => {
        console.log('getCompLogo error: ' + error);
      });
    } else {
      this.logoFile = this.userService.currentCompany.LogoFile ? this.userService.currentCompany.LogoFile : null;
    }
  }

  logOut() {
    sessionStorage.clear();
    this.router.navigateByUrl('/home');
  }

  navTo(dest: string) {
    if (dest == 'dashboard') {
      dest = '/dashboard/' + this.custId;
    }
    this.router.navigateByUrl(dest);
  }

    onTest() {
        let url = `${environment.apiUrl}/Mail/sendFinal/66`;
        let params = new HttpParams().append('subject', 'Expidoc Forms').append('template', 'expidosendcattached.html');
        
        this.http.get(url, {params: params}).toPromise().then(res => {
            let resp = res as GenericResponse;
            //console.log('sendFull rtn: ' + resp.ReturnCode);
        },
        error => {
          console.log('sendFull error: ' + error);
        });
    }
}
