import { HttpClient, HttpParams } from "@angular/common/http";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { GenericResponse, PdfGen } from "../models/order";
import { OrderService } from "../services/order.service";
import { saveAs } from 'file-saver';

export class ContactTakers {
    steps: ContactTakerStep[];
    idx: number;
    http: HttpClient;
    orderSvc: OrderService;
    lastError: string;
    routeTo: string;
    pdfFile: string = '';
    showPdf: boolean = false;

    public contactCompleted = new Subject<boolean>();

    constructor(httpClient: HttpClient, orderService: OrderService) {
        this.http = httpClient;
        this.orderSvc = orderService;
        this.steps = new Array<ContactTakerStep>();
    }

    public addStep(method: string, url: string, params: HttpParams, details: string, route: string) {
        let s = new ContactTakerStep();
        s.MethodType = method;
        s.Url = url;
        s.QueryParams = params;
        s.MethodDetails = details;
        s.RoutePath = route;

        this.steps.push(s);
        //console.log('contact taker step added: ' + JSON.stringify(s));
    }

    public start() {
        this.idx = -1;
        this.lastError = '';
        this.routeTo = '';
        this.pdfFile = '';

        //console.log('contact taker start: ' + JSON.stringify(this.steps));

        this.nextStep();
    }

    nextStep() {
        this.idx++;
        //console.log('next step: ' + this.idx + ": " + this.steps[this.idx].MethodType + " = " + this.steps[this.idx].Url);

        if(this.idx < this.steps.length) {
            if(this.steps[this.idx].MethodType == 'DelPDF') {
                this.delOrderFile();
            } else if(this.steps[this.idx].MethodType == 'D') {
                this.sendFinal();
            } else if(this.steps[this.idx].MethodType == 'E') {
                this.sendEmail();
            } else if(this.steps[this.idx].MethodType == 'F') {
                this.sendFax();
            } else if(this.steps[this.idx].MethodType == 'L') {
                this.linkLetter();
            } else if(this.steps[this.idx].MethodType == 'PDF') {
                this.createOrderFile();
            } else if(this.steps[this.idx].MethodType == 'X') {
                this.stepDone(true);
            } else {
                this.lastError = 'Step type not found - ' + this.steps[this.idx].MethodType;
                this.stepDone(false);
            }
        }
    }

    stepDone(ok: boolean) {
        //console.log('ct step done:' + ok + ":" + this.lastError + ":" + this.idx + " of " + this.steps.length);
        if(ok) {
            if(this.idx + 1 == this.steps.length) {
                if(this.steps[this.idx].RoutePath != '') this.routeTo = this.steps[this.idx].RoutePath;
                
                if(this.showPdf && this.pdfFile.length > 0) this.viewPdf();
                
                this.contactCompleted.next(true);
            } else {
                this.nextStep();
            }
        } else {
            if(this.showPdf && this.pdfFile.length > 0) this.viewPdf();

            this.contactCompleted.next(false);
        }
    }

    linkLetter() {
        let step = this.steps[this.idx];

        const fileURL = `${environment.apiUrl}/PdfGen/showmail/` + this.orderSvc.currentOrderId + '/' + step.MethodDetails;
        window.open(fileURL, '_blank');
        this.stepDone(true);
        /*let pdf = 'Expidoc_' + this.orderSvc.currentOrderId + '-' + step.MethodDetails+ '.pdf';
        let url = `${environment.apiUrl}/PdfGen/showmail/` + this.orderSvc.currentOrderId + '/' + step.MethodDetails;
        this.orderSvc.getPdf(url).subscribe(data => {
          saveAs(data, pdf);
        });*/
    }

    sendFinal() {
        let step = this.steps[this.idx]; 
        let url = step.Url;
        let params = step.QueryParams;
        
        this.http.get(url, {params: params}).toPromise().then(res => {
            let resp = res as GenericResponse;
            //console.log('email rtn: ' + JSON.stringify(resp));
            if(resp.ReturnCode) {
                this.stepDone(true);
            } else {
                this.lastError = resp.DetailMessage;
                this.stepDone(false);      
            }
        },
        error => {
            this.lastError = error;
            this.stepDone(false);
        });
    }

    sendEmail() {
        let step = this.steps[this.idx]; 
        let url = step.Url;
        let params = step.QueryParams;
        
        if(step.MethodDetails == 'addpdf' && this.pdfFile != '') {
            //console.log('adding file param...');
            params = params.append('pdf', this.pdfFile);
        }

        //console.log('mail step: ' + JSON.stringify(step));
        //console.log('mail url: ' + url);
        //console.log('mail params: ' + JSON.stringify(params));
        //console.log('mail pdf: ' + this.pdfFile);
        

        this.http.get(url, {params: params}).toPromise().then(res => {
            let resp = res as GenericResponse;
            //console.log('email rtn: ' + JSON.stringify(resp));
            if(resp.ReturnCode) {
                this.stepDone(true);
            } else {
                this.lastError = resp.DetailMessage;
                this.stepDone(false);      
            }
        },
        error => {
          this.lastError = error;
          this.stepDone(false);
        });
    }

    sendFax() {
        let step = this.steps[this.idx];
        //console.log('sending fax with: ' + JSON.stringify(step));
        let url = step.Url; //+ '/' + this.pdfFile;
        let params = step.QueryParams;
        if(step.MethodDetails == "addpdf" && this.pdfFile != '') {
            //console.log('adding file param...');
            params = params.append('pdf', this.pdfFile);
        }
        //console.log('fax url: ' + url);
        //console.log('fax params: ' + JSON.stringify(params));

        this.http.get(url, {params: params}).toPromise().then(res => {
            let resp = res as GenericResponse;
            //console.log('fax rtn: ' + JSON.stringify(resp));
            if(resp.ReturnCode) {
                this.stepDone(true);
            } else {
                this.lastError = resp.DetailMessage;
                this.stepDone(false);      
            }
        },
        error => {
          this.lastError = error;
          this.stepDone(false);
        });
    }

    viewPdf() {
        let url = `${environment.apiUrl}/PdfGen/showpdf/` + this.pdfFile;
        this.orderSvc.getPdf(url).subscribe(data => {
          saveAs(data, this.pdfFile);
        });
    }

    createOrderFile() {
        //console.log('gen pdf url: ' + this.steps[this.idx].Url);
        this.http.get(this.steps[this.idx].Url).toPromise().then(res => {
            let rtn = res as PdfGen;
            //console.log('gen pdf rtn: ' + JSON.stringify(rtn));
            if(rtn.ResultCode == 1) {
                this.pdfFile = rtn.FileName;
                //console.log('pdffile set to ' + this.pdfFile);
                this.stepDone(true);
            } else {
                this.orderSvc.pageResult = false;
                this.orderSvc.pageText = rtn.ErrorMessage;
                this.lastError = rtn.ErrorMessage;
                this.stepDone(false);  
            }
        },
        error => {
            //console.log('gen pdf error: ' + JSON.stringify(error));
          this.lastError = error;
          this.stepDone(false);
        });
    }

    delOrderFile() {
        let url = this.steps[this.idx].Url;
        let pdf = this.pdfFile.substring(this.pdfFile.lastIndexOf('/') + 1);
        if(url == '') url = `${environment.apiUrl}/PdfGen/del/` + pdf;
        //console.log('del pdf  url: ' + url);
        this.http.get(url).toPromise().then(res => {
            let rtn = res as PdfGen;
            //console.log('del pdf rtn: ' + rtn.ResultCode + ': ' + rtn.ErrorMessage);
            this.stepDone((rtn.ResultCode == 1));
        });
    }

    public cleanFax(fax: string): string {
        let rtn: string = '';

        for(let a = 0; a <= fax.length; a++) {
            let num = fax.charCodeAt(a);
            if(num >= 48 && num <= 57) rtn += fax.substring(a, a + 1);
        }
    
        //console.log('cleaned fax is ' + rtn);
        return rtn;
    }

    public getSteps(): number {
        return this.steps.length;
    }
}

export class ContactTakerStep {
    MethodType: string;
    Url: string;
    QueryParams: HttpParams;
    MethodDetails: string;
    RoutePath: string;
}
function saveAs(res: Object, pdfFile: string) {
    throw new Error("Function not implemented.");
}

