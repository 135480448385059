import { Component, Input, OnInit } from '@angular/core';
import { SubscriptionLike as ISubscription } from "rxjs";
import { FieldDataCols } from '../models/form';
import { FormService } from '../services/form.service';

@Component({
  selector: 'app-form-field-cols',
  templateUrl: './form-field-cols.component.html',
  styleUrls: ['./form-field-cols.component.scss']
})
export class FormFieldColsComponent implements OnInit {
  fieldDataCols: FieldDataCols;
  fieldSeq: number = 0;
  watchField: string = '';
  watchValue: string = '';
  watchOrNot: string = '';
  watching: boolean = false;
  hasVisChild: boolean = false;
  hideThis: boolean = false;
  colTitle: string;
  colDesc: string;
  changeSub: ISubscription;

  @Input() set cols(value: FieldDataCols) {
    this.fieldDataCols = value;
    this.colTitle = value.Columns[0].FieldName;
    this.colDesc = value.Columns[0].FieldDesc;
    this.fieldSeq = value.Columns[0].FieldSeq;

    let pc = this.fieldDataCols.Columns[0].ProcessingCode;
    if(pc == null) pc = '';
    if(pc.indexOf('visibleif') >= 0) {
      //console.log(' pc: ' + pc);
      let specs = pc.split('|');
      for(let a = 0; a < specs.length; a++) {
        let parts = specs[a].split(';');
        if(parts[0] == 'visibleif') {
          if(parts[1] == 'source') {
            //ignore - it's the source
          } else {
            if(! this.hasVisChild) {
              this.hasVisChild = true;
              this.watching = true;
              this.watchField = parts[1];
              this.watchValue = parts[2];
              this.hideThis = true;
              if(parts.length >= 4) {
                this.watchOrNot = parts[3];
                //console.log('set3:"' + this.watchOrNot + '"');
              }
            }
            //console.log('vif: ' + this.fieldSeq + '.' + this.watchField + ':"' + this.watchOrNot + '" ' + pc);
          }
        }
      }

      //console.log('xif: ' + this.watchField + ':"' + this.watchOrNot + '"' + JSON.stringify(value));
    }
    //console.log('eif: ' + this.fieldSeq + '.' + this.watchField + ':"' + this.watchOrNot + '" ' + pc);
  }

  constructor(public formService: FormService) { }

  ngOnInit(): void {
    this.changeSub = this.formService.output.subscribe(v => {
      if(v) {
        if(this.watching) {
          if(v.fieldName == this.watchField) {
            //console.log('change check: ' + this.fieldSeq + '.' + this.watchField + ':"' + this.watchOrNot  + '"');
            let hide = true;
            let fv = v.fieldValue;
            if(fv == null) fv = '';
            let parts = fv.split(',');

            for(let a = 0; a < parts.length; a++) {
              if(this.watchOrNot == '') {
                if(this.watchValue == parts[a]) hide = false;
              }
              if(this.watchOrNot == 'N') {
                if(this.watchValue != parts[a]) hide = false;
              }
              if(this.watchOrNot == 'P') {
                if(this.watchValue.indexOf(parts[a]) >= 0) hide = false;
              }

              /*if(this.watchOrNot == '') {
                if(this.watchValue == parts[a]) hide = false;
              } else {
                if(this.watchValue != parts[a]) hide = false;
              }*/
            }

            //console.log('changed: ' + this.fieldSeq + '.' + this.watchField + ':"' + this.watchOrNot  + '" = ' + v.fieldName + ' to ' + v.fieldValue + ' = ' + this.watchValue + ' so ' + hide);

            this.hideThis = hide; //!(v.fieldValue == this.watchValue)
          }
        }
      }
    });

    if(this.watching) {
      let val = this.formService.getvalue(this.watchField);
      //console.log('fieldcol ' + this.fieldDataCols.Columns[0].PdfField + '  ' + val + ' = ' + this.watchValue);
      this.hideThis = !(val == this.watchValue);
    }
  }

  ngOnDestroy() {
    this.changeSub.unsubscribe();
  }

  renderClass(): string {
    let rtn = "row field-row";
    if(this.watching) rtn = "row field-row field-row-child";
    return rtn;
  }
}
