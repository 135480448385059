<div class="ot-container">
    <div *ngFor="let tkr of takers;let i = index" class="row ot-row ot-title">
        <div class="col-sm-10 ot-title-left">{{tkr.TakerName}}</div>
        <div class="col-sm-2 ot-title-right"><a (click)="onEdit(i)" class="ot-link"><i class="fas fa-edit"></i></a></div>
    </div>
</div>
<div class="ot-new-button">
    <button class="btn btn-primary" (click)="onNew()">New</button>
</div>
<div class="ot-new" *ngIf="showNew">
    <form #ngForm="ngForm" autocomplete="off" (submit)="onSubmit()">
        <div class="form-group">
            <label for="TakerName">Name</label>
            <input type="text" #TakerName="ngModel" name="TakerName" [(ngModel)]="newTaker.TakerName" class="form-control"
                required maxlength="100"/>
        </div>
        <div class="form-group">
            <label for="EmailAddress">Email</label>
            <input type="email" #EmailAddress="ngModel" name="EmailAddress" [(ngModel)]="newTaker.EmailAddress" class="form-control"
                required maxlength="100" pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"/>
        </div>
        <div class="form-group">
            <button type="submit" class="btn btn-primary" [disabled]="!ngForm.valid">Save</button>
            &nbsp;
            <button type="button" class="btn btn-danger" (click)="onCancel()">Cancel</button>
        </div>
    </form>
</div>
