import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ExternalLists } from '../helpers/external-lists';
import { FormListItem } from '../models/form';
import { NewOrderPage } from '../models/order';
import { Employee, HomeAddress } from '../models/work-well';
import { CompanyService } from '../services/company.service';
import { EmployeeService } from '../services/employee.service';
import { FormService } from '../services/form.service';
import { OrderService } from '../services/order.service';

@Component({
  selector: 'app-check-employee',
  templateUrl: './check-employee.component.html',
  styleUrls: ['./check-employee.component.scss']
})
export class CheckEmployeeComponent implements OnInit {
  isSearch: boolean = true;
  emp: Employee;
  addr: HomeAddress;
  companyId: number;
  formTitle: string = 'Let see if you`re already in the system';
  states: Array<FormListItem>;
  locs: Array<FormListItem>;
  depts: Array<FormListItem>;
  wstates: Array<FormListItem>;

  constructor(public companyService: CompanyService, private router: Router, private http: HttpClient,
              private empService: EmployeeService, private orderService: OrderService, private formService: FormService) { }

  ngOnInit(): void {
    this.companyService.showHeader = false;
    this.emp = new Employee();
    this.companyId = this.empService.compId
    this.emp.Employee_Id = 0;

    if(this.companyId == null) this.companyId = 0;

    if(this.companyId <= 0) {
      //this.router.navigateByUrl('/wnwdemo');
    } else {
      let extLists = new ExternalLists(this.http, this.companyId);

      this.depts = new Array<FormListItem>();
      this.locs = new Array<FormListItem>();
      this.states = new Array<FormListItem>();
      this.wstates = new Array<FormListItem>();
      
      extLists.getWQList(this.depts, 'department', 'id', 'code');
      extLists.getWQList(this.locs, 'location', 'id', 'code');
      extLists.getWQList(this.states, 'state', 'id', 'code');
      extLists.getWQList(this.wstates, 'wstate', 'id', 'code');
      //this.formTitle = "Please complete/verify the information below";
      //this.addr = new HomeAddress();
    }
  }

  onCheck() {
    this.http.post(`${environment.apiUrl}/Employee/EmployeeValid/` + this.companyId, this.emp).toPromise().then(res => {
      this.isSearch = false;

      let em = res as Employee;
      //console.log(JSON.stringify(em));

      if(em.Hire_Date != null){
        this.formTitle = "Please verify the information below";
      } else {
        this.formTitle = "Please enter your information below";
      }
      this.emp = em;
      //console.log('emp is :' + JSON.stringify(this.emp));

      if(this.emp.Hire_Date != null) {
        //console.log(this.emp.Hire_Date);
        //console.log(this.emp.Hire_Date.toString().substr(0,10));
        this.emp.Hire_Date = new Date(this.emp.Hire_Date.toString());
      }

      let aid = this.emp.Home_Address_Id;
      this.http.get(`${environment.apiUrl}/Employee/Address/` + aid).toPromise().then(res => {
        this.addr = res as HomeAddress;
        //console.log(JSON.stringify(this.addr));
      },
      error => {
        console.log('error finding addr: ' + error.error);
        //this.isNewError = true;
      });
      /*} else {
        this.formTitle = "Please enter your information below";
        this.addr = new HomeAddress();
        this.addr.Address_Id = 0;
      }*/
    /*},
    error => {
      //console.log('error finding emp: ' + error.error);
      //this.isNewError = true;
      this.formTitle = "Please enter your information below";
      this.addr = new HomeAddress();
      this.addr.Address_Id = 0;
      this.isSearch = false;*/
    });
  }

  onSave() {
    this.http.put(`${environment.apiUrl}/Employee/Address/` + this.addr.Address_Id, this.addr).toPromise().then(res => {
      //console.log('addr saved');
      this.http.put(`${environment.apiUrl}/Employee/` + this.emp.Employee_Id, this.emp).toPromise().then(res => {
        //console.log('emp saved');
        this.createOrder();
      });
    });
  }

  createOrder() {
    let url = `${environment.apiUrl}/Employee/NewOrder/` + this.emp.Employee_Id + '/8/4';
    //console.log('new order url:' + url);

    this.http.get(url).subscribe(res => {
      let nop = res as NewOrderPage;
      //console.log(JSON.stringify(nop));

      if(nop.OrderId > 0) {
        //this.orderService.currentGuest = nop.takerGuest;
        this.companyService.setLogo(nop.takerGuest.LogoFile);
        this.orderService.setCurrentOrder(nop.orderFormCustomers[0]);
        this.orderService.packageSettings = nop.packageSettings;
        this.formService.usePages = true;
        this.formService.setTaker(nop.takerGuest);
        this.formService.setPages(nop.takerGroupOrders);
        this.companyService.logoLink = 'pages';
        this.companyService.showHeader = true;
        this.formService.nextTaker = nop.takers[0];
        this.formService.externalListId = nop.WQCompanyId;
        this.formService.pageIdx = 0;

        this.empService.hrId = this.emp.HR_Address_Id;
        this.empService.supId = this.emp.Supervisor_Address_Id;
        console.log('emp addrs:' + this.empService.supId + ' : ' + this.empService.hrId);

        this.router.navigateByUrl('/wnwaddr');
      } else {
        //this.alertService.error(nop.errorDescription);
        console.log("New order error:" + nop.errorDescription);
      }
    });
  }
}
