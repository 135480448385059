import { Component, Input, OnInit } from '@angular/core';
import { CompanyOrders } from '../models/customer';
declare var bootstrap: any;

@Component({
  selector: 'filer-status',
  templateUrl: './filer-status.component.html',
  styleUrls: ['./filer-status.component.scss']
})
export class FilerStatusComponent implements OnInit {
  @Input() public order: CompanyOrders
  @Input() public details: Boolean;

  filerArray: Array<Object> = [];

  constructor() { }

  ngOnInit(): void {
    if (this.order.Taker1Date != null) {
      this.filerArray.push({taker: this.order.Taker1, title: this.order.Taker1Date, email: this.order.Taker1Email, status: this.order.Taker1Status});
    }
    if (this.order.Taker2Date != null) {
      this.filerArray.push({taker: this.order.Taker2, title: this.order.Taker2Date, email: this.order.Taker2Email, status: this.order.Taker2Status});
    }
    if (this.order.Taker3Date != null) {
      this.filerArray.push({taker: this.order.Taker3, title: this.order.Taker3Date, email: this.order.Taker3Email, status: this.order.Taker3Status});
    }
  }

  onHover(event: any) {
    new bootstrap.Tooltip(event.target).show();
  }

}
