import { Component, Input, OnInit } from '@angular/core';
declare var bootstrap: any;

@Component({
  selector: 'toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit {
  @Input() toast: any;
  constructor() { }

  ngOnInit(): void {
    var toastElList = [].slice.call(document.querySelectorAll('.toast'))
    var latestToast = toastElList[toastElList.length - 1];
    return new bootstrap.Toast(latestToast).show();
  }
}