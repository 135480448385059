<div class="container">
  <h4 class="header">Reset Password</h4>
  <form [formGroup]="passwordInputs" class="fields" (ngSubmit)="onResetPassword()">
    <div class="form-group">
        <div class="form-group mb-3">
            <label for="NewPass" class="form-label">New Password</label>
            <input type="password" formControlName="NewPass" class="form-control" [ngClass]="{ 'is-invalid': onReset && pws.NewPass.errors }" />
            <div *ngIf="onReset && pws.NewPass.errors" class="invalid-feedback">
                <div *ngIf="pws.NewPass.errors.required">New Password is required</div>
                <div *ngIf="pws.NewPass.errors.minlength">Password must be at least 6 characters</div>
            </div>
        </div>
        <div class="form-group mb-3">
            <label for="NewPassConfirmed" class="form-label">Confirm New Password</label>
            <input type="password" formControlName="NewPassConfirmed" class="form-control" [ngClass]="{ 'is-invalid': onReset && pws.NewPassConfirmed.errors }" />
            <div *ngIf="onReset && pws.NewPassConfirmed.errors" class="invalid-feedback">
                <div *ngIf="pws.NewPassConfirmed.errors.required">Confirmed New Password is required</div>
                <div *ngIf="pws.NewPassConfirmed.errors.confirmedValidator">Confirmed New Password must match New Password</div>
            </div>
        </div>
    </div>
    <div class="form-group d-grid">
        <button class="btn btn-primary rounded-pill m-t-30 mb-1" type="submit" [disabled]="loading">
            <span class="spinner-border spinner-border-sm" aria-hidden="true" *ngIf="loading"></span>
            <span> Reset Password</span>
        </button>
        <div *ngIf="resetErr">There was an error resetting password. Please try again later.</div>
    </div>
</form>
</div>
