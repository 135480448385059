<div class="p-4">
    <h3 class="tg-top-h">Edit Login</h3>
    <br>
    <form #ngForm="ngForm" autocomplete="off">
        <div class="form-group row mb-3">
            <div class="col-sm-2 field-name d-flex align-items-center justify-content-md-end mb-1 mb-md-0">First Name</div>
            <div class="col-sm-4">
                <input type="text" #FirstName="ngModel" name="FirstName" [(ngModel)]="userService.currentDialogCompanyLogin.FirstName"
                    class="form-control" required maxlength="50"/>
            </div>
            <div class="col-sm-2 field-name d-flex align-items-center justify-content-md-end my-1 my-md-0">Last Name</div>
            <div class="col-sm-4">
                <input type="text" #LastName="ngModel" name="LastName" [(ngModel)]="userService.currentDialogCompanyLogin.LastName"
                    class="form-control" required maxlength="100"/>
            </div>
        </div>
        <div class="form-group row mb-3">
            <div class="col-sm-2 field-name d-flex align-items-center justify-content-md-end my-1 my-md-0">Email</div>
            <div class="col-sm-10">
                <input type="text" #EmailAddress="ngModel" name="EmailAddress" [(ngModel)]="userService.currentDialogCompanyLogin.EmailAddress"
                    class="form-control" required maxlength="100"/>
            </div>
        </div>
        <div class="form-group row mb-3">
            <div class="col-sm-2 field-name d-flex align-items-center justify-content-md-end my-1 my-md-0">Password</div>
            <div class="col-sm-10">
                <input type="text" #PasswordDes="ngModel" name="PasswordDes" [(ngModel)]="userService.currentDialogCompanyLogin.PasswordDes"
                    class="form-control" required maxlength="20"/>
            </div>
        </div>
        <div class="form-group row mb-3">
            <div class="col-sm-2 d-flex field-name align-items-center justify-content-md-end mb-1 mb-sm-0">Phone</div>
            <div class="col-sm-4">
                <input type="text" #PhoneNbr="ngModel" name="PhoneNbr" [(ngModel)]="userService.currentDialogCompanyLogin.PhoneNbr"
                    class="form-control" maxlength="20"/>
            </div>
            <div class="col-sm-2 d-flex field-name align-items-center justify-content-md-end my-2 my-sm-0">Primary Contact</div>
            <div class="col-sm-4">
                <select #PrimaryContact="ngModel" name="PrimaryContact" [(ngModel)]="userService.currentDialogCompanyLogin.PrimaryContact"
                    class="form-control" maxlength="20">
                    <option value="Y">Yes</option>
                    <option value="N">No</option>
                </select>
            </div>
        </div>
    </form>
    <br>
    <div class="row">
        <div class="col-sm-12 d-flex">
            <button class="btn btn-secondary rounded-pill px-4 me-auto" (click)="onDelete()" *ngIf="(userService.currentDialogCompanyLogin.CompanyLoginId != null)">Delete</button>
            <div class="d-flex ms-auto">
                <button class="btn btn-outline-primary me-2 rounded-pill px-4" (click)="onCancel()">Cancel</button>
                <button class="btn btn-primary rounded-pill px-4" (click)="onSave()">Save</button>
            </div>
        </div>
    </div>
</div>
