import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { Customer } from '../models/customer';
import { AlertService } from '../services/alert.service';
import { UserService } from '../services/user.service';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss']
})

export class CustomerComponent implements OnInit {
  customer: Customer;
  //customerForm: FormGroup;
  loading = false;
  submitted = false;

  constructor(private formBuilder: UntypedFormBuilder,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,
    private userService: UserService) { }

  ngOnInit(): void {
    this.customer = JSON.parse(JSON.stringify(this.authenticationService.currentCustomer));
  }

  onSubmit(form:NgForm) {
    this.alertService.clear();
    this.submitted = true;

    // stop here if form is invalid
    if (form.invalid) {
      return;
    }

    this.loading = true;

    //const result: Customer = Object.assign({}, this.customerForm.value);
    this.userService.putCustomerDetail(this.customer).subscribe(
        res => {
        this.alertService.success('Saved');
        this.authenticationService.setCurrentUser(JSON.parse(JSON.stringify(this.customer)));
        this.submitted = false;
        this.loading = false;
      },
      error => {
        this.alertService.error(error);
      });
  }

  onReset() {
    this.customer = JSON.parse(JSON.stringify(this.authenticationService.currentCustomer));
    }
}
