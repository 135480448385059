import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { PackageForms, Package } from 'src/app/models/form';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-library',
  templateUrl: './company-library.component.html',
  styleUrls: ['./company-library.component.scss']
})
export class CompanyLibraryComponent implements OnInit {
  selectedHdr: string = 'lib';
  displayedColumns: string[] = ['PackageName', 'CompanyId'];
  dataSource: MatTableDataSource<any | Package>;
  typeFilter: string = '';
  searchFilter: string;
  forms: PackageForms[];
  packages: Package[];
  typeFilterOptions: Array<any>;

  constructor(private http: HttpClient, public userService: UserService, private router: Router) { }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    if(this.userService.currentCompany == null) this.router.navigateByUrl('/login');
    
    this.typeFilterOptions = [
      { value: '', name: 'All'},
      { value: '0', name: 'Default'},
      { value: this.userService.currentCompany.CompanyId.toString(), name: 'Custom'}
    ]

    this.forms = new Array<PackageForms>();
    this.packages = new Array<Package>();
    this.getPackages();
  }

  getPackages() {
    this.http.get(`${environment.apiUrl}/Package/Company/` + this.userService.currentCompany.CompanyId + '/A').subscribe(res => {
      this.packages = res as Package[];
      //console.log(this.packages)
      /*let temp = res as Package[];
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].CompanyId === this.userService.currentCompany.CompanyId || temp[i].CompanyId == 0) {
          this.packages.push(temp[i]);
        }
      }*/
      this.dataSource = new MatTableDataSource(this.packages);
      this.dataSource.sort = this.sort;
    });
  }

  handleTypeFilter() {
    this.dataSource = new MatTableDataSource(this.packages.filter((pkg) => this.typeFilter ? pkg.CompanyId.toString() === this.typeFilter : true));
    this.dataSource.filter = this.searchFilter;
    this.dataSource.sort = this.sort;
  }

  handleSearchFilter(event: Event) {
    this.searchFilter = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.searchFilter.trim().toLowerCase();
  }

  navTo(pid: number) {
    this.router.navigateByUrl('/package/' + pid);
  }
}
