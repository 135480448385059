<h1>PAY HERE</h1>
<div class="form-group">
    <button [disabled]="loading" type="button" class="btn btn-primary" (click)="onNext()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Next
    </button>
    &nbsp;
    <button [disabled]="loading" type="button" class="btn btn-danger" (click)="onCancel()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Cancel
    </button>
</div>
