<app-public-header></app-public-header>



<section class="home-banner p-b-30 home-banner-top-thin">
  <div class="home-container">           
         <div class="row">
              <div class="col-cmn col-lg-12 col-md-12 col-sm-12 home-top-other-left">
                <div class="home-bnr-title">About Us</div>
                <p>The Ultimate Solution for Medical Leave Documentation Management</p>
              </div>                        	
          </div>           
  </div>
</section>
<br>
<section class="home-row-two">
    <div class="home-container">
        <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-8">
                <p>Recognizing the growing need for efficient and streamlined medical leave processes,   Work and Well introduced our affiliated company, Expidoc.</p><br/>
                <p>Work & Well has a rich history of supporting organizations by providing medical leave administration services, since 1991,  Through our commitment to excellence, we understand that managing employee leaves can be complex and time-consuming, which is why we are simplifying the process via Expidoc.</p><br/>
                <p>Expidoc specializes in automating employment leave forms through an innovative online platform. With Expidoc, we aim to revolutionize the way organizations manage leave documentation, making the process easier, faster, and more convenient for both employers and employees.</p><br/>
                <p>Expidoc was thoughtfully crafted as a powerful standalone platform, designed to revolutionize the way organizations handle leave management. Seamlessly integrating with your current vendors, Expidoc simplifies and accelerates the entire leave process.</p><br/>
                <p>We are proud of our history and look forward to the future as we continue to innovate and provide valuable leave management services.  Streamline your leave processes with Expidoc, where efficiency meets innovation!</p><br/>
                </div>
            <div class="col-sm-2"></div>
        </div>
    </div>
</section>
<br>

<app-footer></app-footer>