import { Injectable } from '@angular/core';
import { CompanyFieldXref } from '../models/customer';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  public companyLogo: string = './assets/Logos/default.jpg';
  public logoLink: string = '/';
  public headerTitle = ""; //"FMLA Certification";
  public headerTitle2 = ""; //"Doe, Jane  -  DOB: 01/15/1977";
  public showHeader: boolean = true;
  public showHeaderBtns: boolean = false;
  public showHeaderLogout: boolean = false;
  public compData: CompanyData;
  public fieldXrefs: CompanyFieldXref[];
  
  constructor() {
    this.compData = new CompanyData();
  }

  setLogo(file: string) {
    this.companyLogo = './assets/Logos/' + file;
  }

  setUser(nam: string) {
    //console.log('cn: ' + nam);
    if(this.compData == null) this.compData = new CompanyData();
    this.compData.userName = nam;
    //console.log("comps: " + JSON.stringify(this.compData));
  }
}

export class CompanyData {
  public userName: string;
}