import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormFieldComponent } from 'src/app/form-field/form-field.component';
import { FieldData, FieldGroup } from 'src/app/models/form';
import { NewOrderPage } from 'src/app/models/order';
import { AlertService } from 'src/app/services/alert.service';
import { CompanyService } from 'src/app/services/company.service';
import { FormService } from 'src/app/services/form.service';
import { OrderService } from 'src/app/services/order.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-company-invite',
  templateUrl: './company-invite.component.html',
  styleUrls: ['./company-invite.component.scss']
})
export class CompanyInviteComponent implements OnInit {
  private sub: any;
  loading: boolean;
  pageSeq: number;
  companyId: number;
  packageId: number;
  formGroup: FieldGroup;
  fields: FormFieldComponent[];


  constructor(private router: Router, public companyService: CompanyService,
    private http: HttpClient, public formService: FormService, public orderService:OrderService,
    private route: ActivatedRoute, private alertService: AlertService) { }

  ngOnInit(): void {
    this.companyService.showHeader = true;
  }

  ngAfterContentInit() {
    this.companyService.logoLink = 'thanks';
    this.companyService.headerTitle = 'Welcome to Work & Well';
    this.companyService.headerTitle2 = 'Create a Case';

    this.sub = this.route.params.subscribe(params => {
      let cid = params['cid'];
      let pid = params['pid'];

      if(cid && pid) {
        if(cid.length > 0 && pid.length > 0) {
          this.packageId = pid;
          this.companyId = cid;
          this.pageSeq = 1;
          this.companyService.logoLink = 'compnew/' + cid + '/' + pid;
          this.loadGroup();
        }
      }
    });
  }

  loadGroup() {
    this.loading = true;
    this.formGroup = JSON.parse('{"GroupId":0,"PackageId":0,"PageSeq":0,"GroupTitle":"loading...","GroupDesc":""}');
    //this.pageSeq = this.formService.pages[this.formService.pageIdx].PageSeq;
    //console.log("seq is " + this.formService.pages[this.formService.pageIdx].PageSeq);
    //console.log("group is " + this.pageSeq);

    //let i = this.formService.pageIdx + 1;
    //let l = this.formService.pages.length;
    //if(i == l) this.nextTitle = 'Finish';

    let url = `${environment.apiUrl}/FieldGroup/Page/` + this.packageId + "/" + this.pageSeq;
    //console.log(url);

    this.http.get(url)
      .toPromise().then(res => {
        this.formGroup = res as FieldGroup;
        //console.log("got data " + JSON.stringify(this.formGroup));
        this.loadFields();
      },
      error => {
        this.alertService.error(error);
      });
  }

  loadFields() {
    let url = `${environment.apiUrl}/Field/GroupData/0/7/0`; // + this.formGroup.GroupId;
    //console.log('field url: ' + url);

    this.http.get(url).toPromise()
    .then(res => {
      this.loading  = false;
      this.formService.items = null;
      this.formService.initFields(0);

      let f = res as FieldData[];
      this.formService.setItems(f);

      let len = this.formService.items.length;
      //console.log('there are ' + len + ' fields');
      this.formService.initFields(len);
    });
  }

  onNext() {
    this.alertService.clear();
    let valid = this.formService.validateForm();
    //console.log("valid = " + valid);

    if(valid) {
      let data = this.formService.getValues(this.orderService.currentOrderId);
      //console.log("next data saving for x items: " + data.answers.length + ' ' + JSON.stringify(data));

      let parts = this.formGroup.TakerNextAction.split(':');

      if(parts[0] == '3rdp') {
        this.http.post(`${environment.apiUrl}/ThirdParty/` + parts[1] + '/' + this.packageId, data).subscribe(res => {
          let nop = res as NewOrderPage;
          //console.log(JSON.stringify(nop));

          if(nop.OrderId > 0) {
            this.orderService.currentGuest = nop.takerGuest;
            this.companyService.setLogo(nop.takerGuest.LogoFile);
            this.orderService.setCurrentOrder(nop.orderFormCustomers[0]);
            this.orderService.packageSettings = nop.packageSettings;
            this.formService.usePages = true;
            this.formService.setTaker(nop.takerGuest);
            this.formService.setPages(nop.takerGroupOrders);
            this.companyService.logoLink = 'pages';
            this.formService.nextTaker = nop.takers[0];
            this.formService.externalListId = nop.WQCompanyId;

            this.formService.pageIdx = 0;
            this.router.navigateByUrl('/pages/0');
          } else {
            this.alertService.error(nop.errorDescription);
          }
        });
      }

    } else {
      this.alertService.error('All required fields are not filled in');
    }
  }

  onCancel() {
    this.router.navigate(['thanks']);
  }
}
