<app-public-header></app-public-header>
<section class="home-banner p-b-30 home-banner-top-thin">
    <div class="home-container">           
           <div class="row">
                <div class="col-cmn col-lg-6 col-md-6 col-sm-6 home-top-other-left">
                  <div class="home-bnr-title">Pricing</div>
                  <p></p>
                </div>                        	
            </div>           
    </div>
</section>
  
<section class="home-row-two p-t-60 p-b-30">
    <div class="home-container-notused">
        <div class="row">
            <div class="col-sm-9">
                <div class="row">
                    <div class="col-sm-6"></div>
                    <div class="col-sm-2 pkg-title">Build-A-Packet</div>
                    <div class="col-sm-2 pkg-title">Leave Expiditer</div>
                    <div class="col-sm-2 pkg-title">Full Service</div>
                </div>
                <br>
                <div class="row">
                    <div class="col-sm-6"></div>
                    <!--div class="col-sm-2 pkg-price"><span class="pkg-price-dol">$1</span><span class="pkg-price-cnt">49</span><span class="pkg-price-note">/ee/mo</span></div-->
                    <div class="col-sm-2 pkg-price pkg-price-special"><span class="pkg-price-dol">$99</span><span class="pkg-price-note">/month!<br/> Limited time offer!</span></div>
                    <div class="col-sm-2 pkg-price"><span class="pkg-price-dol">$1</span><span class="pkg-price-cnt">79</span><span class="pkg-price-note">/ee/mo</span></div>
                    <div class="col-sm-2 pkg-price"><span class="pkg-price-dol">$0</span><span class="pkg-price-cnt">00</span><span class="pkg-price-note">/with service agreement</span></div>
                </div>
                <br>
                <div class="row feat-row">
                    <div class="col-sm-6 feat-right">All Leave requests easily opened using the Expidoc leave portal</div>
                    <div class="col-sm-2 pkg-x">X</div>
                    <div class="col-sm-2 pkg-x">X</div>
                    <div class="col-sm-2 pkg-x">X</div>
                </div>
                <div class="row feat-row">
                    <div class="col-sm-6 feat-right">o	Expidoc instantly determines all leave eligibility (federal, state, company, etc)</div>
                    <div class="col-sm-2 pkg-x">X</div>
                    <div class="col-sm-2 pkg-x">X</div>
                    <div class="col-sm-2 pkg-x">X</div>
                </div>
                <div class="row feat-row">
                    <div class="col-sm-6 feat-right">Eligibility form completed in its entirety</div>
                    <div class="col-sm-2 pkg-x">X</div>
                    <div class="col-sm-2 pkg-x">X</div>
                    <div class="col-sm-2 pkg-x">X</div>
                </div>
                <div class="row feat-row">
                    <div class="col-sm-6 feat-right">Generates packet with all eligible and necessary leave forms</div>
                    <div class="col-sm-2 pkg-x">X</div>
                    <div class="col-sm-2 pkg-x">X</div>
                    <div class="col-sm-2 pkg-x">X</div>
                </div>
                <div class="row feat-row">
                    <div class="col-sm-6 feat-right">Employee completes paperwork using Expidoc EZ-doc</div>
                    <div class="col-sm-2 pkg-x"></div>
                    <div class="col-sm-2 pkg-x">X</div>
                    <div class="col-sm-2 pkg-x">X</div>
                </div>
                <div class="row feat-row">
                    <div class="col-sm-6 feat-right">Treating Healthcare Provider completes paperwork using Expidoc EZ-doc</div>
                    <div class="col-sm-2 pkg-x"></div>
                    <div class="col-sm-2 pkg-x">X</div>
                    <div class="col-sm-2 pkg-x">X</div>
                </div>
                <div class="row feat-row">
                    <div class="col-sm-6 feat-right">HR completes paperwork using Expidoc EZ-doc</div>
                    <div class="col-sm-2 pkg-x"></div>
                    <div class="col-sm-2 pkg-x">X</div>
                    <div class="col-sm-2 pkg-x">X</div>
                </div>
                <div class="row feat-row">
                    <div class="col-sm-6 feat-right">All follow-up paperwork accessed via Expidoc Formbuilder (clarifications, Re-certs, etc)</div>
                    <div class="col-sm-2 pkg-x"></div>
                    <div class="col-sm-2 pkg-x">X</div>
                    <div class="col-sm-2 pkg-x">X</div>
                </div>
                <div class="row feat-row">
                    <div class="col-sm-6 feat-right">Expidoc determines leave eligibility</div>
                    <div class="col-sm-2 pkg-x"></div>
                    <div class="col-sm-2 pkg-x">X</div>
                    <div class="col-sm-2 pkg-x">X</div>
                </div>
                <div class="row feat-row">
                    <div class="col-sm-6 feat-right">System populates completed paperwork</div>
                    <div class="col-sm-2 pkg-x"></div>
                    <div class="col-sm-2 pkg-x">X</div>
                    <div class="col-sm-2 pkg-x">X</div>
                </div>
                <div class="row feat-row">
                    <div class="col-sm-6 feat-right">Notification sent to all parties (HR, TPA, Employee) that paperwork is complete and can be downloaded or viewed in the Expidoc Portal</div>
                    <div class="col-sm-2 pkg-x"></div>
                    <div class="col-sm-2 pkg-x">X</div>
                    <div class="col-sm-2 pkg-x">X</div>
                </div>
                <div class="row feat-row">
                    <div class="col-sm-6 feat-right">Expidoc Master Control available to HR</div>
                    <div class="col-sm-2 pkg-x"></div>
                    <div class="col-sm-2 pkg-x">X</div>
                    <div class="col-sm-2 pkg-x">X</div>
                </div>
                <div class="row feat-row">
                    <div class="col-sm-6 feat-right">Work &amp; Well`s full case management services</div>
                    <div class="col-sm-2 pkg-x"></div>
                    <div class="col-sm-2 pkg-x"></div>
                    <div class="col-sm-2 pkg-x">X</div>
                </div>
                <div class="row feat-row">
                    <div class="col-sm-6 feat-right">Minimum Monthly Charge</div>
                    <div class="col-sm-2 pkg-price-btm pkg-price-special">$99</div>
                    <div class="col-sm-2 pkg-price-btm">$500</div>
                    <div class="col-sm-2 pkg-price-btr">Call for quote</div>
                </div>
                <div class="row feat-row">
                    <div class="col-sm-6"></div>
                    <div class="col-sm-2 pkg-x"><button class="btn button-primary a-next" (click)="buynow('B')">Buy Now</button></div>
                    <div class="col-sm-2"></div>
                    <div class="col-sm-2"></div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="pri-right-box">
                    <p><span class="pri-right-box-title">Remember...</span></p><br>
                    <p>All purchase options include the use of your current forms!</p><br>
                    <p>Every account is customized to include forms unique to your company.</p><br>
                    <p>State/local leave forms<br>
                        Disability carrier forms<br>
                        Cover letters<br>
                        etc, etc, etc...</p><br>
                    <p>We code your forms into Expidoc</p>
                </div>
            </div>
        </div>
    </div>
</section>


<!--div class="img-body">
    <img src="../../../assets/pricing2.png">
</div>
<div class="row pri-tbl">
    <div class="col-sm-6">a</div>
    <div class="col-sm-2">b</div>
    <div class="col-sm-2 tbl-lborder">c</div>
    <div class="col-sm-2tbl-lborder">d</div>
</div-->

<app-footer></app-footer>