<div class="row">
    <div class="col-sm-8">
        <h3>Review Form</h3>
    </div>
    <div class="col-sm-4 top-mod-buttons">
        <button mat-icon-button class="close-button fb-button" [mat-dialog-close]="true">
            <i class="fas fa-times fb-button-i"></i>
        </button>
        <div class="top-mod-pdf-input" *ngIf="inputVisible">
            <div class="top-mod-pdf-input-title">{{inputTitle}}</div>
            <div class="form-group top-mod-pdf-input-body">
                <input type="text" class="form-control" [(ngModel)]="inputValue" required>
            </div>
            <div class="top-mod-pdf-input-buttons">
                <button type="button" class="btn btn-primary" (click)="onOk()">Ok</button>
                &nbsp;
                <button type="button" class="btn btn-danger" (click)="onCancel()">Cancel</button>        
            </div>
        </div>
    </div>
</div>


<div class="row">
    <div class="col-sm-10">

        <mat-dialog-content class="mat-dialog-content-fb">
            <ngx-extended-pdf-viewer [src]="pdfsrc" 
                    [enablePrint]="true"  height="80vh"
                    style="display: block;">
        </ngx-extended-pdf-viewer>
            
        </mat-dialog-content>

    </div>
<div class="col-sm-2">
    <button mat-icon-button class="fb-button" (click)="onPrepEmail()">
        <i class="far fa-envelope fb-button-i"></i>
    </button> Email<br>
    <button mat-icon-button class="fb-button" (click)="onPrepFax()">
        <i class="fas fa-fax fb-button-i"></i>
    </button> Fax<br>
    <!--button mat-icon-button class="fb-button" (click)="onOpenPdf()">
        <i class="fas fa-download fb-button-i"></i>
    </button> Print -->
</div>
