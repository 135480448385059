<!--h3>{{formGroup.GroupTitle}}</h3>
<p>{{formGroup.GroupDesc}}</p>
<app-form-field *ngFor="let fld of fields; let i = index" [formFieldObj]="fld" [index] = "i"></app-form-field-->

<div class="container">
    <div class="row">
        <div class="col-sm-10">
            <div class="pages-body">
            <div class="pages-questions">
                <div class="page-row-title-right">
                    Preview.
                </div>
                <h3>{{formGroup.GroupTitle}}</h3>
                <p>{{formGroup.GroupDesc}}</p>
                <ng-container *ngFor="let cols of formService.cols">
                    <app-form-field-cols [cols]="cols"></app-form-field-cols>
                </ng-container>
            </div>
            </div>
        </div>
        <div class="col-sm-2">
            x
        </div>
    </div>
</div>
