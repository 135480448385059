import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ExternalLists } from '../helpers/external-lists';
import { FormListItem } from '../models/form';
import { HomeAddress } from '../models/work-well';
import { CompanyService } from '../services/company.service';
import { EmployeeService } from '../services/employee.service';
import { FormService } from '../services/form.service';
import { OrderService } from '../services/order.service';

@Component({
  selector: 'app-check-address',
  templateUrl: './check-address.component.html',
  styleUrls: ['./check-address.component.scss']
})
export class CheckAddressComponent implements OnInit {
  addrId: number;
  addr: HomeAddress = new HomeAddress();
  companyId: number;
  formTitle: string = 'Address';
  states: Array<FormListItem>;

  constructor(public companyService: CompanyService, private router: Router, private http: HttpClient,
    private empService: EmployeeService, private orderService: OrderService, private formService: FormService) { }

  ngOnInit(): void {
    this.companyService.showHeader = false;
    this.companyId = this.empService.compId
    if(this.companyId > 0) {
      this.states = new Array<FormListItem>();
      let extLists = new ExternalLists(this.http, this.companyId);
      extLists.getWQList(this.states, 'state', 'id', 'code');
    }

    this.getAddress();
  }
  getAddress() {
    this.empService.addrStep = this.empService.addrStep + 1;

    if(this.empService.addrStep == 1) {
      this.formTitle = 'Your Supervisors Info';
      this.addrId = this.empService.supId;
    } else {
      this.formTitle = 'Your HR Reps Info';
      this.addrId = this.empService.hrId;
    }

    this.addr = null;
    //console.log('addr step ' + this.empService.addrStep + ' : ' + this.addrId);
    this.http.get(`${environment.apiUrl}/Employee/Address/` + this.addrId).toPromise().then(res => this.addr = res as HomeAddress);
  }

  onSave() {
    //console.log('saving addr');
    this.http.put(`${environment.apiUrl}/Employee/Address/` + this.addrId, this.addr).toPromise().then(res => {
      //console.log('addr saved');
      if(this.empService.addrStep == 1) {
        //this.getAddress();
      //} else {  
        this.router.navigateByUrl('/pages/0');
      }
    },
    error => { console.log(error.error()); }
    );
  }
}
