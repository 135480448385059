<app-appheader></app-appheader>
<header>
    <div class="hero-text-box">
        <div class="row">
            <div class="col-sm-12 guest-mid-para">
                <div *ngIf="processing" class="col-sm-1 guest-spin"><mat-spinner [diameter]="60"></mat-spinner></div>
                <div *ngIf="processing" class="col-sm-11 status-text-center">Please Wait...</div>
                <div *ngIf="!processing" class="col-sm-12 status-text" [innerHtml]="settingBody"></div>
            </div>
        </div>
    </div>
</header>
