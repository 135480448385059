<div class="d-flex" [ngClass]="{'flex-column': details}">
  <div *ngFor="let filer of filerArray; index as i">
    <div *ngIf="filer.status.toLowerCase().startsWith('n') || filer.status ==''">
      <div class="d-flex align-items-center">
        <i class="filer-status fa-regular fa-circle fa-lg" style="color: #5f5f5f; margin-right: 5px" data-bs-toggle="tooltip" title="{{filer.title}}: Not started" data-bs-custom-class="my-2" (mouseover)="onHover($event)"></i>
        <div *ngIf="details">{{filer.title}}: Not started</div>
      </div>
      <div *ngIf="details && i !== filerArray.length - 1" class="line"></div>
    </div>
    <div *ngIf="filer.status.toLowerCase().startsWith('i') || filer.status.toLowerCase().startsWith('s')">
      <div class="d-flex align-items-center">
        <i class="filer-status fa-regular fa-clock fa-lg" style="color: #5f5f5f; margin-right: 5px"  data-bs-toggle="tooltip" title="{{filer.title}}: {{filer.status}}" data-bs-custom-class="my-2" (mouseover)="onHover($event)"></i>
        <div *ngIf="details">{{filer.title}}: {{filer.status}}</div>
      </div>
      <div *ngIf="details && i !== filerArray.length - 1" class="line"></div>
    </div>
    <div *ngIf="filer.status.toLowerCase().startsWith('c')">
      <div class="d-flex align-items-center">
        <i class="filer-status fa-solid fa-circle-check fa-lg" style="color: #2cb007; margin-right: 5px;"  data-bs-toggle="tooltip" title="{{filer.title}}: {{filer.status}}" data-bs-custom-class="my-2" (mouseover)="onHover($event)"></i>
        <div *ngIf="details">{{filer.title}}: {{filer.status}}</div>
      </div>
      <div *ngIf="details && i !== filerArray.length - 1" class="line"></div>
    </div>
  </div>
</div>
