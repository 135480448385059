<div class="p-4">
    <h3 class="tg-top-h">Edit</h3>
    <form #ngForm="ngForm" autocomplete="off">
        <div class="form-group">
            <label for="TakerName">Name</label>
            <input type="text" #TakerName="ngModel" name="TakerName" [(ngModel)]="orderService.currentTaker.TakerName" class="form-control"
                required maxlength="100"/>
        </div>
        <div class="form-group">
            <label for="EmailAddress">Email</label>
            <input type="email" #EmailAddress="ngModel" name="EmailAddress" [(ngModel)]="orderService.currentTaker.EmailAddress" class="form-control"
                required maxlength="100" pattern="^[^\s@]+@[^\s@]+\.[^\s@]{2,}$"/>
        </div>
    </form>

    <h3 class="tg-mid-h">Question Groups</h3>
    <div class="row tg-header">
        <div class="col-sm-2"></div>
        <div class="col-sm-5">Group</div>
        <div class="col-sm-5">Should Be Filled Out By</div>
    </div>
        <div *ngFor="let dsp of groups;let i = index" class="row tg-grp">
            <div class="col-sm-2">
                <input type="checkbox" #IsChecked="ngModel" name="IsChecked" [(ngModel)]="dsp.IsChecked" class="form-control"/>
            </div>
            <div class="col-sm-5 tg-grp-txt">{{dsp.GroupTitle}}</div>
            <div class="col-sm-5 tg-grp-txt">{{dsp.TakerHint}}</div>
        </div>
    <p>&nbsp;</p>
    <div class="form-group">
        <button type="button" class="btn btn-primary" (click)="onSave()">Save</button>
        &nbsp;
        <button type="button" class="btn btn-danger" [mat-dialog-close]="true">Cancel</button>
    </div>
</div>