import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CompanyLogin, Customer } from '../models/customer';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmedValidator } from '../helpers/pw-validator';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';
import { OrderService } from '../services/order.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  private sub: any;
  userType: string = '';
  companyLogin: CompanyLogin;
  customer: Customer;
  passwordInputs: UntypedFormGroup = new UntypedFormGroup({});
  onReset = false;
  loading = false;
  resetErr = false;
  authToken: string;

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    public authenticationService: AuthenticationService,
    private orderService: OrderService,
    public userService: UserService,) {
    this.passwordInputs = formBuilder.group({
      NewPass: ['', [Validators.required, Validators.minLength(6)]],
      NewPassConfirmed: ['', [Validators.required]]
    }, {
      validator: [ConfirmedValidator('NewPass', 'NewPassConfirmed')],
  });
  }

  get pws() { return this.passwordInputs.controls }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      let rid = params['prid'];
      this.userType = rid.substring(0,1);
      if(this.userType == 'L') {
        this.http.get(`${environment.apiUrl}/Login/CompanyByReset/` + rid).subscribe(res => {
          this.companyLogin = res as CompanyLogin;
          if(this.companyLogin) {
            this.authenticationService.setToken(this.companyLogin.Token);
            this.authToken = this.companyLogin.Token;
          } else {
            this.router.navigate(['login']);
          }
        });
      } else {
        this.http.get(`${environment.apiUrl}/Login/CustomerByReset/` + rid).subscribe(res => {
          this.customer = res as Customer;
          if(this.customer) {
            //console.log('reset cust token is ' + JSON.stringify(this.customer));
            this.authenticationService.setToken(this.customer.Token);
            this.authToken = this.customer.Token;
          } else {
            this.router.navigate(['login']);
          }
        });
      }
    });
  }

  onResetPassword() {
    this.onReset = true;
    if (!this.pws.NewPass.errors && !this.pws.NewPassConfirmed.errors) {
      this.loading = true;
      this.authenticationService.setToken(this.authToken);
      //console.log('reset saving token is ' + this.authenticationService.token + ' of ' + this.authToken);

      if (this.userType != 'L') {
        this.customer.PasswordCd = this.pws.NewPass.value;
        this.http.put(`${environment.apiUrl}/Customer/` + this.customer.CustomerId, this.customer).toPromise().then(res => {
          this.userService.logIn(this.customer.EmailAddress, this.customer.PasswordCd).then(value => {
            this.customer = this.userService.getCustomer();
            this.authenticationService.setCurrentUser(this.customer);
            this.router.navigate(['/dashboard/' + this.customer.CustomerId]);
          })
        }), err => {
          this.resetErr = true;
          this.loading = false;
          this.onReset = false;
        };
      } else {
        this.companyLogin.PasswordDes = this.pws.NewPass.value;
        this.http.put(`${environment.apiUrl}/CompanyLogin/` + this.companyLogin.CompanyLoginId, this.companyLogin).toPromise().then(res => {
          this.userService.adminLogIn(this.companyLogin.EmailAddress, this.companyLogin.PasswordDes).then(value => {
            this.router.navigateByUrl('/dashboard/' + this.orderService.dashReturnId);
          });
        }), err => {
          this.resetErr = true;
          this.loading = false;
          this.onReset = false;
        };
      }
    }
  }
}
