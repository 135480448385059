<h4 mat-dialog-title2>{{data.title}}</h4>
<p class="mat-dialog-message">{{data.message}}</p>
<div mat-dialog-content>
    <mat-form-field style="width: 350px;">
        <input matInput [(ngModel)]="data.textValue" style="width: 350px;">
    </mat-form-field>
</div>

<div class="row">
    <button class="btn uni-btn-pos btn-lg" (click)="onOk()">Ok</button>&nbsp;&nbsp;&nbsp;
    <button class="btn uni-btn-neg btn-lg" (click)="onCancel()" *ngIf="data.cancelOk">Cancel</button>
</div>