import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { TakerGroupOrder } from '../models/order';
import { OrderService } from '../services/order.service';

@Component({
  selector: 'app-modal-taker-group',
  templateUrl: './modal-taker-group.component.html',
  styleUrls: ['./modal-taker-group.component.scss']
})
export class ModalTakerGroupComponent implements OnInit {
  groups: TakerGroupOrder[];
  //disps: TakerGroupOrderDisp[];

  constructor(private http: HttpClient, public orderService: OrderService,
    public dialogRef: MatDialogRef<ModalTakerGroupComponent>) { }

  ngOnInit(): void {
    let url = `${environment.apiUrl}/TakerGroup/Order/` + this.orderService.currentOrderId + `/` + this.orderService.currentTakerGroup;
    this.http.get(url).toPromise().then(res => {
      this.groups = res as TakerGroupOrder[];

      for(let a = 0; a < this.groups.length; a++)
      {
        //console.log(a + ":" + this.groups[a].TakerGroupId);
        this.groups[a].IsChecked = false;
        if(this.groups[a].TakerGroupId != null) this.groups[a].IsChecked = true;
      }
    });
  }

  onSave() {
    let adds = '';
    let dels = '';

    for(let a = 0; a < this.groups.length; a++)
    {
      if(this.groups[a].IsChecked) {
        if(this.groups[a].OrigTakerGroupId == null) {
          if(adds.length > 0) adds += ",";
          adds += this.groups[a].GroupId;
        }
      } else {
        if(this.groups[a].OrigTakerGroupId != null) {
          if(dels.length > 0) dels += ",";
          dels += this.groups[a].TakerGroupId;
        }
      }
    }
  
    //console.log(adds + ":" + dels + ":");

    this.orderService.putTaker(this.orderService.currentTaker).subscribe(
      res => {
        let vars = this.orderService.currentTaker.TakerId + ";" + adds + ";" + dels;
        this.http.get(`${environment.apiUrl}/TakerGroup/update/` + vars).toPromise().then(res => {
          this.dialogRef.close();
        });
      },
      error => {
        alert(error);
    });
  }
}
