<div class="p-4">
    <h3 class="tg-top-h">Edit Form</h3>
    <br>
    <form enctype="multipart/form-data" *ngIf="(form.FormId == 0)">
        <span class="btn btn-secondary btn-sm btn-file">
            Upload Form
        <input #fileInput name="fileToUpload" (change)="handleFileInput($event.target.files)" type="file"
                        accept="application/pdf, application/msword" [disabled]="(fileState!= 1)">
        </span>{{chosenFileName}}

        <!--button class="btn btn-secondary btn-sm" [disabled]="(fileState!= 2)">Upload</button-->
    </form>
    <br>
    <form #ngForm="ngForm" autocomplete="off">
        <div class="form-group row">
            <div class="col-sm-3"><label for="FormName">Title</label></div>
            <div class="col-sm-9"><input type="text" #FormName="ngModel" name="FormName" [(ngModel)]="form.FormName" class="form-control"
                required maxlength="100"  [disabled]="cantEdit"/></div>
        </div>
        <div class="form-group row">
            <div class="col-sm-3"><label for="FormName">Description</label></div>
            <div class="col-sm-9"><input type="text" #FormDesc="ngModel" name="FormDesc" [(ngModel)]="form.FormDesc" class="form-control"
                required maxlength="1000"  [disabled]="cantEdit"/></div>
        </div>
        <div class="form-group row">
            <div class="col-sm-3"><label for="DocumentName">File Name</label></div>
            <div class="col-sm-9"><input type="text" #DocumentName="ngModel" name="DocumentName" [(ngModel)]="form.DocumentName" class="form-control"
                required maxlength="100"  [disabled]="cantEdit"/></div>
        </div>
        <div class="form-group row">
            <div class="col-sm-3"><label for="SetupDone">Status</label></div>
            <div class="col-sm-9"><select #SetupDone="ngModel" name="SetupDone" [(ngModel)]="form.SetupDone" class="form-control" required disabled>
                <option value="0">Awaiting Review</option>
                <option value="1">Ready for Use</option>
            </select></div>
        </div>
        <div class="form-group row">
            <div class="col-sm-3"><label for="SendType">Final Document: Via</label></div>
            <div class="col-sm-2"><select #SendType="ngModel" name="SendType" [(ngModel)]="pform.SendType" class="form-control" required>
                <option>Email</option>
                <option>Fax</option>
                <option>N/A</option>
            </select></div>
            <div class="col-sm-1"><label for="SendTo">To</label></div>
            <div class="col-sm-6"><input type="text" #SendTo="ngModel" name="SendTo" [(ngModel)]="pform.SendTo" class="form-control" maxlength="100"/></div>
        </div>
    </form>
    <div class="row">
        <div class="col-sm-12">
            <button class="btn btn-primary paddr" (click)="onSave()" [disabled]="!ngForm.valid">OK</button>
            <button class="btn btn-warning" (click)="onCancel()">Cancel</button>
        </div>
    </div>
</div>
