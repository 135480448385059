<app-public-header></app-public-header>
<section class="home-banner p-b-30 home-banner-top-full">
    <div class="container login-container py-5">
        <div class="col-lg-5 col-md-6 col-sm-8 mx-3">
            <div class="p-lg-5 p-md-5 p-sm-5 p-4 rounded bg-white shadow">
                <div *ngIf="(loginMode === 'login')">
                    <h2 class="reg-color">Log in</h2><br>
                    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                        <div class="form-group mb-4">
                            <label for="EmailAddress" class="form-label is-invalid">Email</label>
                            <input type="text" size="50" formControlName="EmailAddress" class="form-control input-auto" [ngClass]="{ 'is-invalid': submitted && l.EmailAddress.errors }"/>
                            <div *ngIf="submitted && l.EmailAddress.errors" class="invalid-feedback">
                                <div *ngIf="l.EmailAddress.errors.required">Email is required</div>
                            </div>
                        </div>
                        <div class="form-group mb-4">
                            <div class="d-flex justify-content-between">
                                <label for="PasswordCd" class="form-label">Password</label>
                                <div class="d-flex justify-content-end md-1">
                                    <a class="a-link a-right" (click)="toForgotLogin('Reset')">Forgot password?</a>
                                </div>
                            </div>
                            <input type="password" size="50" formControlName="PasswordCd" class="form-control input-auto" [ngClass]="{ 'is-invalid': submitted && (l.PasswordCd.errors || loginFailed) }"/>
                            <div *ngIf="submitted && l.PasswordCd.errors" class="invalid-feedback">
                                <div *ngIf="l.PasswordCd.errors.required">Password is required</div>
                            </div>
                            <div *ngIf="submitted && loginFailed" class="invalid-feedback">
                                <div>We couldn't log you in. Please check your email and password and try again</div>
                            </div>
                        </div>
                        <br>
                        <div class="form-group d-grid gap-2">
                            <button [disabled]="loading" class="btn btn-primary a-next rounded-pill" type="submit">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Log in
                            </button>
                            <div class="d-flex justify-content-end mt-1">
                                First time here?<a class="a-link a-right" (click)="toCreateLogin()">Create login</a>
                            </div>
                        </div>
                    </form>
                    <div class="or-text text-secondary">Or</div>
                    <div class="d-grid gap-2">
                        <button class="btn btn-outline-secondary a-next rounded-pill" (click)="onGuest()">
                            Complete order as guest
                        </button>
                    </div>
                </div>
                <div *ngIf="(loginMode === 'forgot')">
                    <h2 class="reg-color">{{resetTitle}} your password</h2>
                    <br *ngIf="resetTitle !== 'Set'"/>
                    <button *ngIf="resetTitle === 'Set'" class="btn btn-link link-secondary rounded-pill ps-0 mb-2" (click)="toCreateLogin()"><i class="fa-solid fa-chevron-left pe-1"></i>Back</button>
                    <form [formGroup]="forgotForm" (ngSubmit)="onForgot()">
                        <div class="form-group mb-3">
                            <label for="EmailAddress" class="form-label">Email Address</label>
                            <input type="text" size="50" formControlName="EmailAddress" class="form-control input-auto" [ngClass]="{ 'is-invalid': submitted && f.EmailAddress.errors }" />
                            <div *ngIf="submitted && f.EmailAddress.errors" class="invalid-feedback">
                                <div *ngIf="f.EmailAddress.errors.required">Email Address is required</div>
                            </div>
                        </div>
                        <div class="form-group d-grid gap-2">
                            <button [disabled]="loading" class="btn btn-primary a-next rounded-pill" type="submit">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Continue
                            </button>
                            <div class="d-flex justify-content-end">
                                Already have a login?<a class="a-link a-right" (click)="toLoginMode()">Log in</a>
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="(loginMode === 'createLogin')">
                    <h2 class="reg-color">Let's get started</h2><br/>
                    <div class="mb-3">Select the option that best describes you</div>
                    <div class="d-grid gap-2">
                        <button class="btn btn-primary a-next rounded-pill" (click)="toCreateNewAccount()">
                            I have a company code and want to create my account
                        </button>
                    </div>
                    <div class="or-text text-secondary">Or</div>
                    <div class="d-grid gap-2">
                        <button class="btn btn-primary a-next rounded-pill" (click)="toForgotLogin('Set')">
                            Expidoc has my email and I want to set my password
                        </button>
                    </div>
                    <div class="d-flex justify-content-end mt-2">
                        Already have a login?<a class="a-link a-right" (click)="toLoginMode()">Log in</a>
                    </div>
                </div>
                <div *ngIf="(loginMode === 'newAccount')">
                    <h2 class="reg-color">Create your account</h2>
                    <button class="btn btn-link link-secondary rounded-pill ps-0 mb-2" (click)="toCreateLogin()"><i class="fa-solid fa-chevron-left pe-1"></i>Back</button>
                    <form [formGroup]="newloginForm" (ngSubmit)="onNewLogin()">
                        <div class="form-group mb-3">
                            <label for="EmailAddress" class="form-label">Email Address</label>
                            <input type="text" size="50" formControlName="EmailAddress" class="form-control input-auto" [ngClass]="{ 'is-invalid': submitted && n.EmailAddress.errors }" />
                            <div *ngIf="submitted && n.EmailAddress.errors" class="invalid-feedback">
                                <div *ngIf="n.EmailAddress.errors.required">Email Address is required</div>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label for="EmailAddressCfrm" class="form-label">Confirm Email Address</label>
                            <input type="text" size="50" formControlName="EmailAddressCfrm" class="form-control input-auto" [ngClass]="{ 'is-invalid': submitted && n.EmailAddressCfrm.errors }" />
                            <div *ngIf="submitted && n.EmailAddressCfrm.errors" class="invalid-feedback">
                                <div *ngIf="n.EmailAddressCfrm.errors.required">Confirm Email Address is required</div>
                                <div *ngIf="n.EmailAddressCfrm.errors.confirmedValidator">Confirm Email Address must match Email Address</div>
                            </div>
                        </div>
                        <div class="form-group mb-4">
                            <label for="CompanyCode" class="form-label">Company Code</label>
                            <input type="text" size="100" formControlName="CompanyCode" class="form-control input-auto" [ngClass]="{ 'is-invalid': submitted && n.CompanyCode.errors }" />
                            <div *ngIf="submitted && n.CompanyCode.errors" class="invalid-feedback">
                                <div *ngIf="n.CompanyCode.errors.required">Company Code is required</div>
                                <div *ngIf="n.CompanyCode.errors.notAllowed">Invalid input. Please check your email and company code and try again.</div>
                            </div>
                        </div>
                        <div class="form-group d-grid gap-2">
                            <button [disabled]="loading" class="btn btn-primary a-next rounded-pill" type="submit">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Continue
                            </button>
                            <div class="d-flex justify-content-end">
                                Already have a login?<a class="a-link a-right" (click)="toLoginMode()">Log in</a>
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="(loginMode === 'newCust')">
                    <h2 class="reg-color">Create your account</h2>
                    <button class="btn btn-link link-secondary rounded-pill ps-0 mb-2" (click)="toCreateNewAccount()"><i class="fa-solid fa-chevron-left pe-1"></i>Back</button>
                    <form [formGroup]="newCustomerForm" (ngSubmit)="onSubmitCust()">
                        <div class="d-flex">
                            <div class="form-group mb-3 me-2 col-8">
                                <label for="FirstName" class="form-label">First Name</label>
                                <input type="text" size="50" formControlName="FirstName" class="form-control input-auto" [ngClass]="{ 'is-invalid': submitted && c.FirstName.errors }"/>
                                <div *ngIf="submitted && c.FirstName.errors" class="invalid-feedback">
                                    <div *ngIf="c.FirstName.errors.required">First Name is required</div>
                                </div>
                            </div>
                            <div class="form-group mb-3 col-4">
                                <label class="col-sm-1 field-name d-flex align-items-center justify-content-end" for="MiddleName" class="form-label">Middle Name</label>
                                <input class="form-group col-sm-2" type="text" size="50" formControlName="MiddleName" class="form-control input-auto"/>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label for="LastName" class="form-label is-invalid">Last Name</label>
                            <input type="text" size="50" formControlName="LastName" class="form-control input-auto" [ngClass]="{ 'is-invalid': submitted && c.LastName.errors }"/>
                            <div *ngIf="submitted && c.LastName.errors" class="invalid-feedback">
                                <div *ngIf="c.LastName.errors.required">Last Name is required</div>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label for="Password" class="form-label is-invalid">Password</label>
                            <input type="password" size="50" formControlName="Password" class="form-control input-auto" [ngClass]="{ 'is-invalid': submitted && c.Password.errors }"/>
                            <div *ngIf="submitted && c.Password.errors" class="invalid-feedback">
                                <div *ngIf="c.Password.errors.required">Password is required</div>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label for="PasswordCfrm" class="form-label is-invalid">Confirm Password</label>
                            <input type="password" size="50" formControlName="PasswordCfrm" class="form-control input-auto" [ngClass]="{ 'is-invalid': submitted && c.PasswordCfrm.errors }"/>
                            <div *ngIf="submitted && c.PasswordCfrm.errors" class="invalid-feedback">
                                <div *ngIf="c.PasswordCfrm.errors.required">Confirm Password is required</div>
                                <div *ngIf="c.PasswordCfrm.errors.confirmedValidator">Confirm Password must match Password</div>
                            </div>
                        </div>
                        <div class="form-group mb-3">
                            <label for="PhoneNbr" class="form-label is-invalid">Phone Number</label>
                            <input type="text" mask="000-000-0000" placeholder="123-456-7890" formControlName="PhoneNbr" class="form-control input-auto" [ngClass]="{ 'is-invalid': submitted && c.PhoneNbr.errors }"/>
                            <div *ngIf="submitted && c.PhoneNbr.errors" class="invalid-feedback">
                                <div *ngIf="c.PhoneNbr.errors.required">Phone Number is required</div>
                            </div>
                        </div>
                        <div class="form-group d-grid gap-2">
                            <button [disabled]="loading" class="btn btn-primary a-next rounded-pill" type="submit">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Create login
                            </button>
                            <div class="d-flex justify-content-end">
                                Already have a login?<a class="a-link a-right" (click)="toLoginMode()">Log in</a>
                            </div>
                        </div>
                    </form>
                </div>
                <div *ngIf="(loginMode === 'sent')">
                    <h2 class="reg-color">Forgot login</h2><br>
                    <p>You will receive an email shortly if the address entered was found in our system. You may close this tab.</p>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>
