<div>
    <app-appheader [selectedPic]="selectedHdr"></app-appheader>
    <div class="page-container">
        <div class="page-content-2 container">
            <button class="btn btn-link link-secondary rounded-pill ps-0 mb-2" (click)="onBack()"><i class="fa-solid fa-chevron-left pe-1"></i>Back to Packages</button>
            <div class="package-header d-flex flex-column-reverse flex-md-row justify-content-between">
                <div *ngIf="!isEditingPackageName" class="d-flex flex-row align-items-center">
                    <h4>{{packageName}}</h4>
                    <i class="fa-solid fa-pencil fa-xs icon" (click)="onEditPackageName()"></i>
                </div>
                <div *ngIf="isEditingPackageName" class="d-flex flex-row align-items-center">
                    <input type="text" name="PackageName" class="form-control edit-package-field" required maxlength="50" (ngModelChange)="onPackageNameChanged($event)" [(ngModel)]="packageName"/>
                </div>
                <div *ngIf="isChanged && packageName.length > 0" class="discard-save d-flex justify-content-end align-items-center mb-2 mb-md-0">
                    <button type="button" class="btn button-tertiary d-flex align-items-center" (click)="onDiscardChanges()">Discard Changes</button>
                    <button *ngIf="package.CompanyId === 0" type="button" class="btn button-primary text-nowrap" (click)="onSaveAsCustomPackage()">Save as Custom Package</button>
                    <button *ngIf="package.CompanyId !== 0" type="button" class="btn button-primary text-nowrap" (click)="onSaveChanges()">Save Changes</button>
                </div>
            </div>
            <div class="table-container">
                <table mat-table class="tbl" [dataSource]="editedForms">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Form</th>
                        <td mat-cell *matCellDef="let form">{{form.FormName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef>Description</th>
                        <td mat-cell *matCellDef="let form">{{form.FormDesc}}</td>
                    </ng-container>
                    <ng-container matColumnDef="pdf">
                        <th mat-header-cell *matHeaderCellDef>PDF</th>
                        <td mat-cell *matCellDef="let form"><i class="fa-regular fa-file-pdf fa-pointer" (click)="showForm(form.FormId, form.DocumentName)"></i></td>
                    </ng-container>
                    <ng-container matColumnDef="remove">
                        <th mat-header-cell *matHeaderCellDef class="remove"></th>
                        <td mat-cell class="remove" *matCellDef="let form"><i class="fas fa-trash fa-pointer pl-5" (click)="onRemoveForm(form.FormId)"></i></td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
            <button type="button" class="btn btn-link rounded-pill add-form" (click)="onAddForm()"><i class="fa-solid fa-plus fa-sm me-1"></i>Add Form</button>
        </div>
    </div>
</div>
