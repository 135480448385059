import { PackageSetting } from './form';

export class OrderForm {
    OrderId: number;
    CustomerId: number;
    PackageId: number;
    OrderDate: Date;
    PackageCost: number;
    PaidFlg: string;
    LastFieldId: number;
    CompanyOrderNo: string;
    CustomHeader: string;
}

export class OrderFormCustomer {
    OrderId: number;
    CustomerId: number;
    OrderDate: Date;
    PackageId: number;
    PackageName: string;
    PackageDetail: string;
    PackageCost: number;
    PaidFlg: string;
    LastFieldId: number;
    LastGroupSeq: number;
    EmailAddress: string;
    CompanyOrderNo: string;
    CustomHeader: string;
}

export class OFormData {
  DataId: number;
  OrderId: number;
  FieldId: number;
  FieldValue: string;
}

export class OForms {
  answers: OFormData[];
}

export class Taker {
  TakerId: number;
  TakerName: string;
  EmailAddress: string;
  OrderId: number;
  TakerHint: string;
  StartSeq: number;
  FaxNumber: string;
  StartDate: Date;
  FinishDate: Date;
  Email1Date: Date;
  Email2Date: Date;
  SendMethod: string;
}

export class TakerGroup {
  TakerGroupId: number;
  TakerId: number;
  GroupId: number;
}

export class TakerGuest {
  TakerId: number;
  TakerName: string;
  EmailAddress: string;
  OrderId: number;
  LogoFile: string;
  PackageId: number;
  TakerHint: string;
  StartSeq: number;
}

export class TakerGroupOrder {
  GroupId: number;
  GroupTitle: string;
  TakerHint: string;
  TakerGroupId: number;
  OrigTakerGroupId: number;
  PageSeq: number;
  ProgressTitle: string;
  ShowIfField: string;
  ShowIfValue: string;
  IsChecked: boolean;
}

export class NewOrderPage
{
    OrderId: number;
    TakerId: number;
    CompanyId: number;
    WQCompanyId: number;
    errorDescription: string;
    takerGuest: TakerGuest;
    orderFormCustomers: OrderFormCustomer[];
    packageSettings: PackageSetting[];
    takerGroupOrders: TakerGroupOrder[];
    takers: Taker[];
}

export class WQCaseCreate
{
  CaseId: number;
  StageCtr: number;
  CaseTypeId: number;
  ErrorMessage: string;
}

export class WQOrderPackage
{
  OrderId: number;
  TakerA: number;
  TakerD: number;
  TakerDMethod: string;
  DocFax: string;
  ResultCode: number;
  ErrorMessage: string;
}

export class PdfGen
{
  FileName: string;
  ResultCode: number;
  ErrorMessage: string;
}

export class GenericResponse
{
  ReturnCode: boolean;
  ReturnId: number;
  DetailMessage: string;
}


export class SearchCriteria
{
  CName: string;
  Status: string;
  Package: string;
  FromDate: Date;
  ToDate: Date;
  Cid: number;
}

export class OrderFormFieldData
{
  FieldId: number;
  PdfField: string;
  FieldType: string;
  DataMask: string;
  DataId: number;
  FieldValue: string;
}

export class Subscription {
  SubscriptionId: number;
  SubscriptionName: string;
  SubscriptionDesc: string;
  SubscriptionPrice: number;
  ChargeFreq: string;
}

export class PackageSubscription {
  PackageSubscriptionId: number;
  PackageId: number;
  SubscriptionId: number;
}
