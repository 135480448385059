import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { FormFieldComponent } from '../form-field/form-field.component';
import { Field, FieldData, FieldGroup } from '../models/form';
import { FormService } from '../services/form.service';

@Component({
  selector: 'app-group-preview',
  templateUrl: './group-preview.component.html',
  styleUrls: ['./group-preview.component.scss']
})
export class GroupPreviewComponent implements OnInit {
  private sub: any;
  groupId: number;
  formGroup: FieldGroup;
  fields: FieldData[];

  constructor(private route: ActivatedRoute, private http: HttpClient, public formService: FormService) { }

  ngOnInit(): void {
    this.formGroup = new FieldGroup();

    this.sub = this.route.params.subscribe(params => {
      let gid = params['gid'];
      //console.log('loading preview for ' + gid);
      this.groupId = +gid;
      this.loadGroup();
    });
  }

  loadGroup() {
    this.http.get(`${environment.apiUrl}/FieldGroup/` + this.groupId).toPromise().then(res => {
        this.formGroup = res as FieldGroup;
        //console.log('got group');

        this.http.get(`${environment.apiUrl}/Field/GroupData/0/` + this.groupId + '/0').toPromise()
        .then(res => {
          //console.log('got data');
          this.formService.items = null;
          this.formService.initFields(0);

          let f = res as FieldData[];
          this.fields = f;
          this.formService.setItems(f);

          let len = this.formService.items.length;
          //console.log('there are ' + len + ' fields');
          this.formService.initFields(len);
        });
    });
  }
}
