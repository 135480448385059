<div>
    <app-appheader></app-appheader>
    <div class="container page-content-2" [class.mt-4]="!userService.currentEmail">
        <button *ngIf="userService.currentEmail" class="btn btn-link link-secondary rounded-pill ps-0 mb-2" (click)="onBackToOrder()"><i class="fa-solid fa-chevron-left pe-1"></i>Back to Order Details</button>
        <h4 class="header">{{orderService.currentPackage.PackageName}}</h4>
        <!--button (click)="linkTest()">Test</button-->
        <h6>{{orderService.currentOrder.CustomHeader}}</h6>
        <mat-stepper linear #stepper class="stepper-container overflow-x-auto" [selectedIndex]="currentStep" (selectionChange)="selectStep($event)" [animationDuration]="0">
            <mat-step *ngFor="let pg of formService.pages; let idx = index" [completed]="isValidFormCheck(idx)">
                <ng-template matStepLabel>{{pg.ProgressTitle}}</ng-template>
            </mat-step>
        </mat-stepper>
        <h4 class="mb-2">{{formGroup.GroupTitle}}</h4>
        <p>{{formGroup.GroupDesc}}</p>
        <ng-container *ngFor="let cols of formService.cols">
            <app-form-field-cols [cols]="cols"></app-form-field-cols>
        </ng-container>
        <div class="d-flex justify-content-end mt-3 mb-5">
            <button [disabled]="loading" type="button" class="btn btn-danger rounded-pill px-4" (click)="onCancel()">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Cancel
            </button>
            <button [disabled]="(formService.pageIdx == 0)" type="button" class="btn btn-outline-primary rounded-pill px-4 ms-2" (click)="onBack()">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                Back
            </button>
            <button [disabled]="loading" type="button" class="btn btn-primary rounded-pill px-4 ms-2" (click)="onNext()">
                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                {{nextTitle}}
            </button>
        </div>
    </div>
    <div class="modalSpinner" *ngIf="loaderLocked">
        <div class="wait-loader"></div>
    </div>
</div>