import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { SignupComponent } from './signup/signup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from './helpers/auth-guard';
import { OrderFormComponent } from './orderform/orderform.component';
import { OrderEditComponent } from './order-edit/order-edit.component';
import { FormGroupComponent } from './form-group/form-group.component';
import { OrderPayComponent } from './order-pay/order-pay.component';
import { GuestComponent } from './guest/guest.component';
import { ThanksComponent } from './thanks/thanks.component';
import { PagesComponent } from './pages/pages.component';
import { NextTakerComponent } from './next-taker/next-taker.component';
import { IntroComponent } from './intro/intro.component';
import { CompanyInviteComponent } from './company/company-invite/company-invite.component';
import { ThirdPartyComponent } from './third-party/third-party.component';
import { GroupPreviewComponent } from './group-preview/group-preview.component';
import { WnwdemoComponent } from './wnwdemo/wnwdemo.component';
import { CheckEmployeeComponent } from './check-employee/check-employee.component';
import { CheckAddressComponent } from './check-address/check-address.component';
import { CompanySettingsComponent } from './company/company-settings/company-settings.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CompanyLibraryComponent } from './company/company-library/company-library.component';
import { FormbuilderComponent } from './formbuilder/formbuilder.component';
import { PricingComponent } from './home/pricing/pricing.component';
import { CompanyUsersComponent } from './company/company-users/company-users.component';
import { PublicHowComponent } from './home/public-how/public-how.component';
import { PublicWhyComponent } from './home/public-why/public-why.component';
import { PublicScheduleComponent } from './home/public-schedule/public-schedule.component';
import { PackageDetailsComponent } from './package-details/package-details.component';
import { PublicAboutComponent } from './home/public-about/public-about.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { EntryComponent } from './entry/entry.component';
import { OrderDetailsComponent } from './order-details/order-details.component';

const appRoutes: Routes = [
  {path: '', component: HomeComponent},
  {path: 'home', component: HomeComponent},
  {path: 'about', component: PublicAboutComponent},
  {path: 'homehow', component: PublicHowComponent},
  {path: 'homewhy', component: PublicWhyComponent},
  {path: 'homesched', component: PublicScheduleComponent},
  {path: 'pricing', component: PricingComponent},
  {path: 'thanks', component: ThanksComponent},
  {path: 'signup', component: SignupComponent, canActivate: [AuthGuard]},
  {path: 'calendar', component: CalendarComponent},
  {path: 'dashboard/:cid', component: DashboardComponent},
  {path: 'formbuilder', component: FormbuilderComponent},
  {path: 'companylib', component: CompanyLibraryComponent},
  {path: 'companysettings', component: CompanySettingsComponent},
  {path: 'companyusrmgr', component: CompanyUsersComponent},
  {path: 'orderform', component: OrderFormComponent, canActivate: [AuthGuard], children: [
    {path: 'new', component: OrderEditComponent},
    {path: 'edit', component: OrderEditComponent},
    {path: 'pay', component: OrderPayComponent},
    {path: ':id', component: FormGroupComponent}
  ]},
  { path: 'login', component: LoginComponent },
  { path: 'entry', component: EntryComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'pages/:id', component: PagesComponent},
  { path: 'nexttaker', component: NextTakerComponent},
  { path: 'resetpassword/:prid', component: ResetPasswordComponent},
  { path: 'invite/:id', component: GuestComponent},
  { path: 'compnew/:cid/:pid', component: CompanyInviteComponent},
  { path: 'intro', component: IntroComponent},
  { path: 'nexttaker', component: NextTakerComponent},
  { path: '3rdparty/:action', component: ThirdPartyComponent},
  { path: 'grouppreview/:gid', component: GroupPreviewComponent},
  { path: 'wnwdemo', component: WnwdemoComponent},
  { path: 'wnwemp', component: CheckEmployeeComponent},
  { path: 'wnwaddr', component: CheckAddressComponent},
  { path: 'package/:pid', component: PackageDetailsComponent},
  { path: 'orderdetails/:oid', component: OrderDetailsComponent},

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

export const AppRoutingModule = RouterModule.forRoot(appRoutes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled' });
