<app-appheader></app-appheader>
<header>
    <div class="hero-text-box">
        <div class="row">
            <div class="col-sm-12 guest-mid-para">
                {{settingTitle}}<br>
                <div [innerHtml]="settingBody"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12 guest-col-btn">
                <a class="btn btn-std" routerLinkActive="active" routerLink="/pages/0">Next</a>
            </div>
        </div>
    </div>
</header>
