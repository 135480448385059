<div>
    <app-appheader [selectedPic]="selectedHdr"></app-appheader>       
    <div class="page-container">
        <div class="page-content container">
            <div *ngIf="userRole === 'admin'" class="d-flex flex-column flex-sm-row justify-content-between mb-3">
                <div class="d-flex align-items-center mb-2 mb-md-0">
                    <h4>Calendar for</h4>
                    <select name="Customer" placeholder="Customer" [(ngModel)]="currentCustomerId" class="form-select form-control w-auto ms-2 rounded-pill" (change)="handleCustomerChange()">
                        <option *ngFor="let customer of customers" name="Customer" [value]="customer.CustomerId">
                        {{customer.FirstName}} {{customer.LastName}}
                        </option>
                    </select>
                </div>
                <div class="dropdown-center text-end">
                    <button class="btn btn-primary rounded-pill px-4 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    + Add Event
                    </button>
                    <ul class="dropdown-menu align-items-end">
                        <li><button class="dropdown-item text-end" (click)="handleNewEvent()">New Event</button></li>
                        <li><hr class="dropdown-divider"></li>
                        <li><button class="dropdown-item text-end" (click)="handleEditEventType()">Edit Event Types</button></li>
                    </ul>
                </div>
            </div>
            <div *ngIf="userRole === 'customer'" class="d-flex justify-content-between align-items-center mb-3">
                <h4>Calendar</h4>
                <button class="btn btn-primary rounded-pill px-4" (click)="handleNewEvent()">+ New Event</button>
            </div>
            <full-calendar *ngIf="showCal" class="fccF mt-3" [options]="calendarOptions" #EmpCalendar>
            </full-calendar>
        </div>
    </div>
</div>