import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { UserService } from '../services/user.service';

@Injectable({ providedIn: 'root' })
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        //console.log('jwt processing ' + request.url);
        //console.log('   token is ' + JSON.stringify(this.authService.token));
        //let currentUser = this.authService.currentUser;
        if (this.authService.token) {
            //console.log('token is ' + this.authService.token);
            request = request.clone({
                setHeaders: { 
                    Authorization: `${this.authService.token}`
                }
            });
        }

        let token = this.authService.token;
        //console.log('JWT token is ' + token + ' for ' + request.url);
        if(token == null) token = '';
        if(token.length > 0) {
            const headers = request.headers.set('Authorization', this.authService.token);
            request = request.clone({headers});
        }

        return next.handle(request);
    }
}