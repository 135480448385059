import { Component, OnInit } from '@angular/core';
import { OrderService } from '../services/order.service';

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html',
  styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {
  settingBody: string = '';
  settingTitle: string = '';

  constructor(public orderService:OrderService) { }

  ngOnInit(): void {
    this.settingBody = this.orderService.getSetting('IntroBody' + this.orderService.currentGuest.TakerHint, this.settingBody);
    this.settingTitle = this.orderService.getSetting('IntroTitle' + this.orderService.currentGuest.TakerHint, this.settingTitle);
  }

}
