import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Customer } from '../models/customer';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public loggedIn: boolean = false;
    private currentUserSubject: BehaviorSubject<Customer>;
    public currentUser: Observable<Customer>;
    public currentCustomer: Customer;
    public token: string;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<Customer>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
        this.currentCustomer = this.currentUserSubject.value;
        //console.log('auth service init');
    }

    public get currentUserValue(): Customer {
        return this.currentUserSubject.value;
    }

    logout() {
        // remove user from local storage and set current user to null
        //console.log('logging out and clearing token');
        this.loggedIn = false;
        this.token = null;
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.setCurrentUser(null);
    }

    setCurrentUser(user) {
        //console.log("setting current customer:" + JSON.stringify(user));
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        this.currentUser = user;
        this.currentCustomer = user;
        //console.log('set cust token is ' + user.token);
        this.setToken(user.Token);

        this.loggedIn = (user);
    }

    setToken(tok: string) {
        //console.log('auth svc setting token to ' + tok);
        this.token = tok;
    }
}