import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CompanyDisp, CompanyLogin, Customer, LoginParams } from '../models/customer';
import { environment } from 'src/environments/environment';
import { Taker } from '../models/order';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class UserService {
    currentCustomer: Customer;
    currentTaker: Taker;
    currentEmail: string;
    currentCompany: CompanyDisp;
    currentDialogCompanyLogin: CompanyLogin;
    currentDialogCustomerId: number;
    currentDialogCustomer: Customer;
    currentDialogPackageId: number;
    currentDialogPackageFormId: number;
    currentDialogPackageNewId: number;
    dialogMode: number = 1;
    userRole: string;
    token: string = '';

    constructor(private http: HttpClient, private authService: AuthenticationService) { }

    getCustomer(): Customer {
        return this.currentCustomer;
    }

    getTaker(): Taker {
        return this.currentTaker;
    }

    
    getAll() {
        return this.http.get<Customer[]>(`${environment.apiUrl}/Customer`);
    }

    getCustomerDetail(id: number) {
        return this.http.get(`${environment.apiUrl}/Customer/` + id)
    }

    putCustomerDetail(cust: Customer) {
        return this.http.put(`${environment.apiUrl}/Customer/` + cust.CustomerId, cust)
    }
    

    register(user: Customer) {
        var url = environment.apiUrl + "/Customer";
        //console.log("registering to " + url);
        return this.http.post(url, user);
    }

    adminLogIn(username: string, password: string) {
        return new Promise(resolve => {
            let rtn = false;
            let params = new LoginParams();
            params.EmailAddress = username;
            params.PasswordCd = password;

            this.currentCustomer = null; 
            this.currentCompany = null;

            this.http.post(`${environment.apiUrl}/Login/companylogin`, params).subscribe(
                r => {
                    this.currentCompany = r as CompanyDisp;
                    this.currentEmail = username;
                    this.userRole = 'admin';
                    this.authService.setToken(this.currentCompany.Token);
                    this.token = this.currentCompany.Token;
                    resolve(this.currentCompany);
                },
                error => {
                    this.currentCompany = null; 
                    resolve(this.currentCompany);
                }
            );
        });
        //{ params: {email: username,  pwd: password }
    }

    logIn(username: string, password: string) {
        return new Promise(resolve => {
            let rtn = false;
            let params = new LoginParams();
            params.EmailAddress = username;
            params.PasswordCd = password;

            this.http.post(`${environment.apiUrl}/Login/customerlogin`, params).subscribe(
                r => {
                    this.currentCustomer = r as Customer;
                    this.currentEmail = username;
                    this.userRole = 'customer';
                    this.token = this.currentCustomer.Token;

                    //console.log("logged in:" + this.currentCustomer.FirstName + ' with token ' + this.token);
                    resolve(this.currentCustomer);
                },
                error => {
                    this.currentCustomer = null; 
                    resolve(this.currentCustomer);
                }
            );
        });
        //{ params: {email: username,  pwd: password }
    }

    forceLogin(tid: string) {
        return new Promise(resolve => {
            this.http.get(`${environment.apiUrl}/Login/forcecompanylogin/` + tid).subscribe(
                r => {
                    this.currentCompany = r as CompanyDisp;
                    resolve(this.currentCompany);
                },
                error => {
                    this.currentCompany = null; 
                    resolve(this.currentCompany);
                }
            );
        });
    }

    delete(id: number) {
        return this.http.delete(`${environment.apiUrl}/Customer/${id}`);
    }
}
