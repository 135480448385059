import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';
import { map } from "rxjs/operators";
import { OrderFormCustomer, OrderForm, Taker, TakerGuest } from '../models/order';
import { Package, PackageSetting } from '../models/form';

@Injectable({providedIn: 'root'})
export class OrderService {
  pageSteps$: Observable<boolean>;
  resetList$: Observable<boolean>;
  orders: OrderFormCustomer[];
  currentOrderForm: OrderForm;
  currentOrder: OrderFormCustomer;
  currentOrderId: number;
  currentPackageId: number;
  currentOrderTitle: string;
  currentPackage: Package;
  currentCanDownload: string;
  currentGroup: number;
  currentTakerGroup: number;
  currentTaker: Taker;
  nextTakerHint: string;
  packageSettings: PackageSetting[];
  currentGuest: TakerGuest;
  thanksPageThanksObject: string = '';
  pageResult: boolean = true;
  pageText: string = '';
  dashReturnId: number = -1;
  
  private resetSubject: Subject<boolean>;
  private pageStepsSubject: Subject<boolean>;

  constructor(private http: HttpClient) {
    this.pageStepsSubject = new Subject<boolean>();
    this.pageSteps$ = this.pageStepsSubject.asObservable();
    this.resetSubject = new Subject<boolean>();
    this.resetList$ = this.resetSubject.asObservable();
    this.packageSettings = new Array<PackageSetting>();
  }

  setCurrentOrderForm(order: OrderForm) {
    this.currentOrderForm = order;
    this.currentOrderId = order.OrderId;
  }

  setCurrentOrder(order: OrderFormCustomer) {
    this.currentOrder = order;
    if(order) {
      //console.log("current order is " + JSON.stringify(order));
      this.currentOrderId = order.OrderId;
      this.currentPackageId = order.PackageId;
      this.currentOrderTitle = order.PackageName;
      this.currentCanDownload = this.canDownload(order.PaidFlg);
      this.currentGroup = 1;
    }else {
      //console.log("current order is null");
      this.currentOrderId = 0
      this.currentOrderTitle = "New Order";
      this.currentCanDownload = this.canDownload("N");
      this.currentGroup = 1;
    }
  }

  setCurrentPackage(p: Package) {
    this.currentPackage = p;
  }

  setOrder(index: number) {
    this.currentOrder = this.orders[index];
    this.currentOrderId = this.currentOrder.OrderId;
    this.currentOrderTitle = this.currentOrder.PackageName;
    this.currentCanDownload = this.canDownload(this.currentOrder.PaidFlg);
    this.currentGroup = 1;
  }

  setGroup(seq: number) {
    this.currentGroup = seq;
  }

  pageStepsDone(val: boolean) {
    this.pageStepsSubject.next(val);
  }

  refreshList(id: number) {
    this.resetSubject.next(true);
    this.http.get(`${environment.apiUrl}/OrderForm/Customer/` + id).toPromise().then(res => this.orders = res as OrderFormCustomer[]);
  }

  getOrderFormDetail(id: number) {
    return this.http.get(`${environment.apiUrl}/OrderForm` + id);
  }

  putOrderFormDetail(order: OrderForm) {
    return this.http.post(`${environment.apiUrl}/OrderForm`, order);
  }

  postTaker(taker: Taker) {
    return this.http.post(`${environment.apiUrl}/Taker`, taker);
  }

  putTaker(taker: Taker) {
    let tid = taker.TakerId;

    return this.http.put(`${environment.apiUrl}/Taker/` + tid, taker);
  }

  isPaid(paidFlg: string) {
    if(paidFlg == "Y") {
        return "PAID";
    } else {
        return "Due";
    }
  }

  canDownload(paidFlg: string) {
    if(paidFlg == "Y") {
        return "true";
    } else {
      return "false";
    }
  }

  orderStatus(lastFieldId) {
    if(lastFieldId == 0) {
      return "Not Started";
    } else if (lastFieldId == 999) {
      return "Completed";
    } else {
      return "In Progress";
    }
  }

  getPdf(url: string): any {
    return this.http.get<any>(url, { responseType: 'blob' as 'json'}).pipe(map(res => {return new Blob([res], { type: 'application/pdf' });  }));
  }

  getSetting(key: string, defaultVal: string): string {
    let rtn = defaultVal;
    //console.log(key + ":" + this.packageSettings.length);

    if(this.packageSettings.length > 0) {
      for(let a = 0; a < this.packageSettings.length; a++) {
        let ps = this.packageSettings[a];
        //console.log(ps.KeyName + " = " + key + ' ' + ps.ValueDesc);
        if(ps.KeyName == key) {
          rtn = ps.ValueDesc;
          //console.log('    = ' + rtn);
          a = this.packageSettings.length;
        }
      }
    }
    return rtn;
  }
}
