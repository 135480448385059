import { Component, OnInit } from '@angular/core';
import { OrderService } from '../services/order.service';
import { AlertService } from '../services/alert.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-order-pay',
  templateUrl: './order-pay.component.html',
  styleUrls: ['./order-pay.component.scss']
})
export class OrderPayComponent implements OnInit {
  loading = false;

  constructor(public orderService: OrderService,
    private alertService: AlertService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  onNext() {
    this.orderService.setGroup(1);
    this.router.navigate(['orderform/1']);
  }

  onCancel() {
    this.router.navigateByUrl('/dashboard/' + this.orderService.dashReturnId);
  }
}
