import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { LoaderService } from '../services/loader.service';

@Component({
    templateUrl: 'entry.component.html',
    styleUrls: ['./entry.component.scss']
})
export class EntryComponent implements OnInit {
    form: UntypedFormGroup = new UntypedFormGroup({});
    loading = false;

    constructor(private formBuilder: UntypedFormBuilder, public loaderService: LoaderService) {
      this.form = this.formBuilder.group({
        orderId: ['', Validators.required],
        takerId: ['', Validators.required]
      });
    }

    ngOnInit() {
    }

    onSubmit() {      
      this.loaderService.checkTO(this.form.value.orderId, this.form.value.takerId, '/pages/0');
    }
}
