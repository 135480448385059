import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GenericString } from 'src/app/models/customer';
import { Form, PackageForm } from 'src/app/models/form';
import { DialogService } from 'src/app/services/dialog.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dialog-package-form',
  templateUrl: './dialog-package-form.component.html',
  styleUrls: ['./dialog-package-form.component.scss']
})
export class DialogPackageFormComponent implements OnInit {
  form: Form;
  pform: PackageForm;
  cantEdit: boolean = true;

  fileState: number = 1;
  chosenFile: File;
  chosenFileName: string = '';

  @ViewChild('fileInput') fileInput:ElementRef;

  constructor(private http: HttpClient, public userService: UserService, public dialogService: DialogService,
    public dialogRef: MatDialogRef<DialogPackageFormComponent>) { }

  ngOnInit(): void {
    this.form = new Form();
    this.pform = new PackageForm();

    let fid = this.userService.currentDialogPackageFormId;
    if(fid > 0) {
      this.http.get(`${environment.apiUrl}/PackageForm/` + fid).toPromise().then(res => {
        this.pform = res as PackageForm;
        //console.log('pf is ' + JSON.stringify(this.pform));
        let pid = this.pform.FormId;
        this.http.get(`${environment.apiUrl}/Form/` + pid).toPromise().then(res => {
          this.form = res as Form;
        });
      });
    } else {
      this.form.PackageId = fid * -1;
      this.form.FormId = 0;
      this.form.SetupDone = 0;

      this.pform.PackageFormId = 0;
      this.pform.FormId = 0;
      this.pform.PackageId = fid * -1;
      this.pform.SendType = 'Email';

      this.cantEdit = false;
    }
  }

  ngAfterViewInit() {
    if(this.pform.PackageFormId == 0) {
      let el: HTMLElement = this.fileInput.nativeElement;
      el.click();
    }
  }
  
  handleFileInput(files: FileList) {
    this.chosenFile = files.item(0);
    this.chosenFileName = files.item(0).name;
    this.form.FormName = files.item(0).name;
    this.form.DocumentName = files.item(0).name;
  }

  onSave() {
    if(! this.cantEdit) {
      this.http.post(`${environment.apiUrl}/Form/CheckNew/`, this.form).toPromise().then(res => {
        let rtn = res as GenericString;
        let msg = '';
        //if(rtn.ValueDes.indexOf('C') >= 0) msg += 'This Code already exists\n';
        if(rtn.ValueDes.indexOf('F') >= 0) msg += 'This File already exists\n';
        //console.log('checknew returned ' + rtn.ValueDes);
        if(msg.length > 0) {
          this.dialogService.openMessageDialog('Save Form', msg);
        } else {
          this.saveNewform();
        }
      });
    } else {
      this.savePF();
    }
  }

  saveNewform() {
    this.http.post(`${environment.apiUrl}/Form`, this.form).toPromise().then(res => {
      let f = res as Form;
      this.form = f;
      this.pform.FormId = f.FormId;
      //console.log('saved file - id is ' + this.form.FormId);
      this.saveFile();
    });
  }

  saveFile() {
    const uploadData = new FormData();
    uploadData.append('file', this.chosenFile, this.chosenFileName);
    let lid = this.chosenFileName.lastIndexOf('.');
    let ext = this.chosenFileName.substring(lid + 1);
    //console.log('uploading ' + this.chosenFileName + ' - ' + ext);

    this.http.post(`${environment.apiUrl}/Form/UploadFile`, uploadData).toPromise().then(res => {
      this.savePF();
    });
  }

  savePF() {
    if(this.pform.PackageFormId == 0) {
      this.http.post(`${environment.apiUrl}/PackageForm`, this.pform).toPromise().then(res => {
        this.dialogRef.close();
      });
    } else {
      //console.log('putting pf: ' + JSON.stringify(this.pform));
      this.http.put(`${environment.apiUrl}/PackageForm/` + this.pform.PackageFormId, this.pform).toPromise().then(res => {
        this.dialogRef.close();
      });
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
function ViewQuery(arg0: string) {
  throw new Error('Function not implemented.');
}

