<div class="container">
    <div class="col-sm-6">
        <h1>Order Details</h1>
        <form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
            <input type="hidden" name="OrderId" [value]="orderForm.OrderId">

            <div class="form-group">
                <label for="PackageId">Package</label>
                <select #PackageId="ngModel" name="PackageId" [(ngModel)]="orderForm.PackageId" class="form-control" required [disabled]="orderForm.OrderId>0">
                    <option *ngFor="let frm of formService.packages" value= {{frm.PackageId}}>
                        {{frm.PackageName}}
                    </option>
                </select>
            </div>

            <div class="form-group">
                <label for="OrderDate">Order Date</label>
                <div class="date-picker-row">
                    <input type="text" #OrderDate="ngModel" name="OrderDate" text-date-input [(ngModel)]="orderForm.OrderDate" class="form-control-date form-control" [matDatepicker]="OrderDateDatepicker" disabled/>
                    <mat-datepicker-toggle class="form-control-datepicker" matSuffix [for]="OrderDateDatepicker"></mat-datepicker-toggle>
                    <mat-datepicker #OrderDateDatepicker></mat-datepicker>
                </div>
            </div>

            <div class="row">
                <div class="col col-sm-6">
                    <div class="form-group">
                        <label for="PackageCost">Cost</label>
                        <input type="text" #PackageCost="ngModel" name="PackageCost" [(ngModel)]="orderForm.PackageCost" class="form-control" disabled/>
                    </div>        
                </div>
                <div class="col col-sm-1">
                    <div class="form-group">
                        <label for="PaidFlg">Paid</label>
                        <input type="checkbox" #PaidFlg="ngModel" name="PaidFlg" [(ngModel)]="orderForm.PaidFlg"
                            ng-true-value="Y" ng-false-value="N" class="form-control" disabled/>
                    </div>        
                </div>
            </div>

            <div class="form-group">
                <div *ngIf="orderForm.OrderId==0; then submitButton else nextButton"></div>
                <ng-template #submitButton>
                <button [disabled]="loading" type="submit" class="btn btn-primary">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Save
                </button>
                </ng-template>
                <ng-template #nextButton>
                <button [disabled]="loading" type="button" class="btn btn-primary" (click)="onNext()">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Next
                </button>
                </ng-template>
                &nbsp;
                <button [disabled]="loading" type="button" class="btn btn-cancel" (click)="onReset()">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Reset
                </button>
                &nbsp;
                <button [disabled]="loading" type="button" class="btn btn-danger" (click)="onCancel()">
                    <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                    Cancel
                </button>
            </div>
        </form>
    </div>
    <div class="col-sm-6">
        <h1>Who Can Help Out</h1>
        <app-taker-list></app-taker-list>
    </div>
</div>
