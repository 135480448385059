import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Package } from 'src/app/models/form';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { DialogPackageFormComponent } from '../dialog-package-form/dialog-package-form.component';

@Component({
  selector: 'app-dialog-package',
  templateUrl: './dialog-package.component.html',
  styleUrls: ['./dialog-package.component.scss']
})
export class DialogPackageComponent implements OnInit {
  package: Package;
  pkgCbx: PkgCbx;
  cantEdit: boolean = true;

  fileState: number = 1;
  chosenFile: File;
  chosenFileName: string = '';

  constructor(private http: HttpClient, public userService: UserService, public dialogFrm: MatDialog,
    public dialogRef: MatDialogRef<DialogPackageComponent>) { }

  ngOnInit(): void {
    this.package = new Package();
    this.pkgCbx = new PkgCbx();
    this.pkgCbx.SeeData = false;
    
    let pid = this.userService.currentDialogPackageId;
    if(pid > 0) {
      let url = `${environment.apiUrl}/Package/` + pid;
      this.http.get(url).toPromise().then(res => {
        this.package = res as Package;
        this.pkgCbx.SeeData = (this.package.SeeData == 'Y');
      });
    } else {
      this.package.PackageId = 0;
      this.package.ReminderDays = 0;
      this.package.SeeData = 'N';
      this.cantEdit = false;
    }
  }

  handleFileInput(files: FileList) {
    this.chosenFile = files.item(0);
    this.chosenFileName = files.item(0).name;

  }

  onSave() {
    this.package.SeeData = this.pkgCbx.SeeData ? 'Y' : 'N';

    if(this.package.PackageId == 0) {
      this.http.post(`${environment.apiUrl}/Package`, this.package).toPromise().then(res => {
        this.package = res as Package;

        if(this.package.PackageId > 0) this.userService.currentDialogPackageNewId = this.package.PackageId;

        this.dialogRef.close();
      });
    } else {
      this.http.put(`${environment.apiUrl}/Package/` + this.package.PackageId, this.package).toPromise().then(res => {
        this.dialogRef.close();
      });
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}

export class PkgCbx {
  SeeData: boolean;
}