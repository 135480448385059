<div>
    <app-appheader [selectedPic]="selectedHdr"></app-appheader>
    <div class="page-container">
        <div class="page-content container">
            <h4>Packages</h4>
            <div class="d-flex mt-5">
                <input type="text" class="form-control search-field rounded-pill" (keyup)="handleSearchFilter($event)" placeholder="Search..."/>
                <select class="form-select form-control select-field rounded-pill ms-3" name="Type" placeholder="Type" [(ngModel)]="typeFilter" (change)="handleTypeFilter()">
                    <option *ngFor="let type of typeFilterOptions" [value]="type.value">
                        {{type.name}}
                    </option>
                </select>
            </div>
            <div class="table-container">
                <table mat-table class="tbl" [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="PackageName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Package</th>
                        <td mat-cell *matCellDef="let package">{{package.PackageName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="CompanyId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                        <td mat-cell *matCellDef="let package">{{package.CompanyId == 0 ? 'Default' : 'Custom'}}</td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navTo(row.PackageId)"></tr>
                </table>
            </div>
        </div>
    </div>
</div>
