import { HttpClient, HttpParams } from "@angular/common/http";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { StringCaptureDialog } from "../modal-pdf/string-capture-dialog";
import { WQCaseCreate, WQOrderPackage } from "../models/order";
import { ContactTakers } from "./contact-takers";

export class Worquest {
    http: HttpClient;
    baseUrl: string;
    lastError: string;
    public wqCompleted = new Subject<number>();

    constructor(http: HttpClient, url: string) {
        this.http = http;
        this.baseUrl = url;
    }

    public NewCase(oid: number) {
        this.lastError = '';

        let url = this.baseUrl + '/ThirdParty/wqcase/' + oid;
        //console.log('3rd ' + url);
        this.http.get(url).subscribe(res => {
            let cc = res as WQCaseCreate;
            //console.log('3rd wqcase: ' + JSON.stringify(cc));

            if(cc.CaseTypeId > 0) {
              // contact doc, if specified
              url = `${environment.apiUrl}/ThirdParty/wqneworder/` + cc.CaseId + '/3';
              //console.log('3rd new order' + url);
              this.http.get(url).subscribe(res => {
                let op = res as WQOrderPackage;
                console.log('3rd neword ' + JSON.stringify(op));

                if(op.ResultCode == 1) {
                  let ct = new ContactTakers(this.http, null);
                  
                  let ctUrl = this.baseUrl + '/PdfGen/gen/' + op.OrderId + '/0';
                  ct.addStep('PDF', ctUrl, null, '', '');

                  if(op.TakerDMethod == 'E' || op.TakerDMethod == 'B') {
                    ctUrl = this.baseUrl + '/Mail/sendTaker/' + op.TakerD;
                    let params = new HttpParams().append('subject', 'Worquest Patient Data Request').append('template', 'wqdoctorinvite.html');
                    ct.addStep('E', ctUrl, params, 'addpdf', 'thanks');
                  }

                  if(op.TakerDMethod == 'F' || op.TakerDMethod == 'B') {
                    ctUrl = this.baseUrl + '/Mail/send/' + op.OrderId;
                    let paramsf = new HttpParams().append('sendVia', 'fax').append('address', '1' + ct.cleanFax(op.DocFax)).append('subject', 'Worquest Patient Data Request').append('template', 'WQCaseFax.txt');
                    ct.addStep('F', ctUrl, paramsf, 'addpdf', 'thanks');
                  }

                  if(op.TakerDMethod == 'P') {
                    ctUrl = this.baseUrl + '/Mail/invite/' + op.TakerA;
                    ct.addStep('E', ctUrl, null, '', 'thanks');
                  } else {
                    ctUrl = this.baseUrl + '/Mail/sendTaker/' + op.TakerA;
                    let paramsp = new HttpParams().append('subject', 'Worquest Case Update').append('template', 'wqdocsattached.html');
                    ct.addStep('E', ctUrl, paramsp, 'addpdf', 'thanks');
                  }

                  //console.log('ct subscription setup: ' + JSON.stringify(ct));

                  let subscription = ct.contactCompleted.subscribe((ok: boolean) => {
                      console.log('ct subscription reply: ' + ok + ' : ' + ct.lastError);
                      if(ok) {
                        if(ct.routeTo != '') {
                            this.wqCompleted.next(cc.CaseId);
                        } else {
                          console.log('NOT CONFIGURED TO ROUTE TO DIFFERENT PAGE');
                          this.wqCompleted.next(-1);
                        }
                      } else {
                        this.lastError = 'Something went wrong contacting your doctor: ' + ct.lastError;
                        this.wqCompleted.next(-1);
                      }
                    }
                  );

                  ct.start();

                } else {
                    this.lastError = 'Something went wrong creating your paperwork: ' + op.ErrorMessage;
                    callback: (-1);
                }
              });
            } else {
                this.lastError = 'Something went wrong creating your case: ' + cc.ErrorMessage;
                callback: (-1);
            }

            //this.orderService.packageSettings[idx].ValueDesc = tbody;
        });
    }

    public DocDone(oid: number, tid: number, emailAddress: string) {
        let ct = new ContactTakers(this.http, null);
                
        let ctUrl = `${environment.apiUrl}` + '/PdfGen/gen/' + oid + '/0';
        ct.addStep('PDF', ctUrl, null, '', '');

        ctUrl = `${environment.apiUrl}` + '/Mail/send/' + oid;
        let paramsp = new HttpParams().append('sendVia', 'mail').append('address', emailAddress).append('subject', 'Worquest Case Update').append('template', 'wqempdocsattached.html');
        ct.addStep('E', ctUrl, paramsp, 'addpdf', '');
        
        ctUrl = `${environment.apiUrl}` + '/Mail/sendTaker/' + tid;
        let paramsd = new HttpParams().append('subject', 'Worquest Case Update').append('template', 'wqdocdocsattached.html');
        ct.addStep('E', ctUrl, paramsd, 'addpdf', '');
        
        let subscription = ct.contactCompleted.subscribe((ok: boolean) => {
            //console.log('ct subscription reply: ' + ok + ' : ' + ct.lastError);
            if(ok) {
                this.wqCompleted.next(1);
            } else {
                this.lastError = 'Something went wrong sending your patient thier documents: ' + ct.lastError;
                this.wqCompleted.next(-1);
            }
        });

        ct.start();
    }
}
