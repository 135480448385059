import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Customer, UserCriteria } from 'src/app/models/customer';
import { CompanyService } from 'src/app/services/company.service';
import { DialogService } from 'src/app/services/dialog.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { DialogCustomerComponent } from '../dialog-customer/dialog-customer.component';
import { saveAs } from 'file-saver';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-company-users',
  templateUrl: './company-users.component.html',
  styleUrls: ['./company-users.component.scss']
})
export class CompanyUsersComponent implements OnInit {
  crit: UserCriteria;
  selectedHdr: string = 'usrmgr';
  dataSource: MatTableDataSource<any | Customer>;
  displayedColumns: string[] = ['FirstName', 'LastName', 'EmailAddress', 'GroupDes'];
  searchFilter: string;
  fileState = 1;
  uploadMsg: string = '';
  chosenFile: File;
  chosenFileName: string = '';

  constructor(private http: HttpClient, public companyService: CompanyService, public userService: UserService,
              public dialogPkg: MatDialog, public dialogService: DialogService, private router: Router) {}

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    if(this.userService.currentCompany == null) this.router.navigateByUrl('/login');

    this.getCustomers();
  }

  editCustomer(cid: number){
    this.userService.dialogMode = 1;
    this.userService.currentDialogCustomerId = cid;
    const dialogRef = this.dialogPkg.open(DialogCustomerComponent, { minHeight: '300px', minWidth: '50vw', maxHeight: '90vh', data: {step: 'edit'}});

    dialogRef.afterClosed().subscribe(() => {
      // Do stuff after the dialog has closed
      this.getCustomers();
    });
  }

  newCustomer(){
    this.userService.dialogMode = 2;
    this.userService.currentDialogCustomerId = 0;
    const dialogRef = this.dialogPkg.open(DialogCustomerComponent, { minHeight: '300px', minWidth: '50vw', maxHeight: '90vh', data: {step: 'create'}});

    dialogRef.afterClosed().subscribe(() => {
      this.getCustomers();
    });
  }
  
  getCustomers() {
    this.http.get(`${environment.apiUrl}/Customer/Company/` + this.userService.currentCompany.CompanyId).subscribe(res => {
      let customers = res as Customer[];
      if(customers.length > 0) {
        this.dataSource = new MatTableDataSource(customers);
        this.dataSource.sort = this.sort;
      }
    });
  }

  onChooseFile() {
    document.getElementById('file-upload').click()
  }


  handleFileInput(files: FileList) {
    this.chosenFile = files.item(0);
    this.chosenFileName = files.item(0).name;

    let fileReader = new FileReader();

    fileReader.onloadend = (e) => {
      if (typeof fileReader.result === 'string')
      {
        let liners = fileReader.result;
        let lines = liners.split('\n');
              
        if(lines.length >= 2)
        {
          if(lines[0].trim() == 'Email,First Name,Middle Name,Last Name,Address 1,Address 2,City,State,Zip,Phone Number,Date of Birth,Hire Date,Title,Location,State of Employment,Union Name or None,Supervisor Name')
          {
            this.saveData(`${environment.apiUrl}/Customer/Upload/` + this.userService.currentCompany.CompanyId, lines).subscribe(data => {
              saveAs(data, 'FileUploadResult.csv');
              this.getCustomers();
            }, error => {
              this.uploadMsg = 'Error uploading file';
              this.dialogService.openMessageDialog('Upload Users', this.chosenFileName + ' - ' + this.uploadMsg);
            });
          } else {
            this.uploadMsg = 'File is missing the header line';
            this.dialogService.openMessageDialog('Upload Users', this.chosenFileName + ' - ' + this.uploadMsg);
          }
        } else {
          this.uploadMsg = 'File does not contain any lines';
          this.dialogService.openMessageDialog('Upload Users', this.chosenFileName + ' - ' + this.uploadMsg);
        }
      } else {
        this.uploadMsg = 'File is not in the correct format';
        this.dialogService.openMessageDialog('Upload Users', this.chosenFileName + ' - ' + this.uploadMsg);
      }
    }

    fileReader.readAsText(this.chosenFile);

  }

  saveData(path: string, data: any): any {
    return this.http.post<any>(path, data, { responseType: 'blob' as 'json'}).pipe(map(res => {return new Blob([res], { type: 'text/csv' });  }));
  }

  handleSearchFilter(event: Event) {
    this.searchFilter = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.searchFilter.trim().toLowerCase();
  }
}
export {UserCriteria}