<div>
    <app-appheader [selectedPic]="selectedHdr"></app-appheader>         
    <div class="page-container">
        <div class="page-content-2 container">
            <button class="btn btn-link link-secondary rounded-pill ps-0 mb-2" (click)="onCancel()"><i class="fa-solid fa-chevron-left pe-1"></i>Back to Orders</button>
            <div *ngIf="(orderStep == 1) && userService.currentCompany">
                <h4>Select an {{userService.currentCompany.CustomerTitle}}</h4>
                <div class="d-flex align-items-center mt-5">
                    <input type="text" class="form-control search-field rounded-pill" (keyup)="handleCustSearchFilter($event)" placeholder="Search..."/>
                </div>
                <div class="table-container">
                    <table mat-table class="tbl" [dataSource]="dataSourceCustomers" matSort>
                        <ng-container matColumnDef="FirstName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
                            <td mat-cell *matCellDef="let customer">{{customer.FirstName}}</td>
                        </ng-container>
                        <ng-container matColumnDef="LastName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
                            <td mat-cell *matCellDef="let customer">{{customer.LastName}}</td>
                        </ng-container>
                        <ng-container matColumnDef="EmailAddress">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Address</th>
                            <td mat-cell *matCellDef="let customer">{{customer.EmailAddress}}</td>
                        </ng-container>
                        <ng-container matColumnDef="GroupDes">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Group</th>
                            <td mat-cell *matCellDef="let customer">{{customer.GroupDes}}</td>
                        </ng-container>
                        <ng-container matColumnDef="Edit">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let customer"><i class="fa fa-edit lib-ico" (click)="editCust(customer)" title="Edit"></i></td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="customerColumns; sticky: true;"></tr>
                        <tr mat-row *matRowDef="let row; columns: customerColumns;" (click)="onCustomerSelection(row)" (dblclick)="onSelectCustomer()" [class.tbl-row2-sel]="row.CustomerId === selectedCust?.CustomerId"></tr>
                    </table>
                </div>
                <div class="d-flex justify-content-end mt-3 align-items-center">
                    <button class="btn btn-link rounded-pill ps-0 pe-3" (click)="onNewCustomer()">{{userService.currentCompany.CustomerTitle}} Not Listed</button>
                    <button type="button" class="btn btn-outline-primary rounded-pill px-4 me-3" (click)="onCancel()">Cancel</button>
                    <button class="btn btn-primary rounded-pill px-4" (click)="onSelectCustomer()" [disabled]="!selectedCust?.CustomerId">Next</button>
                </div>
            </div>
            <div *ngIf="(orderStep == 2)">
                <h4>Select a Package</h4>
                <div class="d-flex align-items-center mt-5">
                    <input type="text" class="form-control search-field rounded-pill" (keyup)="handlePackageSearchFilter($event)" placeholder="Search..."/>
                </div>
                <div class="table-container">
                    <table mat-table class="tbl" [dataSource]="dataSourcePackages" matSort>
                        <ng-container matColumnDef="PackageName">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Package</th>
                            <td mat-cell *matCellDef="let package">{{package.PackageName}}</td>
                        </ng-container>
                        <ng-container matColumnDef="CompanyId">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
                            <td mat-cell *matCellDef="let package">{{package.CompanyId == 0 ? 'Default' : 'Custom'}}</td>
                        </ng-container>
                        <ng-container *ngIf="this.userService.userRole === 'admin'" matColumnDef="Edit">
                            <th mat-header-cell *matHeaderCellDef></th>
                            <td mat-cell *matCellDef="let package"><i class="fa fa-edit lib-ico" (click)="editPackage(package)" title="Edit"></i></td>
                        </ng-container>
                        <tr mat-header-row *matHeaderRowDef="packageColumns; sticky: true;"></tr>
                        <tr mat-row *matRowDef="let row; columns: packageColumns;" (click)="onPackageSelection(row)" (dblclick)="onSelectPackage()" [class.tbl-row2-sel]="row.PackageId === selectedPackage?.PackageId"></tr>
                    </table>
                </div>
                <div class="d-flex justify-content-end mt-3 align-items-center">
                    <button *ngIf="this.userService.userRole === 'admin'" type="button" class="btn btn-outline-primary rounded-pill px-4 me-3" (click)="onBack()">Back</button>
                    <button *ngIf="this.userService.userRole === 'customer'" type="button" class="btn btn-outline-primary rounded-pill px-4 me-3" (click)="onCancel()">Cancel</button>
                    <button class="btn btn-primary rounded-pill px-4" (click)="onSelectPackage()" [disabled]="!selectedPackage?.PackageId">Next</button>
                </div>
            </div>
            <div *ngIf="(orderStep == 3)">
                <h4>Lets Gather Some Information</h4>
                <div class="my-3"><b>For</b> {{selectedCust.FirstName}} {{selectedCust.LastName}}</div>
                <div *ngFor="let cols of formService.cols">
                    <app-form-field-cols [cols]="cols"></app-form-field-cols>
                </div>
                <div class="d-flex justify-content-end mt-3 mb-5 align-items-center">
                    <button type="button" class="btn btn-outline-primary rounded-pill px-4 me-3" (click)="onBack()">Back</button>
                    <button type="button" class="btn btn-primary rounded-pill px-4" (click)="onCreate()">Finish</button>
                </div>
            </div>
            <div *ngIf="(orderStep == 4)">
                <div class="d-flex flex-column align-items-center mt-5 align-middle">
                    <h4 class="my-3">Congratulations!</h4>
                    <h6 class="mb-5">
                        Order # {{curOrder.OrderId}} has been processed and is now in motion.<br>
                        <br>
                        Your HR department has been notified. Once they have completed their part, you’ll receive an email with the next steps to complete your application.<br>
                        <br>
                        Keep an eye on your inbox for further instructions!<br>
                        <br>
                        Remember, you can track the status of your application by logging into your account on <a href="https://expidoc.com">expidoc.com</a>
                        <br>

                        
                    </h6>
                    <!--div class="d-flex align-items-center">
                        <button type="button" class="btn btn-outline-primary rounded-pill px-4 mx-2" (click)="onView()">View Package</button>
                        <button type="button" class="btn btn-primary rounded-pill px-4" (click)="onEmail()">Email Package</button>
                    </div-->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modalSpinner" *ngIf="loaderLocked">
    <div class="wait-loader"></div>
</div>