import { Component, OnInit } from '@angular/core';
import { PublicService } from 'src/app/services/public.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss']
})
export class PublicHeaderComponent implements OnInit {
  public whyMenu: string = '';
  public prodMenu: string = '';
  public planMenu: string = '';
  public supMenu: string = '';

  constructor(public publicSvc: PublicService, private router: Router) { }

  ngOnInit(): void {
    if(this.publicSvc.selectedMenu == 'why') this.whyMenu = 'y';
    if(this.publicSvc.selectedMenu == 'prod') this.prodMenu = 'y';
    if(this.publicSvc.selectedMenu == 'plan') this.planMenu = 'y';
    if(this.publicSvc.selectedMenu == 'sup') this.supMenu = 'y';
  }

  navTo(dest: string) {
    this.router.navigateByUrl(dest);
  }

}
