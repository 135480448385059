import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { OrderService } from '../services/order.service';

@Component({
  selector: 'app-modal-pdf',
  templateUrl: './modal-pdf.component.html',
  styleUrls: ['./modal-pdf.component.scss']
})
export class ModalPdfComponent implements OnInit {
  pdfsrc: string;
  strReturn: string;
  inputTitle: string;
  inputValue: string;
  inputVisible: boolean = false;
  inputType: string;

  constructor(private el: ElementRef, private http: HttpClient, private orderService: OrderService, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.pdfsrc = `${environment.apiUrl}/PdfGen/` + this.orderService.currentOrderId;

    this.el.nativeElement.addEventListener('click', ()=> {
      this.close()
    })
  }

  close() {
    this.el.nativeElement.classList.remove('sshow')
    this.el.nativeElement.classList.add('hhidden')
  }

  onOpenPdf() {
    const fileURL = `${environment.apiUrl}/PdfGen/` + this.orderService.currentOrderId;
    window.open(fileURL, '_blank');
  }

  onPrepEmail(): void {
    this.inputTitle = "Please Enter the Email Address";
    this.inputValue = "";
    this.inputType = "email";
    this.inputVisible = true;
  }

  onPrepFax(): void {
    this.inputTitle = "Please Enter the Fax Number";
    this.inputValue = "";
    this.inputType = "fax";
    this.inputVisible = true;
  }

  onSendEmail() {
      /*const dialogRef = this.dialog.open(StringCaptureDialog, {
        width: '350px',
        data: {rtn: this.strReturn, title: 'Email Address'}
      });
  
      dialogRef.afterClosed().subscribe(result => {
        this.strReturn = result;
        dialogRef.close();*/

        //console.log('The dialog was closed:' + this.strReturn);
        this.http.get(`${environment.apiUrl}/Mail/send/` + this.orderService.currentOrderId + "/" + this.inputValue)
          .toPromise().then(res => {
        });
      //});
    }

  onSendFax() {
    this.http.get(`${environment.apiUrl}/Mail/fax/` + this.orderService.currentOrderId + "/1" + this.inputValue)
      .toPromise().then(res => {
    });
  }

  onCancel() {
    this.inputVisible = false;
  }

  onOk() {
    if(this.inputType == 'email') {
      if(this.inputValue.length > 0 && this.inputValue.indexOf('@') > 0) {
        this.inputVisible = false;
        this.onSendEmail();
      } else {
        alert("Email address is not valid");
      }
    } else {
      if(this.inputValue.length == 10) {
        this.inputVisible = false;
        this.onSendFax();
      } else {
        alert("Not a valid fax number");
      }
    }
  }
}

