import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogInputData } from 'src/app/models/customer';

@Component({
  selector: 'app-dialog-input',
  templateUrl: './dialog-input.component.html',
  styleUrls: ['./dialog-input.component.scss']
})
export class DialogInputComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DialogInputComponent>, @Inject(MAT_DIALOG_DATA) public data: DialogInputData) { }

  ngOnInit(): void {
  }

  onOk() {
    let val = this.data.textValue;
    if(val == null) val = '';
    if(val.length > 0 || !this.data.textRequired) this.dialogRef.close(this.data);
  }

  onCancel() {
    this.data.textValue = '';
    this.data.cancelled = true;
    this.dialogRef.close(this.data);
  }

  onNoClick() { }
}
