import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Customer } from 'src/app/models/customer';
import { DialogService } from 'src/app/services/dialog.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { ToastService } from 'src/app/services/toast.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmedValidator } from 'src/app/helpers/pw-validator';

const states = [
  {code: 'AL', name: 'Alabama'},
  {code: 'AK', name: 'Alaska'},
  {code: 'AS', name: 'American Samoa'},
  {code: 'AZ', name: 'Arizona'},
  {code: 'AR', name: 'Arkansas'},
  {code: 'CA', name: 'California'},
  {code: 'CO', name: 'Colorado'},
  {code: 'CT', name: 'Connecticut'},
  {code: 'DC', name: 'District of Columbia'},
  {code: 'DE', name: 'Delaware'},
  {code: 'FL', name: 'Florida'},
  {code: 'GA', name: 'Georgia'},
  {code: 'GU', name: 'Guam'},
  {code: 'HI', name: 'Hawaii'},
  {code: 'ID', name: 'Idaho'},
  {code: 'IL', name: 'Illinois'},
  {code: 'IN', name: 'Indiana'},
  {code: 'IA', name: 'Iowa'},
  {code: 'KS', name: 'Kansas'},
  {code: 'KY', name: 'Kentucky'},
  {code: 'LA', name: 'Louisiana'},
  {code: 'ME', name: 'Maine'},
  {code: 'MD', name: 'Maryland'},
  {code: 'MA', name: 'Massachusetts'},
  {code: 'MI', name: 'Michigan'},
  {code: 'MN', name: 'Minnesota'},
  {code: 'MS', name: 'Mississippi'},
  {code: 'MO', name: 'Missouri'},
  {code: 'MP', name: 'Northern Mariana Islands'},
  {code: 'MT', name: 'Montana'},
  {code: 'NE', name: 'Nebraska'},
  {code: 'NV', name: 'Nevada'},
  {code: 'NH', name: 'New Hampshire'},
  {code: 'NJ', name: 'New Jersey'},
  {code: 'NM', name: 'New Mexico'},
  {code: 'NY', name: 'New York'},
  {code: 'NC', name: 'North Carolina'},
  {code: 'ND', name: 'North Dakota'},
  {code: 'OH', name: 'Ohio'},
  {code: 'OK', name: 'Oklahoma'},
  {code: 'OR', name: 'Oregon'},
  {code: 'PA', name: 'Pennsylvania'},
  {code: 'PR', name: 'Puerto Rico'},
  {code: 'RI', name: 'Rhode Island'},
  {code: 'SC', name: 'South Carolina'},
  {code: 'SD', name: 'South Dakota'},
  {code: 'TN', name: 'Tennessee'},
  {code: 'TX', name: 'Texas'},
  {code: 'UM', name: 'United States Minor Outlying Islands'},
  {code: 'UT', name: 'Utah'},
  {code: 'VT', name: 'Vermont'},
  {code: 'VA', name: 'Virginia'},
  {code: 'VI', name: 'U.S. Virgin Islands'},
  {code: 'WA', name: 'Washington'},
  {code: 'WV', name: 'West Virginia'},
  {code: 'WI', name: 'Wisconsin'},
  {code: 'WY', name: 'Wyoming'}
];

@Component({
  selector: 'app-dialog-customer',
  templateUrl: './dialog-customer.component.html',
  styleUrls: ['./dialog-customer.component.scss']
})
export class DialogCustomerComponent implements OnInit {
  customer: Customer;
  winTitle: string = 'Edit User';
  checked: boolean = false;
  userDetails: UntypedFormGroup = new UntypedFormGroup({});
  states = states;
  tab = 0;
  saveText = 'Save';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient, public userService: UserService, public dialogService: DialogService, private formBuilder: UntypedFormBuilder,
              public dialogRef: MatDialogRef<DialogCustomerComponent>, private router: Router, public toastService: ToastService) {
    this.userDetails = formBuilder.group({
      FirstName: ['', [Validators.required]],
      MiddleName: [''],
      LastName: ['', [Validators.required]],
      EmailAddress: ['', [Validators.required, Validators.email]],
      EmailConfirmed: ['', [Validators.required]],
      PhoneNumber: [''],
      GroupDes: [''],
      Password: ['', [Validators.required, Validators.minLength(6)]],
      PasswordConfirmed: ['', [Validators.required]],
      BirthDt: [''],
      HireDt: [''],
      WorkState: [''],
      UnionName: [''],
      SupervisorName: [''],
      Roles: [''],
      Address1: [''],
      Address2: [''],
      CityName: [''],
      StateCd: [''],
      ZipCd: [''],
      ActiveFlg: ['Y'],
    }, {
        validator: [ConfirmedValidator('Password', 'PasswordConfirmed'), ConfirmedValidator('EmailAddress', 'EmailConfirmed'), this.checkEmail()],
    });
  }

  get fUser() { return this.userDetails.controls }

  ngOnInit(): void {
    this.customer = new Customer();

    this.customer.CustomerId = this.userService.currentDialogCustomerId;
    if(this.customer.CustomerId > 0) {
      let url = `${environment.apiUrl}/Customer/` + this.customer.CustomerId;
      this.http.get(url).toPromise().then(res => {
        this.customer = res as Customer;
        this.userDetails = this.formBuilder.group({
          FirstName: [this.customer.FirstName, [Validators.required]],
          MiddleName: [this.customer.MiddleName],
          LastName: [this.customer.LastName, [Validators.required]],
          EmailAddress: [this.customer.EmailAddress, [Validators.required, Validators.email]],
          PhoneNumber: [this.customer.PhoneNbr],
          GroupDes: [this.customer.GroupDes],
          Password: [this.customer.PasswordCd, [Validators.required, Validators.minLength(6)]],
          PasswordConfirmed: [this.customer.PasswordCd, [Validators.required]],
          BirthDt: [this.customer.BirthDt ? new Date(this.customer.BirthDt) : ''],
          HireDt: [this.customer.HireDt ? new Date(this.customer.HireDt) : ''],
          WorkState: [this.customer.WorkState],
          UnionName: [this.customer.UnionName],
          SupervisorName: [this.customer.SupervisorName],
          Roles: [this.customer.Roles],
          Address1: [this.customer.Address1],
          Address2: [this.customer.Address2],
          CityName: [this.customer.CityName],
          StateCd: [this.customer.StateCd],
          ZipCd: [this.customer.ZipCd],
          ActiveFlg: [this.customer.ActiveFlg]
        }, {
            validator: [ConfirmedValidator('Password', 'PasswordConfirmed'), this.checkEmail()],
        });
      });
    } else {
      this.customer.CompanyId = this.userService.currentCompany.CompanyId;
      this.customer.Roles = '';
      this.customer.ActiveFlg = 'Y';
      this.customer.PasswordCd = 'x123!@B';
    }

    // Set the window title and save button text based on the step
    if (this.data?.step == 'verify') {
      this.winTitle = this.userService.currentCompany ? 'Verify ' + this.userService.currentCompany.CustomerTitle : 'Verify User';
      this.saveText = 'Save & Continue';
    } else if (this.data?.step == 'edit') {
      this.winTitle = this.userService.currentCompany ? 'Edit ' + this.userService.currentCompany.CustomerTitle : this.winTitle;
      this.saveText = 'Save';
    } else if (this.data?.step == 'create') {
      this.winTitle = 'Create ' + this.userService.currentCompany.CustomerTitle;
      this.saveText = 'Create';
    }
  }

  checkEmail() {
    return (formGroup: UntypedFormGroup) => {
      const control = formGroup.controls['EmailAddress'];
      if (control.errors && !control.errors.emailExists) return;
      if (this.userService.currentDialogCustomerId > 0 && control.value === this.customer.EmailAddress) {
        control.setErrors(null);
        return;
      }
      this.http.get(`${environment.apiUrl}/Customer/email/` + control.value).toPromise().then(res => {
        let c = res as Customer;
        if(c && c.CustomerId > 0) {
          control.setErrors({ emailExists: true });
        } else {
          control.setErrors(null);
        }
      });
    }
  }

  getFormattedDate(date) {
    var year = date.getFullYear();
  
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return month + '/' + day + '/' + year;
  }

  onSave() {
    this.checked = true;
    if (this.userDetails.status == 'VALID') {
      this.customer = {...this.customer,
        FirstName: this.fUser.FirstName.value,
        MiddleName: this.fUser.MiddleName.value,
        LastName: this.fUser.LastName.value,
        EmailAddress: this.fUser.EmailAddress.value,
        PhoneNbr: this.fUser.PhoneNumber.value,
        GroupDes: this.fUser.GroupDes.value,
        PasswordCd: this.fUser.Password.value,
        BirthDt: this.fUser.BirthDt.value ? this.getFormattedDate(this.fUser.BirthDt.value) : null,
        HireDt: this.fUser.HireDt.value ? this.getFormattedDate(this.fUser.HireDt.value) : null,
        WorkState: this.fUser.WorkState.value,
        UnionName: this.fUser.UnionName.value,
        SupervisorName: this.fUser.SupervisorName.value,
        Roles: this.fUser.Roles.value,
        Address1: this.fUser.Address1.value,
        Address2: this.fUser.Address2.value,
        CityName: this.fUser.CityName.value,
        StateCd: this.fUser.StateCd.value,
        ZipCd: this.fUser.ZipCd.value,
        ActiveFlg: this.fUser.ActiveFlg.value
      };

      if (this.customer.CustomerId == 0) {
        this.http.post(`${environment.apiUrl}/Customer/Save`, this.customer).toPromise().then(res => {
          this.customer = res as Customer;
          this.userService.currentDialogCustomerId = this.customer.CustomerId;
          this.userService.currentDialogCustomer = this.customer;
          this.toastService.addToast({message: "Employee saved"})
          this.dialogRef.close('save');
        },
        error => {
          this.dialogService.openMessageDialog('Save Error', error);
        });
      } else {
        this.http.put(`${environment.apiUrl}/Customer/` + this.customer.CustomerId, this.customer).toPromise().then(res => {
          this.userService.currentDialogCustomer = this.customer;
          this.toastService.addToast({message: "Employee saved"})
          this.dialogRef.close('save');
        },
        error => {
          this.dialogService.openMessageDialog('Save Error', error);
        });
      }
    }
  }

  onCancel() {
    this.dialogRef.close('cancel');
  }

  onDash() {
    let url = 'dashboard/' + this.customer.CustomerId;
    this.router.navigate([url]);
    this.dialogRef.close('save');
  }

  onTabSelect(select: number) {
    this.tab = select;
  }
}