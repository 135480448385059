import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ExternalList, FormListItem } from '../models/form';

export class ExternalLists {

    constructor(public http: HttpClient,public externalId: number) {
    }

    public getList(listCode: string, dispCode: string): Array<FormListItem> {
        let ll = listCode.split(';');
        let ld = dispCode.split(';');
        let ary = new Array<FormListItem>();

        if(ll[1] == 'worquest_state') {
            this.getWQList(ary, 'state', ll[2], ld[2])
        } else if(ll[1] == 'worquest_department') {
            this.getWQList(ary, 'department', ll[2], ld[2])
        } else if(ll[1] == 'worquest_location') {
            this.getWQList(ary, 'location', ll[2], ld[2])
        }


        return ary;
    }

    public getWQList(ary: Array<FormListItem>, dataType: string, listCol: string, dispCol: string) {
        //console.log('ext list for ' + dataType + ' ' + listCol + ' ' + dispCol);
        let url = `${environment.apiUrl}/ThirdParty/List/` + dataType + '/' + this.externalId + '/' + dispCol;
        this.http.get(url).toPromise().then(res => {
            let el = res as ExternalList[];
            
            for(let a = 0; a < el.length; a++) {
                //console.log(a + ' ' + el[a].ListId + ' ' + el[a].ListCode + ' ' + el[a].ListName);
                let l1 = "" + el[a].ListId;
                let l2 = "" + el[a].ListId;

                if(listCol == 'code') l1 = el[a].ListCode;
                if(dispCol == 'code') l2 = el[a].ListCode;

                if(listCol == 'name') l1 = el[a].ListName;
                if(dispCol == 'name') l2 = el[a].ListName;

                let itm = new FormListItem(l1, l2);
                ary.push(itm);
            }
        });
    }
}
