<app-public-header></app-public-header>



<section class="home-banner p-b-30 home-banner-top-thin">
  <div class="home-container">           
         <div class="row">
              <div class="col-cmn col-lg-12 col-md-12 col-sm-12 home-top-other-left">
                <div class="home-bnr-title">Why Expidoc</div>
                <p>Where efficiency meets innovation</p>
              </div>                        	
          </div>           
  </div>
</section>


<section class="home-row-two p-t-60 p-b-30" id="efficient">
    <div class="home-container">
        <div class="row">
            <div class="col-cmn col-lg-12 col-md-12 col-sm-12 text-center">
                <div class="home-page-title">Efficient</div>
            </div>
        </div>
        <div class="row">
            <div class="col-cmn col-lg-4 col-md-4 col-sm-12 service-box text-center">
                <div class="service-box-main">
                     <div class="section-content">
                       <p>Instantly determines leave eligibility for all leave types</p>
                      </div>
                 </div>
            </div>
            <div class="col-cmn col-lg-4 col-md-4 col-sm-12 service-box text-center">
                <div class="service-box-main">
                     <div class="section-content">
                       <p>No more paper forms.  Employee answers questions on-line.  HR and MD questions are sent simultaneously for a quick turnaround</p>
                      </div>
                 </div>
            </div>
            <div class="col-cmn col-lg-4 col-md-4 col-sm-12 service-box text-center">
                <div class="service-box-main">
                     <div class="section-content">
                       <p>No more redundant questions – All parties answer questions one time and response populates across all leave forms</p>
                      </div>
                 </div>
            </div>
        </div>
        <div class="row">
            <div class="col-cmn col-lg-2 col-md-2"></div>
            <div class="col-cmn col-lg-4 col-md-4 col-sm-12 service-box text-center">
                <div class="service-box-main">
                     <div class="section-content">
                       <p>No more incomplete paperwork – Necessary questions must be answered to complete the form</p>
                      </div>
                 </div>
            </div>
            <div class="col-cmn col-lg-4 col-md-4 col-sm-12 service-box text-center">
                <div class="service-box-main">
                     <div class="section-content">
                       <p>Reduces time from leave initiation to receipt of a complete packet by more than half</p>
                      </div>
                 </div>
            </div>
            <div class="col-cmn col-lg-2 col-md-2"></div>
        </div>
    </div>  
</section>


<section class="home-row-two p-t-60 p-b-30 graybg" id="intuitive">
    <div class="home-container">
        <div class="row">
            <div class="col-cmn col-lg-12 col-md-12 col-sm-12 text-center">
                <div class="home-page-title">Easy</div>
            </div>
        </div>
        <div class="home-body-other-left">
            <!--div class="section-content home-mid-p">
                <p>Instantly determine eligibility for numerous leaves.<br>
                    Repetitive questions on multiple forms are answered just ONCE (employees & doctors).                    
                </p>
            </div-->
            <div class="row">
                <div class="col-cmn col-lg-3 col-md-3 col-sm-12 service-box text-center">
                    <div class="service-box-main">
                         <div class="section-content">
                           <p>Employee case initation is simple.  Answer questions on demographics and leave reasons to start process</p>
                          </div>
                     </div>
                </div>
                <div class="col-cmn col-lg-3 col-md-3 col-sm-12 service-box text-center">
                    <div class="service-box-main">
                         <div class="section-content">
                           <p>Treating healthcare provider and HR receives a link with a reduced number of questions</p>
                          </div>
                     </div>
                </div>

                <div class="col-cmn col-lg-3 col-md-3 col-sm-12 service-box text-center">
                    <div class="service-box-main">
                         <div class="section-content">
                           <p>Treating healthcare provider and HR reviews their specific populated section for electronic signature</p>
                          </div>
                     </div>
                </div>

                <div class="col-cmn col-lg-3 col-md-3 col-sm-12 service-box text-center">
                    <div class="service-box-main">
                         <div class="section-content">
                           <p>Expidoc assembles responses to generate complete forms</p>
                          </div>
                     </div>
                </div>


            </div>
        </div>
    </div>  
</section>


<section class="home-row-two p-t-60 p-b-30" id="secure">
    <div class="home-container">
        <div class="row">
            <div class="col-cmn col-lg-12 col-md-12 col-sm-12 text-center">
                <div class="home-page-title">Secure</div>
            </div>
        </div>

        <div class="row">
     
            <div class="col-cmn col-lg-3 col-md-3 col-sm-12 service-box text-center">
                <div class="service-box-main">
                     <div class="section-content">
                       <p>Safe - Conduct Third Party Audits to keep your employee information secure</p>
                      </div>
                 </div>
            </div>
            
            <div class="col-cmn col-lg-3 col-md-3 col-sm-12 service-box text-center">
                <div class="service-box-main">
                     <div class="section-content">
                       <p>Data Encrypted – Both in transit and in storage</p>
                      </div>
                 </div>
            </div>
            
            <div class="col-cmn col-lg-3 col-md-3 col-sm-12 service-box text-center">
                <div class="service-box-main">
                     <div class="section-content">
                       <p>24/7 Monitoring – Offsite servers protected 24/7, 365 days a year</p>
                      </div>
                 </div>
            </div>
            
            <div class="col-cmn col-lg-3 col-md-3 col-sm-12 service-box text-center">
                <div class="service-box-main">
                     <div class="section-content">
                       <p>Protected Network – Protected Network - Meets department of Labor Guidelines for cyber security</p>
                      </div>
                 </div>
            </div>
            
        </div>
   
    </div>  
</section>

<app-footer></app-footer>
