import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DialogInputComponent } from '../helpers/dialog-input/dialog-input.component';
import { DialogMessageComponent } from '../helpers/dialog-message/dialog-message.component';
import { DialogConfirmComponent } from '../helpers/dialog-confirm/dialog-confirm.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialogInput: MatDialog, public dialogMessage: MatDialog) { }

  openInputDialog(title: string, message: string, textRequired: boolean, cancelOk: boolean): Observable<any> {
    const dialogInputRef = this.dialogInput.open(DialogInputComponent, {
      width: '400px', data: {title: title, message: message, textRequired: textRequired, cancelOk: cancelOk}, disableClose: !cancelOk
    });

    return dialogInputRef.afterClosed();
  }

  openMessageDialog(title: string, message: string) {
    let wid = '400px';
    if(message.length > 50) wid = '600px';

    const dialogMessageRef = this.dialogMessage.open(DialogMessageComponent, {width: wid, data: {title: title, message: message}});

    return dialogMessageRef.afterClosed();
  }

  openConfirmDialog(title: string, message: string, confirmText: string): Observable<any> {
    let wid = '200px';
    if(message.length > 50) wid = '400px';

    const dialogConfirmRef = this.dialogMessage.open(DialogConfirmComponent, {width: wid, data: {title: title, message: message, textValue: confirmText}});

    return dialogConfirmRef.afterClosed();
  };
}
