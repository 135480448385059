import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/services/dialog.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { Form } from 'src/app/models/form';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { GenericString } from 'src/app/models/customer';

@Component({
  selector: 'app-dialog-add-form',
  templateUrl: './dialog-add-form.component.html',
  styleUrls: ['./dialog-add-form.component.scss']
})
export class DialogAddFormComponent implements OnInit {
  displayedColumns: string[] = ['FormName', 'FormDesc'];
  tableView = true;
  searchFilter: string;

  dataSource: MatTableDataSource<any | Form>;
  forms = new Array<Form>();
  selectedForm = new Form;
  uploadedFile: File;
  whoCaringForOptions: Array<string> = ['Spouse', 'Adult Child', 'Minor Child', 'Parent', 'Someone Else'];

  constructor(private http: HttpClient, public userService: UserService, public dialogService: DialogService,
    public dialogRef: MatDialogRef<DialogAddFormComponent>) { }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    this.whoCaringForOptions.unshift(this.userService.currentCompany.CustomerTitle);
    this.http.get(`${environment.apiUrl}/Form/`).subscribe(res => {
      this.forms = res as Form[];
      this.dataSource = new MatTableDataSource(this.forms);
      this.dataSource.sort = this.sort;
    });
  }

  onSelectForm(form: Form) {
    this.selectedForm = this.selectedForm.FormId === form.FormId ? new Form : form;
  }

  onCancel() {
    this.dialogRef.close({event: 'Cancel'});
  }

  onAddForm() {
    if (this.tableView) {
      this.dialogRef.close({event: 'Add', data: this.selectedForm});
    } else {
      this.selectedForm.FormId = 0;
      this.selectedForm.SetupDone = 0;
      this.selectedForm.UploadedBy = this.userService.currentEmail;
      this.http.post(`${environment.apiUrl}/Form/CheckNew/`, this.selectedForm).toPromise().then(res => {
        let rtn = res as GenericString;
        let msg = '';
        if(rtn.ValueDes.indexOf('C') >= 0) msg += 'This Code already exists\n';
        if(rtn.ValueDes.indexOf('F') >= 0) msg += 'This File already exists\n';
        if(msg.length > 0) {
          this.dialogService.openMessageDialog('New Uploaded Form', msg);
        } else {
          this.http.post(`${environment.apiUrl}/Form`, this.selectedForm).toPromise().then(res => {
            this.selectedForm = res as Form;
            const uploadData = new FormData();
            uploadData.append('file', this.uploadedFile, this.selectedForm.DocumentName);
            this.http.post(`${environment.apiUrl}/Form/UploadFile`, uploadData).toPromise().then(res => {
              this.dialogRef.close({event: 'Add', data: this.selectedForm});
            });
          });
        }
      });
    }
  }

  onSwitchTableView() {
    this.tableView = !this.tableView;
    this.selectedForm = new Form;
    this.uploadedFile = null;
  }

  onChooseFile() {
    document.getElementById('file-upload').click()
  }

  onInputChange(event: any) {
    this.uploadedFile = event.target.files[0];
    this.selectedForm.DocumentName = event.target.files[0].name;
  }

  onDragOver(event: any) {
    event.preventDefault();
    document.getElementById('drag-area').classList.add("active");
  }

  onDragLeave(event: any) {
    event.preventDefault();
    document.getElementById('drag-area').classList.remove("active");
  }

  onDrop(event: any) {
    event.preventDefault(); 
    this.uploadedFile = event.dataTransfer.files[0];
    this.selectedForm.DocumentName = event.dataTransfer.files[0].name;
  }

  handleSearchFilter(event: Event) {
    this.searchFilter = (event.target as HTMLInputElement).value;
    this.dataSource.filter = this.searchFilter.trim().toLowerCase();
  }

  onRemoveUploadedFile() {
    this.uploadedFile = null;
  }

}
