<app-appheader></app-appheader>
<div class="container">
    <!--div class="row">
        <app-formtitle titleDes="{{orderService.currentOrderTitle}}" orderId="{{taker.OrderId}}" canDownload="true"></app-formtitle> 
    </div-->
    <div class="row">
        <div class="col-sm-10">
            <h3>Your work is done. Now lets send this to your {{taker.TakerHint}}</h3>
            <p>How would you like to send this?</p><br>
            <!--p>Please enter or verify thier contact information and choose how you would like to contact them</p-->

            <form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
                <!--div class="form-group">
                    <label for="TakerName">Name</label>
                    <input type="text" #TakerName="ngModel" name="TakerName" required minlength="10" [(ngModel)]="taker.TakerName" class="form-control"/>
                    <div *ngIf="submitted && TakerName.invalid && (TakerName.dirty || TakerName.touched)" class="alert alert-danger">
                        <div *ngIf="TakerName.errors.required">Name is required.</div>
                        <div *ngIf="TakerName.errors.minlength"> Name must be at least 10 characters long.</div>
                    </div>
                </div>
                <div class="form-group">
                    <label for="EmailAddress">Email</label>
                    <input type="text" #EmailAddress="ngModel" name="EmailAddress" [(ngModel)]="taker.EmailAddress" class="form-control"/>
                </div>
                <div class="form-group">
                    <label for="FaxNumber">Fax Number</label>
                    <input type="text" #FaxNumber="ngModel" name="FaxNumber" minlength="10" [(ngModel)]="taker.FaxNumber" class="form-control"/>
                </div-->
                <div class="form-group">
                    <!--label for="NotifyBy">Notify By</label><br-->
                    <input type="radio" id="email" value="email" #NotifyBy="ngModel" name="NotifyBy" [(ngModel)]="nextNotify.NotifyBy" class="nxt-radio"/>
                    <span class="nxt-radio-lbl">Email</span>
                    <input type="radio" id="fax" value="fax" #NotifyBy="ngModel" name="NotifyBy" [(ngModel)]="nextNotify.NotifyBy" class="nxt-radio"/>
                    <span class="nxt-radio-lbl">Fax</span>
                    <input type="radio" id="both" value="both" #NotifyBy="ngModel" name="NotifyBy" [(ngModel)]="nextNotify.NotifyBy" class="nxt-radio"/>
                    <span class="nxt-radio-lbl">Both</span><br>
                    <input type="radio" id="print" value="print" #NotifyBy="ngModel" name="NotifyBy" [(ngModel)]="nextNotify.NotifyBy" class="nxt-radio"/>
                    <span class="nxt-radio-lbl">Print - I'll take a hard copy to my Doctor's office</span>
                </div>
                <div class="row" *ngIf="sending">
                    <div class="col-sm-5"></div>
                    <div class="col-sm-2 nxt-spin-col"><mat-spinner [diameter]="90"></mat-spinner></div>
                    <div class="col-sm-5"></div>
                </div>
                <div class="form-group">
                    <button [disabled]="sending" type="button" class="btn btn-secondary" (click)="onBack()">
                        <span *ngIf="sending" class="spinner-border spinner-border-sm mr-1"></span>
                        Back
                    </button>
                    &nbsp;
                    <button [disabled]="sending" type="submit" class="btn btn-primary">
                        <span *ngIf="sending" class="spinner-border spinner-border-sm mr-1"></span>
                        Submit
                    </button>
                    &nbsp;
                    <!--button [disabled]="loading" type="button" class="btn btn-cancel" (click)="onReset()">
                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                        Reset
                    </button-->
                </div>
            </form>
        </div>
        <div class="col-sm-2">
            <i class="far fa-file-pdf ff-title-fa"></i> View<br>
            <i class="fas fa-download ff-title-fa"></i> Download<br>
            <i class="fas fa-print ff-title-fa"></i> Print<br>
        </div>
    </div>
</div>
