import { Component, Input, OnInit } from '@angular/core';
import { CompanyFormData } from 'src/app/models/customer';
import { FormListItem } from 'src/app/models/form';
import { CompanyService } from 'src/app/services/company.service';

@Component({
  selector: 'app-company-form-data',
  templateUrl: './company-form-data.component.html',
  styleUrls: ['./company-form-data.component.scss']
})
export class CompanyFormDataComponent implements OnInit {
  fdata: CompanyFormData;
  fieldType: string = "input";
  fieldTitle: string;
  lists: FormListItem[];

  @Input() public set setData(dat: CompanyFormData) {
    this.fdata = dat;
    this.initForm();
  }

  constructor(public compSvc: CompanyService) { }

  ngOnInit(): void {
    if(this.fdata == null) this.fdata = new CompanyFormData();
  }

  initForm() {
    let x = this.compSvc.fieldXrefs.find(f => f.PdfField == this.fdata.PdfField);
    this.lists = new Array<FormListItem>();

    if(x) {
      //console.log(JSON.stringify(this.fdata));
      this.fieldTitle = x.FieldName;

      let vals = x.ListValues;
      if(vals == null) vals = '';
      if(vals.length > 0) {
        this.fieldType = 'select';

        let vs = vals.split('|');
        let ds = x.ListDisplays.split('|');

        for(let a = 0; a < vs.length; a++){
          let fl = new FormListItem(vs[a], ds[a]);
          this.lists.push(fl);
        }

        //console.log(JSON.stringify(this.lists));
      }
    }
  }
}
