import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CalendarEvent, CalendarEventType } from 'src/app/models/customer';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dialog-calendar',
  templateUrl: './dialog-calendar.component.html',
  styleUrls: ['./dialog-calendar.component.scss']
})
export class DialogCalendarComponent implements OnInit {
  eventTypes: CalendarEventType[] = [];
  event = new CalendarEvent();
  title = 'New Event';
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient, public userService: UserService, public dialogFrm: MatDialog,
    public dialogRef: MatDialogRef<DialogCalendarComponent>) { }

  ngOnInit(): void {
    this.http.get(`${environment.apiUrl}/CalendarEventType/Company/` + this.userService.currentCompany.CompanyId).subscribe(res => {
      this.eventTypes = res as CalendarEventType[];
    });
    if (this.data.eventId) { 
      this.http.get(`${environment.apiUrl}/CalendarEvent/` + this.data.eventId).subscribe(res => {
        this.event = res as CalendarEvent;
        this.title = 'Edit Event';
      });
    } else {
      this.event.CustomerId = this.data.currentCustomerId;
      this.event.StartDate = this.data.start ? new Date(this.data.start) : null;
      this.event.EndDate = this.data.end ? new Date(this.data.end.setDate(this.data.end.getDate() - 1)) : null;
    }
  }

  onCreate() {
    this.http.post(`${environment.apiUrl}/CalendarEvent/`, this.event).toPromise().then(res => {
      this.dialogRef.close();
    });
  }

  onUpdate() {
    this.http.put(`${environment.apiUrl}/CalendarEvent/` + this.event.CalendarEventId, this.event).toPromise().then(res => {
      this.dialogRef.close();
    });
  }

  onDelete() {
    this.http.delete(`${environment.apiUrl}/CalendarEvent/` + this.event.CalendarEventId).toPromise().then(res => {
      this.dialogRef.close();
    });
  }

  onCancel() {
    this.dialogRef.close();
  }
}