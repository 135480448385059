<div class="container">
    <div class="row">
        <div class="col-sm-6">
            <h3>Sign In</h3>
            <p>Returning users please sign in here.</p>
            <form>
                <div class="form-group">
                    <label for="email">Email</label>
                    <input name="email" class="input-field form-control" required maxlength="100">
                </div>
                <div class="form-group">
                    <label for="passwordCd">Password</label>
                    <input name="passwordCd" class="input-field form-control" required maxlength="20">
                </div>
                <div class="form-group">
                    <button class="btn btn-success" type="submit" [routerLink]="['/dashboard/' + orderService.dashReturnId]">Submit</button>&nbsp;
                    <button class="btn btn-primary" type="button" [routerLink]="['/home']">Cancel</button>
                </div>
            </form>
        </div>
        <div class="col-sm-6 left-line">
            <h3>Create an Account</h3>
            <p>Let's create a new account and get you started.</p>
            <form>
                <div class="form-group">
                    <label for="email">Email</label>
                    <input name="email" class="input-field form-control" required maxlength="100">
                </div>
                <div class="form-group">
                    <label for="passwordCd">Password</label>
                    <input name="passwordCd" class="input-field form-control" required maxlength="20">
                </div>
                <div class="form-group">
                    <label for="passwordCd2">Confirm Password</label>
                    <input name="passwordCd2" class="input-field form-control" required maxlength="20">
                </div>
                <div class="form-group">
                    <button class="btn btn-success" type="submit" [routerLink]="['/formfmla1']">Create</button>&nbsp;
                    <button class="btn btn-primary nav-item nav-link-edit" type="button" [routerLink]="['/home']">Cancel</button>
                </div>
            </form>
        </div>
    </div>
</div>