import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Customer, EmailMessage } from 'src/app/models/customer';
import { FieldData, FieldGroup, Form, Package } from 'src/app/models/form';
import { GenericResponse, OrderForm, OrderFormFieldData, PdfGen, Taker } from 'src/app/models/order';
import { AlertService } from 'src/app/services/alert.service';
import { DialogService } from 'src/app/services/dialog.service';
import { FormService } from 'src/app/services/form.service';
import { OrderService } from 'src/app/services/order.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { DialogCustomerComponent } from '../company/dialog-customer/dialog-customer.component';
import { saveAs } from 'file-saver';
import { Router } from '@angular/router';
import { UserCriteria } from '../company/company-users/company-users.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { LoaderService } from '../services/loader.service';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-formbuilder',
  templateUrl: './formbuilder.component.html',
  styleUrls: ['./formbuilder.component.scss']
})
export class FormbuilderComponent implements OnInit {
  selectedHdr: string = 'orders';
  customerColumns: string[] = ['FirstName', 'LastName', 'EmailAddress', 'GroupDes', 'Edit'];
  packageColumns: string[] = ['PackageName', 'CompanyId', 'Edit'];

  dataSourceCustomers: MatTableDataSource<any | Customer>;
  dataSourcePackages: MatTableDataSource<any | Package>;
  searchFilter: string;
  customers: Customer[];
  allCustomers: Customer[];
  curOrder: OrderForm = new OrderForm();
  orderStep: number = 1;
  selectedIndex: number = -1;
  curFG: FieldGroup;
  fieldGroupId: number = -1;
  selectedCust: Customer = new Customer();
  selectedPackage: Package = new Package();
  loaderLocked: boolean = false;
  needPdf: boolean = false;
  pdfFile: string = '';
  crit: UserCriteria;
  companyId: number = 0;
  userRole: string = 'U';
  congratsMsg: string = '';
  returnId: number = -1;
  component: { CustomerId: number; FirstName: string; LastName: string; EmailAddress: string; GroupDes: string; CompanyId: number; PasswordCd: string; MiddleName: string; PhoneNbr: string; Address1: string; Address2: string; CityName: string; StateCd: string; ZipCd: string; Roles: string; ResetUqId: string; ResetRequestDate: any; BirthDt: string; HireDt: string; WorkState: string; UnionName: string; SupervisorName: string; Token: string; };

  constructor(public http: HttpClient, public userService: UserService, public dialogUser: MatDialog,
            public dialogService: DialogService, public orderService:OrderService, public router: Router,
            public dialogPkg: MatDialog, private alertService: AlertService, public formService: FormService,
            public loaderService: LoaderService, private AuthSvc: AuthenticationService) { }

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    if(!this.userService.currentCompany && !this.userService.currentCustomer) this.router.navigateByUrl('/login');
    this.companyId = this.userService.userRole == 'admin' ? this.userService.currentCompany?.CompanyId : this.userService.currentCustomer?.CompanyId;
    this.userRole = this.userService.userRole == 'admin' ? 'D' : 'U';

    this.resetMe();
    if (this.userService.userRole == 'customer') {
      this.packageColumns.pop();
      this.orderStep = 2;
      this.onCustomerSelection(this.userService.currentCustomer);
      this.customerSelected();
    }

    if(this.userService.userRole == 'admin') {
      this.returnId = 0;
    } else {
      this.returnId = this.userService.currentCustomer.CustomerId;
    }
  }

  resetMe() {
    this.selectedIndex = -1;

    this.getCustomers();
    this.getPackages();

    this.resetOrder();
    this.curOrder = new OrderForm();
    this.curOrder.OrderDate = new Date();
    this.curOrder.OrderId = 0;
    this.curOrder.PackageCost = 0.0;
    this.curOrder.PaidFlg = 'Y';
    this.curOrder.LastFieldId = 0;
    this.curOrder.PackageId = 0;

    this.crit = new UserCriteria();
  }

  editCust(cust: Customer) {
    let cid = cust.CustomerId;
    this.userService.dialogMode = 1;
    this.userService.currentDialogCustomerId = cid;
    const dialogRef = this.dialogPkg.open(DialogCustomerComponent, { minHeight: '300px', minWidth: '50vw', maxHeight: '90vh', data: {step: 'edit'}});

    dialogRef.afterClosed().subscribe(() => {
      this.getCustomers();
    });
  }

  editPackage(pkg: Package) {
    let pid = pkg.PackageId;
    this.router.navigateByUrl('/package/' + pid);
  }

  onEmail() {
    this.loaderLocked = true;
    
    let pdfFile = 'Expidoc_' + this.curOrder.OrderId + '.pdf';

    let offds = new Array<OrderFormFieldData>();
    let offd = new OrderFormFieldData();
    offd.DataId = 0;
    offd.DataMask = '';
    offd.FieldId = 0;
    offd.FieldType = 'field';
    offd.FieldValue = this.selectedCust.FirstName + ' ' + this.selectedCust.LastName;
    offd.PdfField = 'custname';
    offds.push(offd);

    let em = new EmailMessage();
    em.Tos = this.selectedCust.EmailAddress;
    em.CCs = '';
    em.Subject = 'The documents you requested are attached'
    em.Body = '';
    em.Template = 'expidocattached.html';
    em.Data = JSON.stringify(offds);
    em.Attachment = pdfFile;
    
    let url = `${environment.apiUrl}/Mail/email`;
    this.http.post(url, em).subscribe(res => {
      let resp = res as GenericResponse;
      if(resp.ReturnCode) {
        this.loaderLocked = false;
        this.dialogService.openMessageDialog('Send Email', 'Email has been sent');
      } else {
        this.loaderLocked = false;
        this.dialogService.openMessageDialog('Send Email', 'Error resp sending email :' + resp.DetailMessage);
      }
    }, error => {
      this.loaderLocked = false;
      this.dialogService.openMessageDialog('Send Email', 'Error sending email :' + error);
      //let co = this._alldata[idx] as CompanyOrders;
      //co.DisplayCode = 'n';
    });
  }


  resetOrder() {
    this.curOrder = new OrderForm();
    this.curOrder.OrderDate = new Date();
    this.curOrder.OrderId = 0;
    this.curOrder.PackageCost = 0.0;
    this.curOrder.PaidFlg = 'Y';
    this.curOrder.LastFieldId = 0;
    this.curOrder.PackageId = 4;

    this.selectedIndex = -1;
    this.selectedCust = null;
    this.orderStep = 1;

  }

  getPackages() {
    if (!this.companyId) return;
    this.http.get(`${environment.apiUrl}/Package/Company/` + this.companyId + '/' + this.userRole).subscribe(res => {
      this.dataSourcePackages = new MatTableDataSource(res as Package[]);
      this.dataSourcePackages.sort = this.sort;
      this.selectedPackage = this.dataSourcePackages.filteredData[0];
    });
  }

  getCustomers() {
    if (!this.companyId) return;
    this.customers = new Array<Customer>();

    this.http.get(`${environment.apiUrl}/Customer/Company/` + this.companyId).subscribe(res => {
      this.customers = res as Customer[];
      this.allCustomers = this.customers;
      this.dataSourceCustomers = new MatTableDataSource(this.customers);
      this.dataSourceCustomers.sort = this.sort;
    });
  }

  
  onChange() {
    if(this.crit.CName.length == 0) {
      this.customers = this.allCustomers;
    }
    else {
      if(this.crit.CName.length > 0) {
        let data = new Array<Customer>();
        let srch = this.crit.CName.toLowerCase();
        data = this.allCustomers.filter((nam) => this.custMatches(nam.FirstName, nam.LastName, nam.EmailAddress, nam.GroupDes, srch));
        
        this.customers = data;
      }
    }
  }

  custMatches(fn: string, ln: string, em: string, gd: string, srch: string): boolean {
    let rtn = false;
    let fn2 = fn;
    let ln2 = ln;
    let em2 = em;
    let gd2 = gd;

    if(fn2 == null) fn2 = '';
    if(ln2 == null) ln2 = '';
    if(em2 == null) em2 = '';
    if(gd2 == null) gd2 = '';

    rtn = (fn2.toLowerCase().indexOf(srch) >= 0 || ln2.toLowerCase().indexOf(srch) >= 0 ||
            em2.toLowerCase().indexOf(srch) >= 0 || gd2.toLowerCase().indexOf(srch) >= 0);

    return rtn;
  }

  onCustomerSelection(cust: Customer) {
    this.selectedCust = cust;
  }
  onPackageSelection(pack: Package) {
    this.selectedPackage = pack;
  }

  onSelectPackage() {
    this.curOrder.PackageId = this.selectedPackage.PackageId;

    this.http.get(`${environment.apiUrl}/FieldGroup/QuizPackage/` + this.selectedPackage.PackageId).toPromise().then(res => {
      this.curFG = res as FieldGroup;
      this.fieldGroupId = this.curFG.GroupId;
      this.congratsMsg = this.curFG.TakerNextAction;

      this.http.get(`${environment.apiUrl}/Field/GroupData/0/` + this.fieldGroupId + '/' + this.curOrder.CustomerId).toPromise().then(res => {
        this.formService.items = null;
        this.formService.initFields(0);

        let f = res as FieldData[];
        //console.log(JSON.stringify(f));
        this.formService.setItems(f);

        let len = this.formService.items.length;
        this.formService.initFields(len);

        this.orderStep = 3;
      });
    });
  }

  onSelectCustomer() {
    if (this.selectedCust != null) this.customerSelected();
  }

  onNewCustomer() {
    this.userService.dialogMode = 2;
    this.userService.currentDialogCustomerId = 0;
    const dialogRef = this.dialogUser.open(DialogCustomerComponent, { minHeight: '300px', minWidth: '50vw', maxHeight: '90vh', data: {step: 'create'}});

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'save') {
        this.selectedCust = this.userService.currentDialogCustomer;
        if(this.selectedCust && this.selectedCust.CustomerId > 0) {
          this.curOrder.CustomerId = this.selectedCust.CustomerId;
          this.orderStep = 2;
          this.getCustomers();
        }
      }
    });
  }

  customerSelected() {
    this.curOrder.CustomerId = this.selectedCust.CustomerId;
    this.userService.dialogMode = 1;
    this.userService.currentDialogCustomerId = this.selectedCust.CustomerId;
    const dialogRef = this.dialogUser.open(DialogCustomerComponent, { minHeight: '300px', minWidth: '50vw', maxHeight: '90vh', data: {step: 'verify'}});

    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'save') this.orderStep = 2;
    });
  }

  onCreate() {
    let valid = this.formService.validateForm();
    //console.log("valid = " + valid);

    if(valid) {
      let datap = this.formService.getValues(this.orderService.currentOrderId);
      //console.log('data: ' + JSON.stringify(data));
      
      let i = this.formService.items.find(f => f.PdfField == 'casequiz');
      let of = datap.answers.find(o => o.FieldId == i.FieldId);
      if(of.FieldValue == '1SSW.....') {
        this.dialogService.openMessageDialog('Workers Compensation', 'Workers Compensation cases are not supported');
        return;
      }

      this.loaderLocked = true;
      const userId = this.userService.currentCompany ? this.userService.currentCompany.CompanyLoginId : this.userService.currentCustomer.CustomerId;
      this.http.post(`${environment.apiUrl}/OrderForm/base/` + userId, this.curOrder).subscribe(res => {
        this.curOrder = res as OrderForm;
        //console.log(this.curOrder)
        this.orderService.currentOrderId = this.curOrder.OrderId;
        this.orderService.setCurrentOrderForm(this.curOrder);

        let data = this.formService.getValues(this.orderService.currentOrderId);
        //console.log('data: ' + JSON.stringify(data));
        //console.log('cur ord: ' + JSON.stringify(this.curOrder));

        this.http.post(`${environment.apiUrl}/FormData/SaveData`, data).subscribe(res => {
          //this.http.get(`${environment.apiUrl}/FormData/UpdateData/` + this.orderService.currentOrderId).subscribe(res => {
            // see if theres a first taker
            let nurl = `${environment.apiUrl}/Taker/Next/` + this.orderService.currentOrderId + '/1';
            this.http.get(nurl).toPromise().then(res => {
              let n = res as Taker[];
              //console.log(' takers are ' + JSON.stringify(n));
              this.needPdf = false;

              if(n.length > 0) {
                //console.log('checking taker vs current login: ' + n[0].EmailAddress + ' = ' + this.userService.currentEmail);
                if(n[0].EmailAddress.toLowerCase() == this.userService.currentEmail.toLowerCase()) {
                  let oid = '' + this.orderService.currentOrderId;
                  let tid = '' + n[0].TakerId;
                  this.loaderService.loadPage(oid, tid, '/pages/0');
                } else {
                  // bPdf remains false - there is another user next
                  let tmplt = 'wq' + n[0].TakerHint + 'invite.html';
                  let params = new HttpParams().append('subject', 'Expidoc Data Request').append('template', tmplt);

                  let eurl = `${environment.apiUrl}/Mail/sendTaker/` + n[0].TakerId;
                  this.http.get(eurl, {params: params}).toPromise().then(res => {
                    this.orderStep = 4;
                    this.loaderLocked = false;
                  });
                }
              } else {
                this.needPdf = true;
              }

              if(this.needPdf) {
                this.orderStep = 4;

                let url = `${environment.apiUrl}/PdfGen/buildpkg/` + this.orderService.currentOrderId + '/1';
                //console.log(url);
                this.http.get(url).toPromise().then(res => {
                  let rtn = res as PdfGen;
                  //console.log('build pdf returned ' + JSON.stringify(rtn));
                  if(rtn.ResultCode == 1) {
                    this.pdfFile = rtn.FileName;
                    //this.orderStep = 4;
                    this.loaderLocked = false;
                  } else {
                    //console.log('error building pdf: ' + rtn.ErrorMessage);
                    this.dialogService.openMessageDialog('Build Pdf', "Error building pdf: " + rtn.ErrorMessage);
                    this.loaderLocked = false;
                  }
                });
              }
            //});
          },
          error => {
            //this.loaderLocked = false;
            console.log('error updting form: ' + error);
            this.dialogService.openMessageDialog('Validate Form', "Error updating data !!: " + error);
            //error => this.setError("Unable to save your survey")
            this.loaderLocked = false;
          });
        },
        error => {
          //this.loaderLocked = false;
          console.log('error saving form: ' + error);
          this.dialogService.openMessageDialog('Validate Form', "Error saving form !!: " + error);
          //error => this.setError("Unable to save your survey")
          this.loaderLocked = false;
        });
      },
        error => {
          //this.loaderLocked = false;
          console.log('error saving order: ' + error);
          this.dialogService.openMessageDialog('Save Order', "Error: " + error);
          //error => this.setError("Unable to save your survey")
          this.loaderLocked = false;
      });
    } else {
      //console.log('form not valid');
      this.dialogService.openMessageDialog('Validate Form', 'You have an error in one or more questions above highlighted in red.  Please correct before you continue.');
    }
  }

  onView() {
    let pdfFile = 'Expidoc_' + this.orderService.currentOrderId + '.pdf';
    let url = `${environment.apiUrl}/PdfGen/` + pdfFile;
    this.orderService.getPdf(url).subscribe(data => {
          saveAs(data, pdfFile);
    }, error => {
      this.dialogService.openMessageDialog("Open File", error);
    });
  }

  onCancel() {
    this.resetOrder();
    this.router.navigateByUrl('/dashboard/' + this.orderService.dashReturnId);
  }

  onBack() {
    this.orderStep -= 1;
  }

  handleCustSearchFilter(event: Event) {
    this.searchFilter = (event.target as HTMLInputElement).value;
    this.dataSourceCustomers.filter = this.searchFilter.trim().toLowerCase();
  }

  handlePackageSearchFilter(event: Event) {
    this.searchFilter = (event.target as HTMLInputElement).value;
    this.dataSourcePackages.filter = this.searchFilter.trim().toLowerCase();
  }
}
