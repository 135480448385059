import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ContactTakers } from '../helpers/contact-takers';
import { Worquest } from '../helpers/worquest';
import { AlertService } from '../services/alert.service';
import { CompanyService } from '../services/company.service';
import { FormService } from '../services/form.service';
import { OrderService } from '../services/order.service';

@Component({
  selector: 'app-third-party',
  templateUrl: './third-party.component.html',
  styleUrls: ['./third-party.component.scss']
})
export class ThirdPartyComponent implements OnInit {
  private sub: any;
  processing: boolean = true;
  settingBody: string = 'Now that was easy!';


  constructor(public orderService: OrderService, private router: Router,
    private http: HttpClient, public formService: FormService,
    private route: ActivatedRoute, public companyService: CompanyService,
    private alertService: AlertService) { }

  ngOnInit(): void {
  }

  ngAfterContentInit() {
    //this.companyService.logoLink = 'thanks';
    this.sub = this.route.params.subscribe(params => {
      let act = params['action'];
      if(act) {
        let url = `${environment.apiUrl}`;
        let wq = new Worquest(this.http, url);

        // wqcase
        if(act == 'wqcase') {
          let subscription = wq.wqCompleted.subscribe((rtn: number) => {
            let cid = rtn;

            if(cid > 0) {
              let th = 'ThanksBody' + this.orderService.currentGuest.TakerHint;
              let idx = this.orderService.packageSettings.findIndex(f => f.KeyName == th);
              this.settingBody = 'Case ' + cid + ' has been created for you.<br><br>' + this.orderService.packageSettings[idx].ValueDesc;
            } else {
              this.settingBody = 'Something went wrong creating your case';
              this.alertService.error(wq.lastError);
            }

            this.processing = false;
          });

          wq.NewCase(this.orderService.currentOrderId);
        } else if(act == 'wqdoc') {
          let subscription = wq.wqCompleted.subscribe((rtn: number) => {
            if(rtn > 0) {
              let th = 'ThanksBody' + this.orderService.currentGuest.TakerHint;
              let idx = this.orderService.packageSettings.findIndex(f => f.KeyName == th);
              this.settingBody = this.orderService.packageSettings[idx].ValueDesc;
            } else {
              this.settingBody = 'Something went wrong sending your patient thier documents';
              this.alertService.error(wq.lastError);
            }

            this.processing = false;
          });

          wq.DocDone(this.orderService.currentOrderId, this.formService.taker.TakerId, this.orderService.currentOrder.EmailAddress);
        } else {
          this.alertService.error('System Error: Method not found - ' + act);
        }
      } else {
        this.alertService.error('System Error: No Action specified');
      }
    });
  }

}
