import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker'; 
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { SignupComponent } from './signup/signup.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CaseComponent } from './case/case.component';
import { FormtitleComponent } from './formtitle/formtitle.component';
import { ShowpdfComponent } from './showpdf/showpdf.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AlertComponent } from './components/alert/alert.component';
import { ErrorInterceptor } from './helpers/error-interceptor';
import { JwtInterceptor } from './helpers/jwt-interceptor';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomerComponent } from './customer/customer.component';
import { AuthenticationService } from './services/authentication.service';
import { UserService } from './services/user.service';
import { OrderFormSmryComponent } from './orderform-smry/orderform-smry.component';
import { OrderFormComponent } from './orderform/orderform.component';
import { OrderEditComponent } from './order-edit/order-edit.component';
import { FormService } from './services/form.service';
import { FormGroupComponent } from './form-group/form-group.component';
import { FormFieldComponent } from './form-field/form-field.component';
import { OrderPayComponent } from './order-pay/order-pay.component';
import { ModalPdfComponent } from './modal-pdf/modal-pdf.component';
import { TakerGroupComponent } from './taker-group/taker-group.component';
import { TakerComponent } from './taker/taker.component';
import { TakerListComponent } from './taker-list/taker-list.component';
import { ModalTakerGroupComponent } from './modal-taker-group/modal-taker-group.component';
import { GuestComponent } from './guest/guest.component';
import { ThanksComponent } from './thanks/thanks.component';
import { PagesComponent } from './pages/pages.component';
import { NextTakerComponent } from './next-taker/next-taker.component';
import { IntroComponent } from './intro/intro.component';
import { PhoneNumberDirective } from './directives/phone-number.directive';
import { FormFieldColsComponent } from './form-field-cols/form-field-cols.component';
import { CommonModule, DatePipe } from '@angular/common';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CompanyInviteComponent } from './company/company-invite/company-invite.component';
import { ThirdPartyComponent } from './third-party/third-party.component';
import { GroupPreviewComponent } from './group-preview/group-preview.component';
import { WnwdemoComponent } from './wnwdemo/wnwdemo.component';
import { CheckEmployeeComponent } from './check-employee/check-employee.component';
import { CheckAddressComponent } from './check-address/check-address.component';
import { HomeHeaderComponent } from './home-header/home-header.component';
import { FooterComponent } from './footer/footer.component';
import { CompanyService } from './services/company.service';
import { OrderService } from './services/order.service';
import { CompanySettingsComponent } from './company/company-settings/company-settings.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CompanyLibraryComponent } from './company/company-library/company-library.component';
import { FormbuilderComponent } from './formbuilder/formbuilder.component';
import { PricingComponent } from './home/pricing/pricing.component';
import { PublicHeaderComponent } from './home/public-header/public-header.component';
import { CompanyUsersComponent } from './company/company-users/company-users.component';
import { DialogPackageComponent } from './company/dialog-package/dialog-package.component';
import { DialogPackageFormComponent } from './company/dialog-package-form/dialog-package-form.component';
import { DialogCustomerComponent } from './company/dialog-customer/dialog-customer.component';
import { DialogInputComponent } from './helpers/dialog-input/dialog-input.component';
import { DialogMessageComponent } from './helpers/dialog-message/dialog-message.component';
import { DialogEventTypeComponent } from './dialog-event-type/dialog-event-type.component';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { PublicWhyComponent } from './home/public-why/public-why.component';
import { PublicHowComponent } from './home/public-how/public-how.component';
import { PublicScheduleComponent } from './home/public-schedule/public-schedule.component';
import { DialogCompanyLoginComponent } from './company/dialog-company-login/dialog-company-login.component';
import { DialogSubscriptionComponent } from './company/dialog-subscription/dialog-subscription.component';
import { CompanyFormDataComponent } from './company/company-form-data/company-form-data.component';
import { CompanyDistGroupComponent } from './company/company-dist-group/company-dist-group.component';
import { DialogAddFormComponent } from './dialog-add-form/dialog-add-form.component';
import { PackageDetailsComponent } from './package-details/package-details.component';
import { MatStepperModule } from '@angular/material/stepper';
import { PublicAboutComponent } from './home/public-about/public-about.component';
import { FullCalendarModule } from '@fullcalendar/angular';
import { DialogCalendarComponent } from './dialog-calendar/dialog-calendar.component';
import { AppheaderComponent } from './appheader/appheader.component';
import { ToastService } from './services/toast.service';
import { ToastComponent } from './toast/toast.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LoaderService } from './services/loader.service';
import { FilerStatusComponent } from './filer-status/filer-status.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { EntryComponent } from './entry/entry.component';
import { OrderDetailsComponent } from './order-details/order-details.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    SignupComponent,
    DashboardComponent,
    CaseComponent,
    FormtitleComponent,
    ShowpdfComponent,
    AlertComponent,
    LoginComponent,
    RegisterComponent,
    CustomerComponent,
    OrderFormSmryComponent,
    OrderFormComponent,
    OrderEditComponent,
    OrderDetailsComponent,
    FormGroupComponent,
    FormFieldComponent,
    OrderPayComponent,
    ModalPdfComponent,
    FilerStatusComponent,
    TakerGroupComponent,
    TakerComponent,
    TakerListComponent,
    ModalTakerGroupComponent,
    GuestComponent,
    ThanksComponent,
    PagesComponent,
    NextTakerComponent,
    IntroComponent,
    PhoneNumberDirective,
    FormFieldColsComponent,
    CompanyInviteComponent,
    ThirdPartyComponent,
    GroupPreviewComponent,
    WnwdemoComponent,
    CheckEmployeeComponent,
    CheckAddressComponent,
    HomeHeaderComponent,
    FooterComponent,
    AppheaderComponent,
    CompanySettingsComponent,
    CalendarComponent,
    CompanyLibraryComponent,
    FormbuilderComponent,
    PricingComponent,
    PublicHeaderComponent,
    CompanyUsersComponent,
    DialogCalendarComponent,
    DialogPackageComponent,
    DialogPackageFormComponent,
    DialogCustomerComponent,
    DialogInputComponent,
    DialogMessageComponent,
    DialogEventTypeComponent,
    PublicWhyComponent,
    PublicHowComponent,
    PublicScheduleComponent,
    DialogCompanyLoginComponent,
    DialogSubscriptionComponent,
    CompanyFormDataComponent,
    CompanyDistGroupComponent,
    DialogAddFormComponent,
    PackageDetailsComponent,
    PublicAboutComponent,
    ToastComponent,
    ResetPasswordComponent,
    EntryComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    FullCalendarModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MatIconModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatDialogModule,
    MatListModule,
    MatSelectModule,
    MatSortModule,
    MatTableModule,
    MatStepperModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgxExtendedPdfViewerModule,
    NgxMaterialTimepickerModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatFormFieldModule,
    MatChipsModule,
    MatIconModule
  ],
  exports: [PhoneNumberDirective],
  providers: [AuthenticationService, UserService, FormService, DatePipe, CompanyService, OrderService, ToastService, LoaderService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    provideNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
