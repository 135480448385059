<div class="p-4">
  <h3 class="tg-top-h">{{title}}</h3>
  <form #ngForm="ngForm" autocomplete="off">
    <div class="form-group d-flex flex-column align-items-center mt-3 mb-2">
      <mat-form-field>
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" [(ngModel)]="event.StartDate" name="StartDate" required>
          <input matEndDate placeholder="End date" [(ngModel)]="event.EndDate" name="EndDate" required>
        </mat-date-range-input>
        <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
    </div>
    <div *ngIf="data.customers.length > 0" class="form-group d-flex flex-column align-items-center mb-2">
      <div class="col-12 col-md-6 mb-1"><label for="Customer">Customer</label></div>
      <div class="col-12 col-md-6">
        <select #Customer="ngModel" name="Customer" [(ngModel)]="event.CustomerId" class="form-select form-control" required>
          <option *ngFor="let customer of data.customers" name="Customer" [value]="customer.CustomerId">
            {{customer.FirstName}} {{customer.LastName}}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group d-flex flex-column align-items-center mb-2">
      <div class="col-12 col-md-6 mb-1"><label for="CalendarEventTypeId">Event Type</label></div>
      <div class="col-12 col-md-6">
        <select #CalendarEventTypeId="ngModel" name="Event Types" [(ngModel)]="event.CalendarEventTypeId" class="form-select form-control" required>
          <option *ngFor="let type of eventTypes" name="Event Type" [value]="type.CalendarEventTypeId">
            {{type.EventTypeName}}
          </option>
        </select>
      </div>
    </div>
    <div class="form-group d-flex flex-column align-items-center mb-4">
      <div class="col-12 col-md-6 mb-1"><label for="Comments">Comments</label></div>
      <div class="col-12 col-md-6">
        <input type="text" #Comments="ngModel" name="Comments" [(ngModel)]="event.Comments"class="form-control" maxlength="100"/>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="d-grid gap-2 d-md-flex justify-content-md-between">
      <button *ngIf="data.eventId" class="btn btn-secondary rounded-pill px-4" (click)="onDelete()">Delete</button>
      <div class="d-grid gap-2 d-flex flex-column flex-md-row">
        <button class="btn btn-outline-primary rounded-pill px-4 me-0 me-md-2" (click)="onCancel()">Cancel</button>
        <button class="btn btn-primary px-4 rounded-pill" *ngIf="data.eventId" (click)="onUpdate()" [disabled]="!ngForm.valid">Save</button>
        <button class="btn btn-primary px-4 rounded-pill" *ngIf="!data.eventId" (click)="onCreate()" [disabled]="!ngForm.valid">Create</button>
      </div>
    </div>
  </div>
</div>