import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CompanyLogin } from 'src/app/models/customer';
import { DialogService } from 'src/app/services/dialog.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dialog-company-login',
  templateUrl: './dialog-company-login.component.html',
  styleUrls: ['./dialog-company-login.component.scss']
})
export class DialogCompanyLoginComponent implements OnInit {

  constructor(private http: HttpClient, public userService: UserService, public dialogService: DialogService,
    public dialogRef: MatDialogRef<DialogCompanyLoginComponent>) { }

  ngOnInit(): void {
  }

  onSave() {
    if(this.userService.currentDialogCompanyLogin.CompanyLoginId == null) {
      //this.userService.currentDialogCompanyLogin.CompanyLoginId = null;
      //console.log(JSON.stringify(this.userService.currentDialogCompanyLogin));
      this.http.post(`${environment.apiUrl}/CompanyLogin/Save`, this.userService.currentDialogCompanyLogin).toPromise().then(res => {
        this.dialogRef.close();
      },
      error => {
        this.dialogService.openMessageDialog('save', error);
      });

    } else {
      this.http.put(`${environment.apiUrl}/CompanyLogin/` + this.userService.currentDialogCompanyLogin.CompanyLoginId, this.userService.currentDialogCompanyLogin).toPromise().then(res => {
        this.dialogRef.close();
      });
    }
  }

  onDelete() {
    this.http.delete(`${environment.apiUrl}/CompanyLogin/` + this.userService.currentDialogCompanyLogin.CompanyLoginId).toPromise().then(res => {
      this.dialogRef.close();
    },
    error => {
      this.dialogService.openMessageDialog('delete', error);
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

}
