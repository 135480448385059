import { Component, OnInit, ViewChild } from '@angular/core';
import { CompanyService } from 'src/app/services/company.service';
import { UserService } from 'src/app/services/user.service';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CompanyOrders, EmailMessage } from 'src/app/models/customer';
import { GenericResponse, OrderFormFieldData, PdfGen, SearchCriteria, Taker } from 'src/app/models/order';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { OrderService } from 'src/app/services/order.service';
import { saveAs } from 'file-saver';
import { DialogService } from 'src/app/services/dialog.service';

export class Group {
  level = 0;
  parent: Group;
  expanded = false;
  totalCounts = 0;
  get visible(): boolean {
    return !this.parent || (this.parent.visible && this.parent.expanded);
  }
}

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  crit: SearchCriteria = new SearchCriteria();
  dataSource: MatTableDataSource<any | Group>;
  statusFilter: string;
  searchFilter: string = '';
  displayedColumns: string[];
  selectedHdr: string = 'orders';
  loaderLocked: boolean = false;
  statusOptions = [
    {value: 'All', name: 'All'},
    {value: 'Incomplete', name: 'Incomplete'},
    {value: 'Complete', name: 'Complete'}
  ]
  companyId: number;
  userRole: string;

  constructor(private http: HttpClient, public companyService: CompanyService, public orderService: OrderService,
            public userService: UserService, public route: ActivatedRoute, public dialogSvc: DialogService, private router: Router) {}

  @ViewChild(MatSort) sort: MatSort;

  ngOnInit(): void {
    if(!this.userService.currentCompany && !this.userService.currentCustomer) this.router.navigateByUrl('/login');
    this.userRole = this.userService.userRole;  
    this.companyId = this.userRole === 'admin' ? this.userService.currentCompany?.CompanyId : this.userService.currentCustomer?.CompanyId;
    this.displayedColumns = this.userRole === 'admin' ? ['PackageName', 'GroupDes', 'CustomerName', 'EmailAddress', 'TakerStatus', 'OrderId', 'FinalSent', 'Pdf', 'Email'] : ['PackageName', 'OrderId', 'TakerStatus', 'FinalSent', 'Pdf', 'Email'];

    this.route.params.subscribe((params: Params) => {
      this.crit.FromDate = null;
      this.crit.ToDate = null;
      this.crit.CName = '';
      this.crit.Status = 'All';
      this.crit.Cid = params.cid > 0 ? params.cid : 0;

      if(this.crit.Cid == 0 && this.orderService.dashReturnId > 0) this.crit.Cid = this.orderService.dashReturnId;
      this.getOrders();    
    });
  }

  getOrders() {
    let gs = new Taker();
    gs.TakerId = this.companyId;
    gs.StartDate = this.crit.FromDate ? this.crit.FromDate : new Date('1/1/2000');
    this.crit.ToDate?.setHours(this.crit.ToDate.getHours() + 23);
    gs.FinishDate = this.crit.ToDate ? this.crit.ToDate : new Date('12/31/2100');
    gs.TakerHint = this.crit.Status;
    gs.OrderId = this.crit.Cid;

    this.http.post(`${environment.apiUrl}/Company/OrderSearch/`, gs).subscribe(res => {
      let data = res as CompanyOrders[];
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.filterPredicate = (data: CompanyOrders, filter: string) => {
        return !filter || data.PackageName.toLowerCase().includes(filter)
        || data.Taker1Email.toLowerCase().includes(filter)
        || data.CustomerName.toLowerCase().includes(filter)
        || String(data.OrderId).includes(filter);
      };
      this.dataSource.filter = this.crit.CName.trim().toLowerCase();
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch (property) {
          case 'FinalSent': return new Date(item.FinalSent);
          case 'EmailAddress': return item.Taker1Email.toLowerCase();
          case 'CustomerName': return item.CustomerName.toLowerCase();
          case 'PackageName': return item.PackageName.toLowerCase();
          default: return item[property];
        }
      };
      this.dataSource.sort = this.sort;
    }, 
      error => {console.log(error.error)}
    );
   }
  
  onEmail(order: CompanyOrders) {
    this.loaderLocked = true;
    let pdfFile = 'Expidoc_' + order.OrderId + '.pdf';

    let offds = new Array<OrderFormFieldData>();
    let offd = new OrderFormFieldData();
    offd.DataId = 0;
    offd.DataMask = '';
    offd.FieldId = 0;
    offd.FieldType = 'field';
    offd.FieldValue = order.CustomerName;
    offd.PdfField = 'custname';
    offds.push(offd);

    let em = new EmailMessage();
    em.Tos = order.Taker1Email;
    em.CCs = '';
    em.Subject = 'The documents you requested are attached'
    em.Body = '';
    em.Template = 'expidocattached.html';
    em.Data = JSON.stringify(offds);
    em.Attachment = pdfFile;

    //console.log(JSON.stringify(em));
    
    let url = `${environment.apiUrl}/Mail/email`;
    this.http.post(url, em).subscribe(res => {
      let resp = res as GenericResponse;
      if(resp.ReturnCode) {
        this.loaderLocked = false;
        this.dialogSvc.openMessageDialog('Send Email', 'Email has been sent');
      } else {
        this.loaderLocked = false;
        this.dialogSvc.openMessageDialog('Send Email', 'Error resp sending email :' + resp.DetailMessage);
      }
    }, error => {
      this.loaderLocked = false;
      this.dialogSvc.openMessageDialog('Send Email', 'Error sending email :' + error);
    });
  }

  onView(order: CompanyOrders) {
    //console.log("oid", order.OrderId)
    let ord = '' + order.OrderId;
    let pdfFile = 'Expidoc_' + ord + '.pdf';
    let url = `${environment.apiUrl}/PdfGen/` + pdfFile;
    this.orderService.getPdf(url).subscribe(data => {
      saveAs(data, pdfFile);
    }, error => {
      this.dialogSvc.openMessageDialog("Open File", error);
      let co = order;
      co.DisplayCode = 'n';
    });
  }

  onRebuild(order: CompanyOrders) {
    this.loaderLocked = true;
    let ord = '' + order.OrderId;
    let url = `${environment.apiUrl}/PdfGen/buildpkg/` + ord + '/1';
    this.http.get(url).toPromise().then(res => {
      let rtn = res as PdfGen;
      if(rtn.ResultCode == 1) {
        let co = order;
        co.DisplayCode = '';
        this.loaderLocked = false;
        this.onView(order);
      } else {
        this.dialogSvc.openMessageDialog("Rebuild File", rtn.ErrorMessage);
        this.loaderLocked = false;
      }
    });
  }

  navTo(dest: string) {
    this.router.navigateByUrl(dest);
  }

  onClickRow(row: CompanyOrders) {
    this.router.navigateByUrl('/orderdetails/' + row.OrderId);
  }

}
