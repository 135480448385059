import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Component, Input, OnInit, inject} from '@angular/core';
import {MatChipEditedEvent, MatChipInputEvent} from '@angular/material/chips';
import {LiveAnnouncer} from '@angular/cdk/a11y';
import { CompanyDistribution } from 'src/app/models/customer';

/**
 * @title Chips with input
 */
@Component({
  selector: 'company-dist-group',
  templateUrl: 'company-dist-group.component.html',
  styleUrls: ['company-dist-group.component.scss'],
})
export class CompanyDistGroupComponent implements OnInit {
  addOnBlur = true;
  companyDist = new CompanyDistribution() as CompanyDistribution;
  distName: string;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  emails: string[] = [];

  announcer = inject(LiveAnnouncer);

  @Input() public set setCompanyDist(companyDist: CompanyDistribution) {
    this.companyDist = companyDist;
  }

  @Input() public set setDistName(distName: string) {
    this.distName = distName;
  }

  ngOnInit() {
    this.parseEmails(this.companyDist['EmailAddress']);
  }

  parseEmails(emails: string) {
    this.emails = emails ? emails.split(',') : [];
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add email
    if (value) {
      this.emails.push(value);
      this.companyDist['EmailAddress'] = this.emails.join(',');
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(email: string): void {
    const index = this.emails.indexOf(email);

    if (index >= 0) {
      this.emails.splice(index, 1);
      this.companyDist['EmailAddress'] = this.emails.join(',');

      this.announcer.announce(`Removed ${email}`);
    }
  }

  edit(email: string, event: MatChipEditedEvent) {
    const value = event.value.trim();

    // Remove email if it no longer has a name
    if (!value) {
      this.remove(email);
      return;
    }

    // Edit existing email
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails[index] = value;
      this.companyDist['EmailAddress'] = this.emails.join(',');
    }
  }
}
