<div class="hdr wnwbod">
    <div class="row">
        <div class="col-sm-3 ee-logo"><img src="../assets/wnwlogo.jpg"></div>
        <div class="col-sm-9 ee-right">
            <span class="nav-temp">Home</span>
            <span class="nav-temp">about us</span>
            <span class="nav-temp">services</span>
            <span class="nav-temp">education</span>
            <span class="nav-temp">new platform</span>
            <span class="nav-temp">contact us</span>

            <button class="btn btn-danger ee-hdr-btn" onclick="location.href='#'">RETURN</button>
        </div>
    </div>
</div>
<div class="wnwbod">
    <div class="login-bgnd">
        <!--img class="login-img" src="../../assets/allbanner.jpg"-->
    </div>
    <div class="login-title title-text">EMPLOYEE PORTAL</div>
</div>

<div class="row login-second-sect wnwbod">
    <div class="col-sm-1"></div>
    <div class="col-sm-10 login-intro-txt">
        <br>Log-In and get real time information regarding your case. <br>
        <span class="login-info-txt">Case Status | Time Taken | Time Remaining | Due Dates | Received Paperwork Confirmation</span>
    </div>
    <div class="col-sm-1"></div>
</div>
<div class="login-second-sect wnwbod btm-pads">
    <div class="row">
        <div class="col-sm-6 login-right-sect">
            <div class="row">
                <div class="col-sm-1"></div>
                <div class="col-sm-10">
                    <div class="jumbotronXX">
                        <form>
                            <div class="row">
                                <div class="col-sm-12 login-sect-title">
                                    Already have a Case? Login here:
                                </div>
                            </div>
                            <div class="row login-alert" *ngIf="isError">
                                <div class="col-sm-12">
                                    Sorry we could not find your information
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-sm-5 form-label login-color">Case No.:</div>
                                <div class="col-sm-7 form-field">
                                    <input type="text"  style='width:auto' size="10" maxlength="6" name="CaseId" class="form-control"/>
                                </div>
                            </div>
                            <div class="row form-group">
                                <div class="col-sm-5 form-label login-color">Birth Date:</div>
                                <div class="col-sm-7 form-field">
                                    <input name="BirthDt" class="form-control form-control-suffix"
                                            size="10" maxlength="10" style="width:auto" [matDatepicker]="BirthDtpicker">
                                    <mat-datepicker-toggle matSuffix [for]="BirthDtpicker"></mat-datepicker-toggle>
                                    <mat-datepicker #BirthDtpicker></mat-datepicker>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 form-center">
                                    <button class="btn btn-danger" (click)="onSubmit()">Submit</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-sm-1"></div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="row">
                <div class="col-sm-12 login-sect-title">
                    No previous cases, no problem<br>
                    Lets get you started:
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 form-center">
                    <form #newForm="ngForm">
                        <div class="row form-group">
                            <div class="col-sm-5 form-label login-color">Company Code:</div>
                            <div class="col-sm-7 form-field">
                                <input type="text" required style='width:auto' size="10" maxlength="6" name="compCode" [(ngModel)]="compCode" class="form-control"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 form-center">
                                <button [disabled]="!newForm.valid" class="btn btn-danger" (click)="onNewCase()">Begin</button>
                            </div>
                        </div>
                        <div class="row login-alert" *ngIf="isNewError">
                            <div class="col-sm-12">
                                Sorry that code is invalid
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>