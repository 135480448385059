<div>
    <app-appheader [selectedPic]="selectedHdr"></app-appheader>
    <div class="page-container">
        <div class="page-content container">
            <div class="d-flex justify-content-between align-items-center">
                <h4>Orders</h4>
                <button class="btn btn-primary rounded-pill px-4" (click)="navTo('formbuilder')">+ New Order</button>
            </div>
            <div class="d-flex align-items-start align-items-sm-center mt-3 flex-column flex-sm-row justify-content-start">
                <input type="text" class="form-control field rounded-pill" [(ngModel)]="crit.CName" (keyup)="getOrders()" placeholder="Search..."/>
                <select class="form-select form-control field rounded-pill ms-0 ms-sm-1 mt-2 mt-sm-0 d-none d-md-block" [(ngModel)]="crit.Status" (change)="getOrders()" placeholder="Status" >
                    <option *ngFor="let status of statusOptions" [value]="status.value">
                        {{status.name}}
                    </option>
                </select>
                <div class="ms-0 ms-sm-1 input-group" appearance="outline">
                    <mat-date-range-input class="form-control date-field rounded-pill mt-2" name="range" [rangePicker]="picker">
                        <input matStartDate name="start" [(ngModel)]="crit.FromDate" placeholder="Start range" (dateChange)="getOrders()">
                        <input matEndDate name="end" [(ngModel)]="crit.ToDate" placeholder="End range" (dateChange)="getOrders()">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-date-range-picker #picker></mat-date-range-picker>
                </div>
            </div>
            <div class="table-container">
                <table mat-table class="tbl" [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="PackageName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Package</th>
                        <td mat-cell *matCellDef="let order">{{order.PackageName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="GroupDes" *ngIf="this.userRole === 'admin'">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{userService.currentCompany.CustomerGroupTitle}}</th>
                        <td mat-cell *matCellDef="let order">{{order.GroupDes}}</td>
                    </ng-container>
                    <ng-container matColumnDef="CustomerName" *ngIf="this.userRole === 'admin'">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{userService.currentCompany.CustomerTitle}}</th>
                        <td mat-cell *matCellDef="let order">{{order.CustomerName}}</td>
                    </ng-container>
                    <ng-container matColumnDef="EmailAddress" *ngIf="this.userRole === 'admin'">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email Address</th>
                        <td mat-cell *matCellDef="let order">{{order.Taker1Email}}</td>
                    </ng-container>
                    <ng-container matColumnDef="TakerStatus">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                        <td mat-cell *matCellDef="let order"><filer-status [order]="order"/></td>
                    </ng-container>
                    <ng-container matColumnDef="OrderId">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Document ID</th>
                        <td mat-cell *matCellDef="let order">{{order.OrderId}}</td>
                    </ng-container>
                    <ng-container matColumnDef="FinalSent">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Created On</th>
                        <td mat-cell *matCellDef="let order">{{order.FinalSent}}</td>
                    </ng-container>
                    <ng-container matColumnDef="Pdf">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>PDF</th>
                        <td mat-cell *matCellDef="let order">
                            <i class="fa-regular fa-file-pdf fa-pointer" *ngIf="order.DisplayCode === '' || order.DisplayCode == null" (click)="onView(order)"></i>
                            <i class="fa fa-repeat" *ngIf="order.DisplayCode === 'n'" (click)="onRebuild(order)"></i>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="Email">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
                        <td mat-cell *matCellDef="let order"><i class="fa-regular fa-envelope fa-pointer" *ngIf="order.DisplayCode === '' || order.DisplayCode == null" (click)="onEmail(order)"></i></td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="onClickRow(row)"></tr>
                </table>
            </div>
        </div>
    </div>
</div>
<div class="modalSpinner" *ngIf="loaderLocked">
    <div class="wait-loader"></div>
</div>
