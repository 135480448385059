<footer class="cusftr">
  <div class="ftrmain">
    <div class="row">
      <div class="col-sm-4 one">
        <div class="cipi">
          <a href="index.html"><img src="../../assets/logo.png" class="footer-logo" /></a>
            <p>Expidoc re-engineers the medical leave process</p>       
        </div>
      </div>
      
      <div class="col-sm-2 two">
        <div class="cipi">
          <h2>Quick Links</h2>                
          <ul class="listo">
            <li><a routerLink="/homehow">How It Works</a></li>
            <li><a routerLink="/homewhy">Why Expidoc</a></li>
            <li><a href="https://calendly.com/lindas6064" target="_blank">Find Out More</a></li>
            <li><a routerLink="/about">About Us</a></li>
            <li><a routerLink="/pricing">Pricing</a></li>
          </ul>             
        </div>
      </div>
      
      <div class="col-sm-3 three">
        <div class="cipi">
          <h2>Contact Us</h2> 
          <table class="footer-contact-tbl" width="100%" border="0">
              <tr>
                <td valign="top" align="left"><i class="fa fa-phone"></i></td>
                <td valign="top" align="left"><p><a href="tel:7165551212">(716) 262-8080</a></p></td>
              </tr>
              <tr>
                <td valign="top" align="left"><i class="fa fa-envelope"></i></td>
                <td valign="top" align="left"><p><a href="mailto:info@expidoc.com">info@expidoc.com</a></p></td>
              </tr>
          </table>                                                         
        </div>
      </div>
      
      <div class="col-sm-3 four">
        <div class="cipi">
          <h2>Social Media</h2>
          <div class="footer_four">
            <div class="socilo">
              <a href="#" target="_blank"><i class="fa fa-facebook-f"></i></a>
              <a href="#" target="_blank"><i class="fa fa-linkedin"></i></a>
              <a href="#" target="_blank"><i class="fa fa-twitter"></i></a>
              <a href="#" target="_blank"><i class="fa fa-instagram"></i></a>
            </div>
          </div>                     
        </div>
      </div>
    </div> 
    <div class="row copyright">
      <div class="col-cmn col-lg-12 col-md-12 col-sm-12 text-center">
        <div class="footer-copyright"><p>Copyright © 2023 Expidoc. All rights reserved.</p></div>
      </div>                        
    </div>
  </div>
</footer>
  