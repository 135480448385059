<app-public-header></app-public-header>
<section class="home-banner p-b-30 home-banner-top-full">
    <div class="container login-container py-5">
        <div class="col-lg-5 col-md-6 col-sm-8 mx-3">
            <div class="p-lg-5 p-md-5 p-sm-5 p-4 rounded bg-white shadow">
                <div>
                    <h2 class="reg-color">Enter order details</h2><br>
                    <form [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="form-group mb-3">
                            <label for="orderId" class="form-label is-invalid">Order Number</label>
                            <input type="number" size="10" formControlName="orderId" class="form-control input-auto"/>
                        </div>
                        <div class="form-group mb-4">
                            <label for="takerId" class="form-label">User Id</label>
                            <input type="number" size="10" formControlName="takerId" class="form-control input-auto"/>
                        </div>
                        <div class="form-group d-grid gap-2">
                            <button class="btn btn-primary a-next rounded-pill" type="submit">
                                <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                Find order
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer></app-footer>
