import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'src/app/services/dialog.service';
import { PublicService } from 'src/app/services/public.service';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.scss']
})
export class PricingComponent implements OnInit {

  constructor(public publicSvc: PublicService, public dialogService: DialogService, private router: Router) { }

  ngOnInit(): void {
    this.publicSvc.selectedMenu = "plan";
  }

  buynow(pkg: string) {
    this.router.navigate(['register/']);
  }

}
