import { Component, OnInit } from '@angular/core';
import { OrderService } from '../services/order.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AlertService } from '../services/alert.service';
import { FormService } from '../services/form.service';
import { FieldGroup, FieldData } from '../models/form';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { FormFieldComponent } from '../form-field/form-field.component';

@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: ['./form-group.component.scss']
})
export class FormGroupComponent implements OnInit {
  userSubscription: Subscription;
  loading: boolean;
  pageSeq: number;
  formGroup: FieldGroup;
  fields: FormFieldComponent[];
  
  constructor(private http: HttpClient,
    private orderService: OrderService,
    private alertService: AlertService,
    public formService: FormService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.userSubscription = this.route.params.subscribe(
      (params: Params) => { this.loadGroup(); });
  }

  ngOnDestroy(): void {
    this.userSubscription.unsubscribe()
  }

  loadGroup() {
    this.loading = true;
    this.formGroup = JSON.parse('{"GroupId":0,"PackageId":0,"PageSeq":0,"GroupTitle":"loading...","GroupDesc":""}');
    this.pageSeq = this.orderService.currentGroup;
    //console.log("form is " + this.orderService.currentOrder.FormId);
    //console.log("group is " + this.pageSeq);

    /*let params: HttpParams = new HttpParams();
    params.append('formid', '' + this.orderService.currentOrder.FormId);
    params.append('pageseq', '' + this.pageSeq);*/

    this.http.get(`${environment.apiUrl}/FieldGroup/Page/` + this.orderService.currentOrder.PackageId + "/" + this.pageSeq)
      .toPromise().then(res => {
        this.formGroup = res as FieldGroup;
        //console.log("got data " + this.formGroup.GroupId);
        this.loadFields();
      },
      error => {
        this.alertService.error(error);
      });
  }

  loadFields() {
    this.http.get(`${environment.apiUrl}/Field/GroupData/` + this.orderService.currentOrder.OrderId + "/" + this.formGroup.GroupId + '/' +  + this.orderService.currentOrder.CustomerId).toPromise()
    .then(res => {
      this.loading  = false;

      let i = res as FieldData[];
      this.formService.setItems(i);

      let len = this.formService.items.length;
      this.formService.initFields(len);
    });
  }

  onBack() {
    this.orderService.setGroup(this.pageSeq - 1);
    this.router.navigate(['orderform/' + this.orderService.currentGroup]);
  }

  onNext() {
    let data = this.formService.getValues(this.orderService.currentOrderId);
    //console.log("next data saving for x items: " + data.answers.length);
    //console.log("   " + data.answers[0].FieldValue);

    // later add piece to check if just saving or completed

    this.http.post(`${environment.apiUrl}/FormData/SaveData`, data)
      .subscribe(res => {
        this.orderService.setGroup(this.pageSeq + 1);
        this.router.navigate(['orderform/' + this.orderService.currentGroup]);
      },
      error => console.log("Error saving !!")
                //error => this.setError("Unable to save your survey")
    );
  }

  onSave() {
    let data = this.formService.getValues(this.orderService.currentOrderId);
    //console.log("save data saving for x items: " + data.answers.length);
    //console.log("   " + data.answers[0].FieldValue);

    // later add piece to check if just saving or completed

    this.http.post(`${environment.apiUrl}/FormData/SaveData`, data)
      .subscribe(
      error => console.log("Error saving !!")
                //error => this.setError("Unable to save your survey")
    );
  }

  onCancel() {
    this.router.navigateByUrl('/dashboard/' + this.orderService.dashReturnId);
  }
}
