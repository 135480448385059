<app-public-header></app-public-header>

<section class="home-banner p-b-30 home-banner-top-thin">
  <div class="home-container">           
         <div class="row">
              <div class="col-cmn col-lg-12 col-md-12 col-sm-12 home-top-other-left">
                <div class="home-bnr-title">How it Works</div>
                <p>The Ultimate Solution for Medical Leave Document Management</p>
              </div>                        	
          </div>           
  </div>
</section>
<section class="home-row-two graybg">
    <div class="home-container">
        <div class="row">
            <div class="col-sm-2"></div>
            <div class="col-sm-8">
                <br/>
                <br/>
                <p>Expidoc is a web-based service that helps populate medical leave forms, the easy way.  Sending simple, directed questions to get the information needed to populate all leave forms.  Doctors, employees, and HR complete electronically and the actual forms are complete, in minutes!</p>
                <br/>
                <br/>
            </div>
            <div class="col-sm-2"></div>
        </div>
    </div>
</section>
<br>
<section class="home-row-two">
    <div class="home-container">
        <div class="row">
            <div class="col-sm-12 text-center">
                <h4>Watch one of our short videos and you’ll understand it’s power!</h4>
                <br/>
            </div>
        </div>
        <div class="row mid-how-bod">
            <div class="col-sm-4 text-center">
                <p class="video-banner">CUTE ANIMATED COMMERCIAL</p>
                <iframe src="https://www.youtube.com/embed/OOIk-xm3Lbc" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" width="auto" height="auto" frameborder="0"></iframe>
            </div>
            <div class="col-sm-4 text-center">
                <p class="video-banner">EXPIDOC IN ACTION</p>
                <iframe src="https://www.youtube.com/embed/2HcrGLeTcyM" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" width="auto" height="auto" frameborder="0"></iframe>                
            </div>
            <div class="col-sm-4 text-center">
                <p class="video-banner">BUILD-A-PACKET</p>
                <iframe src="https://www.youtube.com/embed/q-KOWBPhdkY" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" width="auto" height="auto" frameborder="0"></iframe>
            </div>
        </div>
    </div>
</section>
<br/>
<app-footer></app-footer>
