<div class="hdr wnwbod">
    <div class="row">
        <div class="col-sm-3 ee-logo"><img src="../assets/wnwlogo.jpg"></div>
        <div class="col-sm-9 ee-right">
            <span class="nav-temp">Home</span>
            <span class="nav-temp">about us</span>
            <span class="nav-temp">services</span>
            <span class="nav-temp">education</span>
            <span class="nav-temp">new platform</span>
            <span class="nav-temp">contact us</span>

            <button class="btn btn-danger ee-hdr-btn" onclick="location.href='#'">RETURN</button>
        </div>
    </div>
</div>
<div class="container" *ngIf="isSearch">
    <h3>Let's see if we already have your info</h3>
    <br>
    <div class="jumbotron">
        <form #checkForm="ngForm">
            <div class="row form-group">
                <div class="col-sm-3 form-title">Employee ID</div>
                <div class="col-sm-4">
                    <input type="text" #Social_Security_Num="ngModel" name="Social_Security_Num" [(ngModel)]="emp.Social_Security_Num" class="form-control" required maxlength="20"/>
                </div>
                <div class="col-sm-5"></div>
            </div>    
            <div class="row form-group">
                <div class="col-sm-3 form-title">Birth Date</div>
                <div class="col-sm-6 form-field">
                    <input #Birth_Date="ngModel" name="Birth_Date" [(ngModel)]="emp.Birth_Date" class="form-control form-control-suffix"
                            size="10" maxlength="10" style="width:auto" [matDatepicker]="BirthDtpicker" required>
                    <mat-datepicker-toggle matSuffix [for]="BirthDtpicker"></mat-datepicker-toggle>
                    <mat-datepicker #BirthDtpicker></mat-datepicker>
                </div>
                <div class="col-sm-3"></div>
            </div>    
            <div class="row form-group">
                <div class="col-sm-3 form-title"></div>
                <div class="col-sm-3 date-picker-row">
                    <button [disabled]="!checkForm.valid" type="button" class="btn btn-primary" (click)="onCheck()">Next</button>
                </div>
                <div class="col-sm-6"></div>
            </div>    
        </form>
    </div>
</div>
<div class="container" *ngIf="!isSearch">
    <h3>{{formTitle}}</h3>
    <br>
    <form #addrForm="ngForm">
        <div class="row form-group">
            <div class="col-sm-2 form-title">Name</div>
            <div class="col-sm-4">
                <input #First_Name="ngModel" name="First_Name" [(ngModel)]="addr.First_Name" placeholder="First" type="text" class="form-control" required maxlength="50"/>
            </div>
            <div class="col-sm-2">
                <input #Middle_Initial="ngModel" name="Middle_Initial" [(ngModel)]="addr.Middle_Initial" placeholder="MI" type="text" class="form-control" maxlength="1"/>
            </div>
            <div class="col-sm-4">
                <input #Last_Name="ngModel" name="Last_Name" [(ngModel)]="addr.Last_Name" placeholder="Last" type="text" class="form-control" required maxlength="50"/>
            </div>
        </div>    
        <div class="row form-group">
            <div class="col-sm-2 form-title">Email</div>
            <div class="col-sm-10">
                <input #Email="ngModel" name="Email" [(ngModel)]="addr.Email" placeholder="Email" type="email" class="form-control" required maxlength="100"/>
            </div>
        </div>    
        <div class="row form-group">
            <div class="col-sm-2 form-title">Title</div>
            <div class="col-sm-10">
                <input #Title="ngModel" name="Title" [(ngModel)]="addr.Title" placeholder="Title" type="text" class="form-control" maxlength="100"/>
            </div>
        </div>    
        <div class="row form-group">
            <div class="col-sm-2 form-title">Address</div>
            <div class="col-sm-10">
                <input #Address_Line1="ngModel" name="Address_Line1" [(ngModel)]="addr.Address_Line1" placeholder="Address" type="text" class="form-control" required maxlength="100"/>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-sm-2 form-title"></div>
            <div class="col-sm-10">
                <input #Address_Line2="ngModel" name="Address_Line2" [(ngModel)]="addr.Address_Line2" placeholder="Suite, Appt, etc." type="text" class="form-control" maxlength="100"/>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-sm-2 form-title"></div>
            <div class="col-sm-5">
                <input #City="ngModel" name="City" [(ngModel)]="addr.City" placeholder="City" type="text" class="form-control" required maxlength="100"/>
            </div>
            <div class="col-sm-2">
                <select #State_Id="ngModel" name="State_Id" [(ngModel)]="addr.State_Id" placeholder="St" type="text" class="form-control" required maxlength="3">
                    <option value=""></option>
                    <option *ngFor="let j of states" value="{{j.ItemValue}}">{{j.ItemLabel}}</option>                    
                </select>
            </div>
            <div class="col-sm-3">
                <input #Zip_Code="ngModel" name="Zip_Code" [(ngModel)]="addr.Zip_Code" placeholder="Zip" type="text" class="form-control" required maxlength="10"/>
            </div>
        </div>    
        <div class="row form-group">
            <div class="col-sm-2 form-title">Phone</div>
            <div class="col-sm-5">
                <input #Phone_Number="ngModel" name="Phone_Number" [(ngModel)]="addr.Phone_Number" placeholder="Phone" type="text" class="form-control" required maxlength="13"/>
            </div>
            <div class="col-sm-2 form-title">Gender</div>
            <div class="col-sm-3">
                <select #Gender="ngModel" name="Gender" [(ngModel)]="addr.Gender" type="text" class="form-control" required maxlength="1">
                    <option value="0">Female</option>
                    <option value="1">Male</option>
                </select>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-sm-2 form-title">Department</div>
            <div class="col-sm-5">
                <select #Department_Id="ngModel" name="Department_Id" [(ngModel)]="emp.Department_Id" type="text" class="form-control" maxlength="3">
                    <option value=""></option>
                    <option *ngFor="let d of depts" value="{{d.ItemValue}}">{{d.ItemLabel}}</option>                    
                </select>
            </div>
            <div class="col-sm-2 form-title">Hire Date</div>
            <div class="col-sm-3 form-field">
                <input #Hire_Date="ngModel" name="Hire_Date" [(ngModel)]="emp.Hire_Date" class="form-control form-control-suffix"
                        size="10" maxlength="10" style="width:auto" [matDatepicker]="HireDtpicker" required>
                <mat-datepicker-toggle matSuffix [for]="HireDtpicker"></mat-datepicker-toggle>
                <mat-datepicker #HireDtpicker></mat-datepicker>
            </div>
        </div>    
        <div class="row form-group">
            <div class="col-sm-2 form-title">Location</div>
            <div class="col-sm-5">
                <select #Location_Id="ngModel" name="Location_Id" [(ngModel)]="emp.Location_Id" type="text" class="form-control" required maxlength="3">
                    <option value=""></option>
                    <option *ngFor="let l of locs" value="{{l.ItemValue}}">{{l.ItemLabel}}</option>                    
                </select>
            </div>
            <div class="col-sm-2 form-title">Work State</div>
            <div class="col-sm-3 form-field">
                <select #Work_State_Id="ngModel" name="Work_State_Id" [(ngModel)]="emp.Work_State_Id" type="text" class="form-control" required maxlength="3">
                    <option value=""></option>
                    <option *ngFor="let j of wstates" value="{{j.ItemValue}}">{{j.ItemLabel}}</option>                    
                </select>
            </div>
        </div>    
        <div class="row form-group">
            <div class="col-sm-2 form-title">Full/Part Time</div>
            <div class="col-sm-2">
                <select #Full_Time_Ind="ngModel" name="Full_Time_Ind" [(ngModel)]="emp.Full_Time_Ind" type="text" class="form-control" required maxlength="1">
                    <option value="F">Full</option>
                    <option value="P">Part</option>
                </select>
            </div>
            <div class="col-sm-2 form-title">Days/Week</div>
            <div class="col-sm-2">
                <input #Days_Worked_Week="ngModel" name="Days_Worked_Week" [(ngModel)]="emp.Days_Worked_Week" type="text" class="form-control" required maxlength="100"/>
            </div>
            <div class="col-sm-2 form-title">Hours/Days</div>
            <div class="col-sm-2">
                <input #Hours_Worked_Day="ngModel" name="Hours_Worked_Day" [(ngModel)]="emp.Hours_Worked_Day" type="text" class="form-control" required maxlength="100"/>
            </div>
        </div>


        <div class="row form-group">
            <div class="col-sm-2 form-title"></div>
            <div class="col-sm-4 date-picker-row">
                <button [disabled]="!addrForm.valid" type="button" class="btn btn-primary" (click)="onSave()">Next</button>
            </div>
            <div class="col-sm-6"></div>
        </div>    
    </form>
</div>