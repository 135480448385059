<div class="p-4">
    <h3 class="tg-top-h">Edit Package</h3>
    <br>
    <form #ngForm="ngForm" autocomplete="off">
        <div class="form-group">
            <label for="PackageName">Name</label>
            <input type="text" #PackageName="ngModel" name="PackageName" [(ngModel)]="package.PackageName" class="form-control"
                required maxlength="100" [disabled]="cantEdit"/>
        </div>
        <div class="form-group row">
            <div class="col-sm-2"><label for="ReminderDays">Send reminder after</label></div>
            <div class="col-sm-1">
                <select #ReminderDays="ngModel" name="ReminderDays" [(ngModel)]="package.ReminderDays" class="form-control" required>
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                </select>
            </div>
            <div class="col-sm-9"><label for="ReminderDays">days</label></div>
        </div>
        <div class="form-group row">
            <div class="col-sm-12">
                <label>
                <input type="checkbox" #SeeData="ngModel" name="SeeData" [(ngModel)]="pkgCbx.SeeData"
                    class="cbx"/>Allow internal viewing of data and forms
                </label>
            </div>
        </div>
    </form>
    <br>
    <div class="row">
        <div class="col-sm-12">
            <button class="btn btn-primary paddr" (click)="onSave()">OK</button>
            <button class="btn btn-warning" (click)="onCancel()">Cancel</button>
        </div>
    </div>
</div>
