<div class="hdr wnwbod">
    <div class="row">
        <div class="col-sm-3 ee-logo"><img src="../assets/wnwlogo.jpg"></div>
        <div class="col-sm-9 ee-right">
            <span class="nav-temp">Home</span>
            <span class="nav-temp">about us</span>
            <span class="nav-temp">services</span>
            <span class="nav-temp">education</span>
            <span class="nav-temp">new platform</span>
            <span class="nav-temp">contact us</span>

            <button class="btn btn-danger ee-hdr-btn" onclick="location.href='#'">RETURN</button>
        </div>
    </div>
</div>
<div class="container">
    <h3>{{formTitle}}</h3>
    <br>
    <form #addrForm="ngForm" *ngIf="addr">
        <div class="row form-group">
            <div class="col-sm-2 form-title">Name</div>
            <div class="col-sm-4">
                <input #First_Name="ngModel" name="First_Name" [(ngModel)]="addr.First_Name" placeholder="First" type="text" class="form-control" required maxlength="50"/>
            </div>
            <div class="col-sm-2">
                <input #Middle_Initial="ngModel" name="Middle_Initial" [(ngModel)]="addr.Middle_Initial" placeholder="MI" type="text" class="form-control" maxlength="1"/>
            </div>
            <div class="col-sm-4">
                <input #Last_Name="ngModel" name="Last_Name" [(ngModel)]="addr.Last_Name" placeholder="Last" type="text" class="form-control" required maxlength="50"/>
            </div>
        </div>    
        <div class="row form-group">
            <div class="col-sm-2 form-title">Email</div>
            <div class="col-sm-10">
                <input #Email="ngModel" name="Email" [(ngModel)]="addr.Email" placeholder="Email" type="email" class="form-control" required maxlength="100"/>
            </div>
        </div>    
        <div class="row form-group">
            <div class="col-sm-2 form-title">Title</div>
            <div class="col-sm-10">
                <input #Title="ngModel" name="Title" [(ngModel)]="addr.Title" placeholder="Title" type="text" class="form-control" maxlength="100"/>
            </div>
        </div>    
        <div class="row form-group">
            <div class="col-sm-2 form-title">Address</div>
            <div class="col-sm-10">
                <input #Address_Line1="ngModel" name="Address_Line1" [(ngModel)]="addr.Address_Line1" placeholder="Address" type="text" class="form-control" required maxlength="100"/>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-sm-2 form-title"></div>
            <div class="col-sm-10">
                <input #Address_Line2="ngModel" name="Address_Line2" [(ngModel)]="addr.Address_Line2" placeholder="Suite, Appt, etc." type="text" class="form-control" maxlength="100"/>
            </div>
        </div>
        <div class="row form-group">
            <div class="col-sm-2 form-title"></div>
            <div class="col-sm-5">
                <input #City="ngModel" name="City" [(ngModel)]="addr.City" placeholder="City" type="text" class="form-control" required maxlength="100"/>
            </div>
            <div class="col-sm-2">
                <select #State_Id="ngModel" name="State_Id" [(ngModel)]="addr.State_Id" placeholder="St" type="text" class="form-control" required maxlength="3">
                    <option value=""></option>
                    <option *ngFor="let j of states" value="{{j.ItemValue}}">{{j.ItemLabel}}</option>                    
                </select>
            </div>
            <div class="col-sm-3">
                <input #Zip_Code="ngModel" name="Zip_Code" [(ngModel)]="addr.Zip_Code" placeholder="Zip" type="text" class="form-control" required maxlength="10"/>
            </div>
        </div>    
        <div class="row form-group">
            <div class="col-sm-2 form-title">Phone</div>
            <div class="col-sm-5">
                <input #Phone_Number="ngModel" name="Phone_Number" [(ngModel)]="addr.Phone_Number" placeholder="Phone" type="text" class="form-control" required maxlength="13"/>
            </div>
            <div class="col-sm-2 form-title">Gender</div>
            <div class="col-sm-3">
                <select #Gender="ngModel" name="Gender" [(ngModel)]="addr.Gender" type="text" class="form-control" required maxlength="1">
                    <option value="0">Female</option>
                    <option value="1">Male</option>
                </select>
            </div>
        </div>

        <div class="row form-group">
            <div class="col-sm-2 form-title"></div>
            <div class="col-sm-4 date-picker-row">
                <button [disabled]="!addrForm.valid" type="button" class="btn btn-primary" (click)="onSave()">Next</button>
            </div>
            <div class="col-sm-6"></div>
        </div>    
    </form>
</div>