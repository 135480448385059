<div class="public-header shadow">
  <div class="container">
    <nav class="navbar navbar-expand-lg">
      <div class="container-fluid">
        <a class="navbar-brand px-0" href="index.html">
          <img src="assets/ftrlogo.png" class="toplogo" />
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav my-2 my-lg-0 justify-content-end ms-0">
            <li class="nav-item my-1">
              <a class="nav-link text-nowrap" routerLink="/homehow">How It Works</a>
            </li>
            <li class="nav-item my-1">
              <a class="nav-link text-nowrap" routerLink="/homewhy">Why Expidoc</a>
            </li>
            <li class="nav-item my-1">
              <a class="nav-link text-nowrap" href="https://calendly.com/lindas6064" target="_blank">Find Out More</a>
            </li>
            <li class="nav-item my-1">
              <a class="nav-link text-nowrap" routerLink="/pricing">Pricing</a>
            </li>
            <li class="nav-item my-1">
              <a class="nav-link text-nowrap" routerLink="/about">About Us</a>
            </li>
            <li class="nav-item my-1">
              <button class="btn btn-outline-primary rounded-pill px-4 text-nowrap" (click)="navTo('login')">Log In</button>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</div>