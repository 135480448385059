<div class="p-4 d-flex flex-column">
  <h3 class="tg-top-h">Edit Event Types</h3>
  <div class="my-3 d-grid col-8 mx-auto">
    <ul id="typeList" class="list-group-flush">
      <li class="d-flex justify-content-between align-items-center mt-2" *ngFor="let type of eventTypes">
        <input type="text" class="form-control" id="{{type.EventTypeName}}" [(ngModel)]="type.EventTypeName" name="{{type.EventTypeName}}" value="{{type.EventTypeName}}" />
        <input type="color" class="ms-2 form-control form-control-color" id="{{type.CalendarColor}}" [(ngModel)]="type.CalendarColor" name="{{type.CalendarColor}}" value="{{type.CalendarColor}}"/>
        <div class="space">
          <i *ngIf="!type.Events" class="fas fa-trash fa-pointer ml-5 icon" (click)="onRemoveType(type.CalendarEventTypeId)"></i>
        </div>
      </li>
    </ul>
    <div class="d-grid d-md-block mt-0">
      <button class="btn btn-link ps-0" (click)="onAddEventType()">+ Add Event Type</button>
    </div>
  </div>
  <div class="row">
    <div class="d-grid gap-2 d-md-flex justify-content-md-end">
      <button class="btn btn-outline-primary rounded-pill px-4 me-0 me-md-2" (click)="onCancel()">Cancel</button>
      <button class="btn btn-primary px-4 rounded-pill" (click)="onSave()">Save</button>
    </div>
  </div>
</div>