<div class="container">
    <form>
    <div class="row">
        <h3>Case</h3>
        <p>Lets gather some information</p>
        <div class="col-sm-12">
            <div class="form-group">
                <label for="caseDate">Date</label>
                <input name="caseDate" class="input-field form-control" required value="12/15/2019" size="10">
            </div>
            <div class="form-group">
                <label for="doctorName">Doctor</label>
                <input name="doctorName" class="input-field form-control">
            </div>
            <div class="form-group">
                <button class="btn btn-primary" type="button" [routerLink]="['/dashboard/' + orderService.dashReturnId]">Back</button>
                <button class="btn btn-success" type="button" [routerLink]="['/formfmla1']" style="float: right;">Next</button>
            </div>
        </div>
    </div>
    </form>
</div>