import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CalendarEventType } from 'src/app/models/customer';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dialog-event-type',
  templateUrl: './dialog-event-type.component.html',
  styleUrls: ['./dialog-event-type.component.scss']
})
export class DialogEventTypeComponent implements OnInit {
  eventTypes: CalendarEventType[] = [];
  toDeleetypes: CalendarEventType[] = [];
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private http: HttpClient, public userService: UserService, public dialogFrm: MatDialog,
    public dialogRef: MatDialogRef<DialogEventTypeComponent>) { }

  ngOnInit(): void {
    this.http.get(`${environment.apiUrl}/CalendarEventType/Company/` + this.userService.currentCompany.CompanyId).subscribe(res => {
      this.eventTypes = res as CalendarEventType[];
    });
  }

  onAddEventType() {
    this.eventTypes.push({ CalendarEventTypeId: 0, CompanyId: this.userService.currentCompany.CompanyId, EventTypeName: '', CalendarColor: '#000000' });
  }

  onSave() {
    for (let i = 0; i < this.eventTypes.length; i++) {
      if (this.eventTypes[i].CalendarEventTypeId === 0) {
        this.http.post(`${environment.apiUrl}/CalendarEventType/`, this.eventTypes[i]).subscribe(res => {
          this.eventTypes[i].CalendarEventTypeId = res as number;
        });
      } else {
        this.http.put(`${environment.apiUrl}/CalendarEventType/` + this.eventTypes[i].CalendarEventTypeId, this.eventTypes[i]).subscribe(res => {
        });
      }
    }
    for (let i = 0; i < this.toDeleetypes.length; i++) {
      this.http.delete(`${environment.apiUrl}/CalendarEventType/` + this.toDeleetypes[i].CalendarEventTypeId).subscribe(res => {
      });
    }
    this.dialogRef.close();
  }

  onRemoveType(typeId: number) {
    if (typeId > 0) {
      this.toDeleetypes.push(this.eventTypes.find(x => x.CalendarEventTypeId === typeId));
    }
    this.eventTypes.splice(this.eventTypes.findIndex(x => x.CalendarEventTypeId === typeId), 1);
  }

  onCancel() {
    this.dialogRef.close();
  }
}