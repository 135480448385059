<form #form="ngForm" autocomplete="off" (submit)="onSubmit(form)">
    <input type="hidden" name="CustomerId" [value]="customer.CustomerId">
    <div class="row">
        <div class="form-group col-sm-2">
            <label for="FirstName">First Name</label>
        </div>
        <div class="form-group col-sm-4">
            <input type="text" #FirstName="ngModel" name="FirstName" required minlength="2" [(ngModel)]="customer.FirstName" class="form-control"/>
            <div *ngIf="submitted && FirstName.invalid && (FirstName.dirty || FirstName.touched)" class="alert alert-danger">
                <div *ngIf="FirstName.errors.required">First Name is required.</div>
                <div *ngIf="FirstName.errors.minlength">First Name must be at least 2 characters long.</div>
            </div>
        </div>
        <div class="form-group col-sm-2">            
            <label for="LastName">Last Name</label>
        </div>
        <div class="form-group col-sm-4">
            <input type="text" #LastName="ngModel" name="LastName" required minlength="2" [(ngModel)]="customer.LastName" class="form-control"/>
            <div *ngIf="submitted && LastName.invalid && (LastName.dirty || LastName.touched)" class="alert alert-danger">
                <div *ngIf="LastName.errors.required">Last Name is required.</div>
                <div *ngIf="LastName.errors.minlength">Last Name must be at least 2 characters long.</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="form-group col-sm-2">
            <label for="username">Email</label>            
        </div>
        <div class="form-group col-sm-4">
            <input type="text" #EmailAddress="ngModel" name="EmailAddress" required [(ngModel)]="customer.EmailAddress" class="form-control"/>
            <div *ngIf="submitted && EmailAddress.invalid && (EmailAddress.dirty || EmailAddress.touched)" class="alert alert-danger">
                <div *ngIf="EmailAddress.errors.required">Email is required.</div>
            </div>
        </div>
        <div class="form-group col-sm-2">
            <label for="PasswordCd">Password</label>            
        </div>
        <div class="form-group col-sm-4">
            <input type="password" #PasswordCd="ngModel" name="PasswordCd" required minlength="6" [(ngModel)]="customer.PasswordCd" class="form-control"/>
            <div *ngIf="submitted && PasswordCd.invalid && (PasswordCd.dirty || PasswordCd.touched)" class="alert alert-danger">
                <div *ngIf="PasswordCd.errors.required">Password is required.</div>
                <div *ngIf="PasswordCd.errors.minlength">Password must be at least 6 characters long.</div>
            </div>
        </div>
    </div>
    <div class="form-group">
        <button [disabled]="loading" type="submit" class="btn btn-primary">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Save
        </button>
        &nbsp;
        <button [disabled]="loading" type="button" class="btn btn-cancel" (click)="onReset()">
            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
            Reset
        </button>
    </div>
</form>
