import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Form, Package, PackageForm } from 'src/app/models/form';
import { DialogAddFormComponent } from '../dialog-add-form/dialog-add-form.component';
import { DialogService } from 'src/app/services/dialog.service';
import { saveAs } from 'file-saver';
import { map } from 'rxjs/operators';
import { ToastService } from '../services/toast.service';

@Component({
  selector: 'package-details',
  templateUrl: './package-details.component.html',
  styleUrls: ['./package-details.component.scss']
})
export class PackageDetailsComponent implements OnInit {
  displayedColumns: string[] = ['name', 'description', 'pdf', 'remove'];
  selectedHdr: string = 'lib';
  forms: Form[];
  editedForms: Form[];
  package: Package;
  packageName: string;
  isChanged: boolean = false;
  isEditingPackageName: boolean = false;

  constructor(private http: HttpClient, public userService: UserService, private router: Router,
              public dialogAddForm: MatDialog, public route: ActivatedRoute, public dialogSvc: DialogService, public toastService: ToastService) { }

  ngOnInit(): void {
    if(this.userService.currentCompany == null) this.router.navigateByUrl('/login');
    this.route.params.subscribe(params => {
      this.http.get(`${environment.apiUrl}/Package/` + params['pid']).subscribe(res => {
        this.package = res as Package;
        this.packageName = this.package.PackageName;
        this.getForms();
      });
    });
  }

  getForms() {
    this.http.get(`${environment.apiUrl}/Form/Package/` + this.package.PackageId).subscribe(res => {
      this.editedForms = res as Form[];
      this.forms = res as Form[];
    });
  }

  onDiscardChanges() {
    this.isChanged = false;
    this.isEditingPackageName = false;
    this.packageName = this.package.PackageName;
    this.getForms();
  }

  onSaveAsCustomPackage() {
    let newPackage = new Package();
    newPackage.PackageName = this.packageName;
    newPackage.CompanyId = this.userService.currentCompany.CompanyId;
    newPackage.PackageCost = this.package.PackageCost;
    newPackage.ReminderDays = this.package.ReminderDays;
    newPackage.SeeData = this.package.SeeData;

    this.http.post(`${environment.apiUrl}/Package/`, newPackage).subscribe(res => {
      let resp = res as Package;
      for (let i = 0; i < this.editedForms.length; i++) {
        let newPackageForm = new PackageForm();
        newPackageForm.PackageId = resp.PackageId;
        newPackageForm.FormId = this.editedForms[i].FormId;
        newPackageForm.FormSeq = i + 1;
        newPackageForm.SendTo = 'Taker-Associate';
        newPackageForm.SendType = 'Email';
        
        this.http.post(`${environment.apiUrl}/PackageForm/`, newPackageForm).subscribe(res => {
          this.toastService.addToast({message: "Package saved"})
          this.router.navigateByUrl('/companylib');
        }, error => {
          this.dialogSvc.openMessageDialog('Save as Custom Package', 'Error adding forms:' + error);
        });
      }
    }, error => {
      this.dialogSvc.openMessageDialog('Save as Custom Package', 'Error creating custom package:' + error);
    });
  }

  getDifference(a, b) {
    return a.filter(formA => {
      return !b.some(formB => {
        return formA.FormId == formB.FormId;
      });
    });
  }

  onSaveChanges() {
    // Add Forms
    let addedForms = this.getDifference(this.editedForms, this.forms);
    for (let i = 0; i < addedForms.length; i++) {
      let newPackageForm = new PackageForm();
      newPackageForm.PackageId = this.package.PackageId;
      newPackageForm.FormId = addedForms[i].FormId;
      newPackageForm.FormSeq = this.forms.length + i + 1;
      newPackageForm.SendTo = 'Taker-Associate';
      newPackageForm.SendType = 'Email';
      
      this.http.post(`${environment.apiUrl}/PackageForm/`, newPackageForm).subscribe(res => {}, error => {
        this.dialogSvc.openMessageDialog('Save Changes', 'Error adding packages:' + error);
      });
    }

    // Remove Forms
    let removedForms = this.getDifference(this.forms, this.editedForms);
    this.http.get(`${environment.apiUrl}/PackageForm`).subscribe(res => {
      var packageForms = res as PackageForm[];
      for (let i = 0; i < removedForms.length; i++) {
        let packageForm = packageForms.find(pf => (pf.FormId === removedForms[i].FormId && pf.PackageId === this.package.PackageId));
        this.http.delete(`${environment.apiUrl}/PackageForm/` + packageForm.PackageFormId).subscribe(res => {
        }, error => {
          this.dialogSvc.openMessageDialog('Save Changes', 'Error removing packages:' + error);
        });
      }
    });

    // Change package name
    if (this.packageName !== this.package.PackageName) {
      this.package.PackageName = this.packageName;
      this.http.put(`${environment.apiUrl}/Package/` + this.package.PackageId, this.package).subscribe(res => {
        this.toastService.addToast({message: "Package saved"})
        this.router.navigateByUrl('/companylib');
      }, error => {
        this.dialogSvc.openMessageDialog('Save Changes', 'Error updating custom package:' + error);
      });
    } else {
      this.toastService.addToast({message: "Package saved"})
      this.router.navigateByUrl('/companylib');
    }
  }

  onAddForm() {
    const dialogRef = this.dialogAddForm.open(DialogAddFormComponent, { minHeight: '300px', minWidth: '70vw' });
    
    dialogRef.afterClosed().subscribe((result) => {
      if (result.event === 'Add') {
        this.editedForms = [...this.editedForms, result.data as Form];
        this.isChanged = true;
      }
    });
  }

  onRemoveForm(formId: number) {
    this.isChanged = true;
    this.editedForms = this.editedForms.filter(item => item.FormId !== formId);
  }

  onEditPackageName() {
    this.isEditingPackageName = true;
    this.isChanged = true;
  }

  onPackageNameChanged(text) {
    this.isChanged = true;
  }

  showForm(fid: number, fname: string) {
    let typ = 'application/pdf';
    if(fname.endsWith('docx')) typ = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';

    this.getFile(`${environment.apiUrl}/PdfGen/showfile/` + fid, typ).subscribe(data => {
      saveAs(data, fname);
    });
  }

  getFile(path: string, typ: string): any {
    return this.http.get<any>(path, { responseType: 'blob' as 'json'}).pipe(map(res => {return new Blob([res], { type: typ });  }));
  }

  onBack() {
    this.router.navigateByUrl('/companylib');
  }
}
