<div class="comp-header">
    <nav class="navbar navbar-light bg-white bdr">
        <div class="container-fluid container my-1">
            <div class="d-flex flex-column align-items-end flex-md-row">
                <img class="logo" (click)="navTo('dash/0')" src="../../../assets/ftrlogo.png"/>
                <div *ngIf="logoFile != ''" class="for mx-1 d-none d-md-block">for</div>
                <img *ngIf="logoFile != ''" class="comp-logo d-none d-md-block" src="../../assets/Logos/{{logoFile}}"/>
            </div>
            <!--button (click)="onTest()">Test</button-->
            <div class="dropdown-center cursor d-none d-sm-none d-md-block">
                <div id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <div class="d-flex align-items-center">
                        <div class="text-end">
                            <div>{{userService.currentEmail}}</div>
                            <div *ngIf="userService.userRole === 'admin'" class="text-secondary">{{userService.currentCompany.CompanyName}}</div>
                        </div>
                        <i class="fa-solid fa-circle-user fa-xl m-2 text-secondary"></i>
                    </div>
                </div>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton1">
                    <li *ngIf="userService.userRole === 'admin'">
                        <button class="dropdown-item text-end" (click)="navTo('companysettings')">Settings</button>
                    </li>
                    <hr *ngIf="userService.userRole === 'admin'" class="dropdown-divider">
                    <li>
                        <button class="dropdown-item text-end" (click)="logOut()">Logout</button>
                    </li>
                </ul>
            </div>
            <button class="navbar-toggler d-block d-sm-block d-md-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
        </div>
    </nav>
    <nav class="navbar navbar-expand navbar-light bg-white bdr p-0 collapse d-md-block" id="navbarNavAltMarkup">
        <div class="container">
            <ul class="navbar-nav d-flex flex-column flex-md-row">
                <li class="nav-item p-1 pb-0 {{cssOrders}}">
                    <button type="button" class="btn btn-link nav-link" [ngClass]="{'active': cssOrders === 'selected'}" (click)="navTo('dashboard')">Orders</button>
                </li>
                <li *ngIf="userService.userRole === 'admin'" class="nav-item p-1 {{cssLib}}">
                    <button type="button" class="btn btn-link nav-link" [ngClass]="{'active': cssLib === 'selected'}" (click)="navTo('companylib')">Packages</button>
                </li>
                <li *ngIf="userService.userRole === 'admin'" class="nav-item p-1 {{cssUser}}">
                    <button type="button" class="btn btn-link nav-link" [ngClass]="{'active': cssUser === 'selected'}" (click)="navTo('companyusrmgr')">{{userService.currentCompany.CustomerTitle}}s</button>
                </li>
                <li class="nav-item p-1 {{cssCal}}">
                    <button type="button" class="btn btn-link nav-link" [ngClass]="{'active': cssCal === 'selected'}" (click)="navTo('calendar')">Calendar</button>
                </li>
                <div class="d-block d-sm-block d-md-none">
                    <li *ngIf="userService.userRole === 'admin'" class="nav-item p-1">
                        <button class="btn btn-link nav-link" (click)="navTo('companysettings')">Settings</button>
                    </li>
                    <li class="nav-item p-1">
                        <button class="btn btn-link nav-link" (click)="logOut()">Logout</button>
                    </li>
                </div>
            </ul>
        </div>
    </nav>
</div>
