import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { OrderService } from '../services/order.service';
import { FormService } from '../services/form.service';
import { saveAs } from 'file-saver';
import { PdfGen } from '../models/order';

@Component({
  selector: 'app-formtitle',
  templateUrl: './formtitle.component.html',
  styleUrls: ['./formtitle.component.scss']
})
export class FormtitleComponent implements OnInit {
  @Input() titleDes: string;
  @Input() orderId: number;
  @Input() canDownload: string;
  pdfFileName: string;
  preventDownload: boolean = true;

  constructor(private http : HttpClient, public dialog: MatDialog, public orderService: OrderService,
              public formService: FormService) { }

  ngOnInit(): void {
    //console.log("can:" + this.canDownload);
    if(this.canDownload == "true") this.preventDownload = false;
    //this.preventDownload = this.canDownload ? false : true;
    //console.log("prevent:" + this.preventDownload);
    //console.log('pg idx: '  + this.formService.pageIdx);
  }

  showLine(idx: number, isLeft: boolean) : string {
    let rtn = '-show';

    if(idx == 0 && isLeft) rtn = '';
    if(idx == this.formService.pages.length - 1 && isLeft == false) rtn = '';

    if(rtn != '') {
      if(idx < this.formService.pageIdx) rtn += ' ft-page-color-done';
      if(idx > this.formService.pageIdx) rtn += ' ft-page-color-next';
      if(idx == this.formService.pageIdx && isLeft == true) rtn += ' ft-page-color-done';
      if(idx == this.formService.pageIdx && isLeft == false) rtn += ' ft-page-color-next';
    }

    return rtn;
  }

  isPast(idx: number) : boolean {
    return (idx < this.formService.pageIdx);
  }

  dotColor(idx: number): string {
    let rtn = 'done';

    if(idx > this.formService.pageIdx) rtn = 'next';
    if(idx == this.formService.pageIdx) rtn = 'now';

    return rtn;
  }

  onInvite() {

  }
  
  onFax() {
    this.http.get(`${environment.apiUrl}/Mail/fax/` + this.orderService.currentOrderId)
      .toPromise().then(res => {
    });
  }
  
  onOpenPdf() {
    //const fileURL = `${environment.apiUrl}/PdfGen/` + this.orderService.currentOrderId;
    //console.log('gen url is: ' + fileURL);
    //let pdfFile = '17_20220725.pdf';
    //console.log('pdf is ' + pdfFile);

    let ctUrl = `${environment.apiUrl}/PdfGen/gen/` + this.orderService.currentOrderId + '/0';
    this.http.get(ctUrl).toPromise().then(res => {
      let rtn = res as PdfGen;
      if(rtn.ResultCode == 1) {
         let url = `${environment.apiUrl}/PdfGen/showpdf/` + rtn.FileName;
         this.orderService.getPdf(url).subscribe(data => {
           saveAs(data, rtn.FileName);
         });
      } else {
        console.log('error generating pdf: ' + rtn.ErrorMessage);
      }
    });
  }


  private handlePdfData(data: string) {
    //console.log(data);
    this.pdfFileName = data;
    window.open(this.pdfFileName, '_blank');
  } 
}

