export class WorkWell {
}

export class WnWCompany {
    Company_Id: number;
    Company_Name: string;
    Form_Api_Code: string;
    Active_Ind: string;
}

export class Employee {
    Employee_Id: number;
    Home_Address_Id: number;
    Social_Security_Num: string;
    Birth_Date: Date;
    Location_Id: number;
    Department_Id: number;
    Hire_Date: Date;
    Work_State_Id: number;
    Days_Worked_Week: number;
    Hours_Worked_Day: number;
    Full_Time_Ind: string;
    Supervisor_Address_Id: number;
    HR_Address_Id: number;
}

export class HomeAddress {
    Address_Id: number;
    First_Name: string;
    Middle_Initial: string;
    Last_Name: string;
    Email: string;
    Title: string;
    Phone_Number: string;
    Address_Line1: string;
    Address_Line2: string;
    City: string;
    State_Id: string;
    Zip_Code: string;
    Gender: string;
}

export class CaseMatrix {
    matrixFull: string;
    matrix2: string;
    matrix3: string;
    matrix4: string;
    matrix7: string;
    matrix8: string;
    matrix10: string;
    hrs12: number;
    fdow: Date;
    estrt: Date;
    /*BirthDueDate: Date;
    ChildNoSelfCare: string;*/

    constructor() {
        this.matrix2 = 'S';
        this.matrix3 = '';
        this.matrix4 = '';
        this.matrix7 = '';
        this.matrix8 = '';
        this.matrix10 = '';
    }
}
