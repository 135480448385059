<div class="container">
    <div class="spinner-container" *ngIf="loading">
        <h4 class="loading-text">Creating Expidoc account...</h4>
        <mat-spinner></mat-spinner>
    </div>
    <div *ngIf="!loading">
        <h4 class="header">Create your Expidoc account</h4>
        <mat-horizontal-stepper linear #stepper class="stepper-container">
            <mat-step [stepControl]="userDetails">
                <form [formGroup]="userDetails" class="step-fields" (ngSubmit)="onContinue('user')">
                <ng-template matStepLabel>User Details</ng-template>
                <div class="form-group mb-3">
                    <label for="firstName" class="form-label">First Name</label>
                    <input type="text" formControlName="FirstName" class="form-control" [ngClass]="{ 'is-invalid': userContinue && fUser.FirstName.errors }"/>
                    <div *ngIf="userContinue && fUser.FirstName.errors" class="invalid-feedback">
                        <div *ngIf="fUser.FirstName.errors.required">First Name is required</div>
                    </div>
                </div>
                <div class="form-group mb-3">
                    <label for="lastName" class="form-label">Last Name</label>
                    <input type="text" formControlName="LastName" class="form-control" [ngClass]="{ 'is-invalid': userContinue && fUser.LastName.errors }" />
                    <div *ngIf="userContinue && fUser.LastName.errors" class="invalid-feedback">
                        <div *ngIf="fUser.LastName.errors.required">Last Name is required</div>
                    </div>
                </div>
                <div class="form-group mb-3">
                    <label for="email" class="form-label">Email</label>
                    <input type="text" formControlName="Email" class="form-control" [ngClass]="{ 'is-invalid': userContinue && fUser.Email.errors }" />
                    <div *ngIf="userContinue && fUser.Email.errors" class="invalid-feedback">
                        <div *ngIf="fUser.Email.errors.required">Email is required</div>
                        <div *ngIf="fUser.Email.errors.email">Must be a valid email</div>
                        <div *ngIf="fUser.Email.errors.emailExists">An account with this email already exists</div>
                    </div>
                </div>
                <div class="form-group mb-3">
                    <label for="phoneNumber" class="form-label">Phone Number</label>
                    <input type="text" mask="000-000-0000" placeholder="123-456-7890" formControlName="PhoneNumber" class="form-control" [ngClass]="{ 'is-invalid': userContinue && fUser.PhoneNumber.errors }" />
                    <div *ngIf="userContinue && fUser.PhoneNumber.errors" class="invalid-feedback">
                        <div *ngIf="fUser.PhoneNumber.errors.required">Phone Number is required</div>
                        <div *ngIf="fUser.PhoneNumber.errors.minlength">Must be a valid phone number</div>
                    </div>
                </div>
                <div class="form-group mb-3">
                    <label for="password" class="form-label">Password</label>
                    <input type="password" formControlName="Password" class="form-control" [ngClass]="{ 'is-invalid': userContinue && fUser.Password.errors }" />
                    <div *ngIf="userContinue && fUser.Password.errors" class="invalid-feedback">
                        <div *ngIf="fUser.Password.errors.required">Password is required</div>
                        <div *ngIf="fUser.Password.errors.minlength">Password must be at least 6 characters</div>
                    </div>
                </div>
                <div class="form-group mb-3">
                    <label for="passwordConfirmed" class="form-label">Confirm Password</label>
                    <input type="password" formControlName="PasswordConfirmed" class="form-control" [ngClass]="{ 'is-invalid': userContinue && fUser.PasswordConfirmed.errors }" />
                    <div *ngIf="userContinue && fUser.PasswordConfirmed.errors" class="invalid-feedback">
                        <div *ngIf="fUser.PasswordConfirmed.errors.required">Confirm Password is required</div>
                        <div *ngIf="fUser.PasswordConfirmed.errors.confirmedValidator">Confirm Password must match Password</div>
                    </div>
                </div>
                <div class="form-group d-grid">
                    <button class="btn btn-primary rounded-pill m-t-30" matStepperNext>Continue</button>
                    <div class="have-account">Have an account?<a href="/login" class="login">Login</a></div>
                </div>
                </form>
            </mat-step>
            <mat-step [stepControl]="companyDetails">
                <form [formGroup]="companyDetails" class="step-fields" (ngSubmit)="onContinue('company')">
                    <ng-template matStepLabel>Company Details</ng-template>
                    <div class="form-group">
                        <label for="companyName" class="form-label">Company Name</label>
                        <input type="text" formControlName="CompanyName" class="form-control" [ngClass]="{ 'is-invalid': companyContinue && fCompany.CompanyName.errors }" />
                        <div *ngIf="companyContinue && fCompany.CompanyName.errors" class="invalid-feedback">
                            <div *ngIf="fCompany.CompanyName.errors.required">Company Name is required</div>
                        </div>
                    </div>
                    <div class="form-group d-grid">
                        <button class="btn btn-primary rounded-pill m-t-30" matStepperNext>Continue</button>
                        <div class="have-account">Have an account?<a href="/login" class="login">Login</a></div>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <form (ngSubmit)="onContinue('disclaimer')">
                    <ng-template matStepLabel>Disclaimer</ng-template>
                    <div class="form-group">
                        <!--embed src="../../assets/Disclaimer.pdf" type="application/pdf" width="500px" height="400px"/-->
                        <div>
                            <br/>
                            <p>Please read this disclaimer ("disclaimer") carefully before purchasing a service package from Expidoc.</p>
                            <br/>
                            <p>The content and technology displayed on Expidoc’s website, mobile applications and portal access points, are the intellectual property of Expidoc. You may not reuse, republish, reproduce or reprint such content without our written consent.</p>
                            <br/>
                            <p>All packets and forms produced will be considered approved by the purchaser.  All determinations for what packets and forms are provided will be determined exclusively by the the Purchaser’s guidance and approval during setup and the input of purchaser’s users.   While the information on this website has been verified to the best of our abilities, we cannot guarantee that there are no mistakes or errors.</p>
                            <br/>
                            <p>Purchaser understands that determinations for eligibility for FMLA, Short Term Disability, Accommodations and/or Workers’ Compensation shall remain the full responsibility of the Purchaser.  Purchaser shall maintain sole discretion over any and all employment action against any employee.  Purchaser recognizes that all decisions regarding its employees’ employment status are decisions of Purchaser exclusively. Purchaser agrees to seek legal counsel prior to making any adverse employment determinations to the extent that Purchaser believes it is necessary.</p>
                            <br/>
                            <p>The purchase of services from Expidoc will not be deemed to create a partnership, joint venture, agency relationship or other association between the parties hereto.  Expidoc will not be responsible in any respect for any employment actions taken by Purchaser.</p>
                            <br/>
                            <p>Notwithstanding anything to the contrary, Expidoc shall not be liable, whether under contract, negligence, strict liability, or other legal or equitable theory, for any amounts relating the to Purchaser’s use of Expidoc service platform.</p>
                        </div>
                        <div class="form-group d-grid step-fields">
                                <button class="btn btn-primary rounded-pill m-t-30" matStepperNext>Accept</button>
                                <button class="btn btn-outline-primary rounded-pill mt-2" matStepperPrevious>Decline</button>
                            <div class="have-account">Have an account?<a href="/login" class="login">Login</a></div>
                        </div>
                    </div>
                </form>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Payment</ng-template>
                <div class="step-fields">
                    <div class="text-center">
                        <h5>We use Intuit QuickBooks to manage payments</h5>
                        <img class="quickbooks" src="../../assets/quickbooks-logo.svg" alt="QuickBooks Logo"/>
                    </div>
                    <div class="text-center" *ngIf="(!payContinue)">
                            <p>Clicking Continue will open a new browser tab or window</p>
                    </div>
                    <div class="form-group d-grid">
                        <button class="btn btn-primary rounded-pill m-t-30" *ngIf="(!payContinue)" matStepperNext (click)="onSubmit()">Continue</button>
                        <button class="btn btn-outline-primary rounded-pill mt-2" *ngIf="(!payContinue)" matStepperPrevious>Back</button>
                        <div class="have-account" *ngIf="(payContinue)"><br><br><b>Welcome to Expidoc!</b> You will receive a welcome email and full access to Expidoc once your payment is confirmed</div>
                        <button class="btn btn-primary rounded-pill m-t-30" *ngIf="(payContinue)" (click)="onHome()">Finished</button>
                    </div>
                </div>
            </mat-step>
        </mat-horizontal-stepper>
    </div>
</div>
