import { Component, OnInit, Input } from '@angular/core';
import { OrderFormCustomer } from '../models/order';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from '../services/order.service';

@Component({
  selector: 'app-orderform-smry',
  templateUrl: './orderform-smry.component.html',
  styleUrls: ['./orderform-smry.component.scss']
})
export class OrderFormSmryComponent implements OnInit {
  @Input() order: OrderFormCustomer;
  @Input() index: number;

  constructor(public orderService: OrderService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
  }

  setOrder()
  {
    this.orderService.setOrder(this.index);
    this.router.navigate(['orderform/edit']);
  }

}
