import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { OrderForm, OrderFormCustomer, Taker, TakerGroupOrder, TakerGuest } from '../models/order';
import { AlertService } from './alert.service';
import { CompanyService } from './company.service';
import { FormService } from './form.service';
import { OrderService } from './order.service';
import { UserService } from './user.service';
import { Package, PackageSetting } from '../models/form';
import { Injectable } from '@angular/core';
import { ToastService } from './toast.service';
import { AuthenticationService } from './authentication.service';
import { GenericString } from '../models/customer';

@Injectable({ providedIn: 'root' })
export class LoaderService {
  taker: Taker;
  order: OrderForm;
  page: string;
  errCtr: number = 0;
  maxTrys: number = 5;

  constructor(public orderService: OrderService, private router: Router, private authService: AuthenticationService,
    private http: HttpClient, public formService: FormService, public companyService: CompanyService,
    private alertService: AlertService, public userService: UserService, public toastService: ToastService) {}

  checkTO(orderId: string, takerId: string, page: string) {
    let turl = `${environment.apiUrl}/Login/CheckOrder/` + orderId + '/' + takerId;
    //console.log('checking TO for ' + orderId + ' '  + takerId + ' : ' + turl);
    this.http.get(turl).toPromise().then(res => {
      let t = res as GenericString;
      //console.log('taker is ' + JSON.stringify(t));
      if(t.ValueDes != '') {
        this.authService.setToken(t.ValueDes);
        this.loadPage(orderId, takerId, page);
      } else {
        //console.log('checkTO error');
        this.errCtr++;
        if(this.errCtr >= this.maxTrys) {
          this.router.navigate(['home']);
        } else {
          this.alertService.error('We could not locate you in the system');
          //this.toastService.addToast('We could not locate you in the system');
        }
      }
    });
  }

  loadPage(orderId: string, takerId: string, page: string) {
    //console.log('loading page');
    this.companyService.logoLink = 'thanks';
    this.page = page;
    if(orderId && takerId) {
      //this.companyService.logoLink = 'guest/' + orderId.padStart(5, '0') + takerId.padStart(5, '0');
      this.companyService.logoLink = 'guest/' + this.padLeft(orderId, '0', 5) + this.padLeft(takerId, '0', 5);

      this.loadTaker(takerId);
    }
  }

  loadTaker(takerId: string) {
    //console.log('loading taker');
    let turl = `${environment.apiUrl}/Taker/` + takerId;
    this.http.get(turl).toPromise().then(res => {
      this.taker = res as Taker;
            
      this.userService.currentTaker = this.taker;
      this.orderService.currentTaker = this.taker;
      this.companyService.setUser(this.taker.TakerName);
      this.companyService.headerTitle = 'Welcome ' + this.taker.TakerName;

      let tgurl = `${environment.apiUrl}/Taker/Guest/` + takerId;
      this.http.get(tgurl).toPromise().then(res => {
        let g = res as TakerGuest;
  
        this.formService.setTaker(g);
        this.orderService.currentGuest = g;

        if(this.taker.StartDate == null) {
          this.taker.StartDate = new Date();
          this.http.put(`${environment.apiUrl}/Taker/` + this.taker.TakerId, this.taker).toPromise().then(res => {
            this.loadOrder(this.taker.OrderId);
          });
        } else {
          this.loadOrder(this.taker.OrderId);
        }
      });
    },
    error => {
      this.alertService.error(error);
    });
  }

  loadOrder(orderId: number) {
    //console.log('loading order');

    let ourl = `${environment.apiUrl}/OrderForm/` + orderId;
    this.http.get(ourl).toPromise().then(res => {
      this.order = res as OrderForm;

      this.orderService.setCurrentOrderForm(this.order);

      let ocurl = `${environment.apiUrl}/OrderForm/OCustomer/` + orderId;
      this.http.get(ocurl).toPromise().then(res => {
        let o = res as OrderFormCustomer[];
        this.orderService.setCurrentOrder(o[0]);

        this.companyService.headerTitle2 = 'for ' + o[0].PackageDetail + ' ' + o[0].CustomHeader;
    
        this.loadCustomer(this.order.CustomerId);
      });
    });
  }

  loadCustomer(custId: number) {
    //console.log('loading customer');
    let ourl = `${environment.apiUrl}/Customer/` + custId;
    this.http.get(ourl).toPromise().then(res => {
      this.loadPackage();
    });
  }

  loadPackage() {
    //console.log('loading package');
    let purl = `${environment.apiUrl}/Package/` + this.order.PackageId;
    this.http.get(purl).toPromise().then(res => {
      let p = res as Package;
      this.orderService.setCurrentPackage(p);

      let psurl = `${environment.apiUrl}/PackageSetting/package/` + this.order.PackageId;
      this.http.get(psurl).toPromise().then(res => {
        this.orderService.packageSettings = res as PackageSetting[];

        let gurl = `${environment.apiUrl}/TakerGroup/Order/` + this.order.OrderId + '/' + this.taker.TakerId;

        this.http.get(gurl).toPromise().then(res => {
          
          let pages = res as TakerGroupOrder[];

          this.formService.usePages = true;
          this.formService.setPages(pages);

          this.companyService.logoLink = 'pages';

          let nurl = `${environment.apiUrl}/Taker/Next/` + this.order.OrderId + '/' + this.taker.StartSeq;

          this.http.get(nurl).toPromise().then(res => {
            let n = res as Taker[];
            if(n.length > 0) {
              this.formService.nextTaker = n[0];
              //console.log('nav to pages');
              this.router.navigate([this.page]);
            }
          });
        },
        error => {
          this.alertService.error(error);
        });
      });
    });
  }

  padLeft(text:string, padChar:string, size:number): string {
    return (String(padChar).repeat(size) + text).substr( (size * -1), size) ;
  }
}

export class TakerToker {
  taker: Taker;
  token: string;
}