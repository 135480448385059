import { Component, OnInit } from '@angular/core';
import { OrderService } from '../services/order.service';
import { Router, ActivatedRoute } from '@angular/router';
import { OrderForm } from '../models/order';
import { AlertService } from '../services/alert.service';
import { NgForm } from '@angular/forms';
import { AuthenticationService } from '../services/authentication.service';
import { FormService } from '../services/form.service';
import { formatDate } from '@angular/common';

@Component({
  selector: 'app-order-edit',
  templateUrl: './order-edit.component.html',
  styleUrls: ['./order-edit.component.scss']
})
export class OrderEditComponent implements OnInit {
  orderForm: OrderForm;
  loading = false;
  submitted = false;

  constructor(public orderService: OrderService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    public formService: FormService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.formService.refreshList(false);

    if(this.orderService.currentOrder) {
      //var ord = JSON.stringify(this.orderService.currentOrder);
      //console.log(ord);
      //this.orderForm = JSON.parse(ord);
      this.orderForm = this.orderService.currentOrder;
    } else {
      this.orderForm = new OrderForm();
      this.orderForm.OrderId = 0;
      this.orderForm.CustomerId = this.authenticationService.currentCustomer.CustomerId;
      this.orderForm.OrderDate = new Date();
      this.orderForm.PackageId = 0;
      this.orderForm.PaidFlg = 'Y';
      this.orderForm.LastFieldId = 0;
      
      /*var ofrm = '{"OrderId":0,"CustomerId":' + this.authenticationService.currentCustomer.CustomerId + 
                 ',"OrderDate":"' + formatDate(new Date(), 'MM/dd/yyyy', 'en') +
                 'T00:00:00","PackageId":0,"PackageCost":0.0,"PaidFlg":"N","LastFieldId":0}';

      //console.log(ofrm);
      this.orderForm = JSON.parse(ofrm);*/
    }
    //console.log(this.orderForm.PaidFlg);
  }

  onSubmit(form:NgForm) {
    this.alertService.clear();
    this.submitted = true;

    // stop here if form is invalid
    if (form.invalid) {
      return;
    }

    this.loading = true;

    //const result: Customer = Object.assign({}, this.customerForm.value);
    this.orderService.putOrderFormDetail(this.orderForm).subscribe(
        res => {
        this.alertService.success('Saved');
        //this.orderService.setCurrentUser(JSON.parse(JSON.stringify(this.customer)));
        this.submitted = false;
        this.loading = false;

        //this.orderService.setGroup(1);
        //this.router.navigate(['orderform/group']);
          },
      error => {
        this.alertService.error(error);
      });
  }

  onNext() {
    this.orderService.setGroup(1);
    if(this.orderForm.PaidFlg == "Y") {
      this.orderService.setGroup(1);
      this.router.navigate(['orderform/1']);
    } else {
      this.router.navigate(['orderform/pay']);
    }
  }
  
  onReset() {
    this.orderForm = JSON.parse(JSON.stringify(this.orderService.currentOrder));
  }

  onCancel() {
    this.router.navigateByUrl('/dashboard/' + this.orderService.dashReturnId);
  }
}
