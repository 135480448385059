<app-appheader></app-appheader>
<div class="container">
    <div class="row">
        <app-formtitle titleDes="{{orderService.currentOrderTitle}}" orderId="{{orderService.currentOrderId}}" canDownload="{{orderService.currentCanDownload}}"></app-formtitle> 
    </div>

    <!-- button mat-button (click)="openDialog()">Open dialog</button -->




    <router-outlet></router-outlet>
</div>

