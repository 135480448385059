import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ToastService {
  toasts: any[] = [];

  addToast(toast: any) {
    this.toasts.push(toast);
    setTimeout(() => {
      this.toasts.pop();
    }, 5500);
  }
}