import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogService } from 'src/app/services/dialog.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { Subscription, CompanySubscription } from 'src/app/models/customer';

@Component({
  selector: 'app-dialog-subscription',
  templateUrl: './dialog-subscription.component.html',
  styleUrls: ['./dialog-subscription.component.scss']
})
export class DialogSubscriptionComponent implements OnInit {
  subscriptions: Subscription[];
  companySubscription: CompanySubscription;
  isFormChanged: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: CompanySubscription, private http: HttpClient, public userService: UserService, public dialogService: DialogService,
    public dialogRef: MatDialogRef<DialogSubscriptionComponent>) { }

  ngOnInit(): void {
    this.isFormChanged = false;
    this.subscriptions = new Array<Subscription>();
    this.companySubscription = this.data as CompanySubscription;

    this.http.get(`${environment.apiUrl}/Subscription/`).subscribe(res => {
      this.subscriptions = res as Subscription[];
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onUnsubscribe() {
    this.companySubscription.CancelRequest = 'Y';
    this.onUpdate();
  }

  onUpdate() {
    let url = `${environment.apiUrl}/CompanySubscription/` + this.companySubscription.CompanySubscriptionId;
    this.http.put(url, this.companySubscription).subscribe(res => {
      //console.log('subscription updated ' + url + ':' + JSON.stringify(this.companySubscription) + ' == ' + res);
    },
      error => { this.dialogService.openMessageDialog('Update Subscription Error', error) }
    );

    this.dialogRef.close();
  }

  onFormChange() {
    this.isFormChanged = true;
  }

}
