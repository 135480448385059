import { Component, OnInit } from '@angular/core';
import { OrderService } from '../services/order.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-orderform',
  templateUrl: './orderform.component.html',
  styleUrls: ['./orderform.component.scss']
})
export class OrderFormComponent implements OnInit {
  orderId: number;

  constructor(public orderService:OrderService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.orderId = this.orderService.currentOrderId;
  }

  /*openDialog() {
    const dialogRef = this.dialog.open(ModalPdfComponent);

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }*/
}
