import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FieldData, FieldDataCols, FormFieldDataChange, FormListItem, Package } from '../models/form';
import { FormFieldComponent } from '../form-field/form-field.component';
import { OFormData, OForms, OrderFormFieldData, Taker, TakerGroup, TakerGroupOrder, TakerGuest } from '../models/order';
import { BehaviorSubject, Observable } from 'rxjs';
import { ExternalLists } from '../helpers/external-lists';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  public usePages: boolean = false;
  packages: Package[];
  public items: FieldData[];
  public cols: FieldDataCols[];
  fields: FormFieldComponent[];
  pages: TakerGroupOrder[];
  allPages: TakerGroupOrder[];
  orderData: OrderFormFieldData[];
  pageIdx: number = 0;
  lastData: OForms;
  taker: TakerGuest;
  nextTaker: Taker;
  externalListId: number = 0;
  sysliststcd: Array<FormListItem>;
  sysliststnam: Array<FormListItem>;
  sysliststcdnam: Array<FormListItem>;

  // for field controller observers
  private outputSubject: BehaviorSubject<FormFieldDataChange> = new BehaviorSubject(null); 
  output : Observable<FormFieldDataChange> = this.outputSubject.asObservable();

  constructor(private http: HttpClient) {
    this.sysliststcd = new Array<FormListItem>();
    this.sysliststnam = new Array<FormListItem>();
    this.sysliststcdnam = new Array<FormListItem>();
    this.orderData = new Array<OrderFormFieldData>();

    let extLists = new ExternalLists(this.http, 0);
    extLists.getWQList(this.sysliststcd, 'state', 'code', 'code');
    extLists.getWQList(this.sysliststnam, 'state', 'name', 'name');
    extLists.getWQList(this.sysliststcdnam, 'state', 'code', 'name');
  }

  fieldUpdated(data: FormFieldDataChange){
    this.outputSubject.next(data);
  }

  refreshList(force: boolean) {
    var needload = true;
    if(this.packages) {
      if(this.packages.length > 0 && !force) needload = false;
    }
    if(needload) {
      this.http.get(`${environment.apiUrl}/Package`).toPromise().then(res => this.packages = res as Package[]);
    }
  }

  setItems(i: FieldData[]) {
    //console.log('*************setting fields');
    let f = i;
    let row = new Array<FieldDataCols>();
    let col = new Array<FieldData>();

    if(f.length > 0) {
      for(let a = 0; a < f.length; a++) {
        if(f[a].FieldType == 'checkbox' || f[a].FieldType == 'list' || f[a].FieldType == 'radio') {
          //console.log('list ' + f[a].FieldId + ' is ' + f[a].ListValues);
          if(f[a].ListValues.substring(0, 1) == '!') {
            let eh = new ExternalLists(this.http, this.externalListId);
            f[a].ListItems = eh.getList(f[a].ListValues, f[a].ListDisplays);
          } else {
            let v = f[a].ListValues.split('|');
            let d = f[a].ListDisplays.split('|');

            let ary = new Array<FormListItem>();

            for(let b = 0; b < v.length; b++) {
              let sv = v[b];
              let sd = d[b];
              //console.log('frm svc list ' + b + ' ' + sv + ' ' + sd);

              let n = new FormListItem(sv, sd);
              //console.log(JSON.stringify(n));

              ary.push(n);
            }
            
            f[a].ListItems = ary;
          }
        }

        if(f[a].ColSeq == 0) {
          if(col.length > 0) {
            let rc = new FieldDataCols();
            rc.Columns = col;
            row.push(rc);

            col = null;
            col = new Array<FieldData>();
          }
        }

        f[a].ItemIndex = a;
        col.push(f[a]);
      }

      if(col.length > 0) {
        let rcf = new FieldDataCols();
        rcf.Columns = col;
        row.push(rcf);
      }
    }

    //console.log(JSON.stringify(row));
    this.items = f;
    this.cols = row;
  }

  setTaker(taker: TakerGuest) {
    this.taker = taker;
  }

  setPages(pages: TakerGroupOrder[]) {
    //console.log("pages set # =  " + pages.length);
    //console.log('set pages:' + JSON.stringify(pages));
    this.allPages = pages;
    
    if(this.orderData) {
      if(this.orderData.length > 0) this.filterPages();
    }
    
    this.pageIdx = 0;
  }

  filterPages() {
    this.pages = new Array<TakerGroupOrder>();

    for(let a = 0; a < this.allPages.length; a++)
    {
      //console.log('filter ' + this.allPages[a].GroupTitle + ' ' + this.allPages[a].ShowIfField); // + ' = ' + this.allPages[a].ShowIfValue);
      if(this.allPages[a].ShowIfField == '') {
        this.pages.push(this.allPages[a]);
      } else {
        if(this.pagesNeeded(this.allPages[a].ShowIfField)) this.pages.push(this.allPages[a]);
      }
    }
  }

  initFields(len: number) {
    //console.log("init array for " + len);
    this.fields = new Array(len);
  }

  registerField(index: number, crit: FormFieldComponent) {
    //console.log("registering " + index);
    this.fields[index] = crit;
  }

  validateForm(): boolean {
    let rtn = true;

    for(let i = 0; i < this.fields.length; i++) {
      if(this.fields[i].formField.PdfField != 'n/a') {
        let qrtn = this.fields[i].validateField();
        //console.log("field validated:" + qrtn + ' for ' + this.fields[i].formField.PdfField);
        if(!qrtn) {
          rtn = false;
          // i = this.fields.length;
        }
      }
    }

    //console.log("form validated:" + rtn);
    return rtn;
  }

  getValues(ordId: number): OForms {
    //console.log("getvalues starting");
    let rtn = new OForms();
    rtn.answers = [];

    //console.log("getvalues looping:" + this.fields.length);
    for(let i = 0; i < this.fields.length; i++) {

      let ans = this.fields[i].getValue();
      if(ans.OrderId == 0) ans.OrderId = ordId;
      rtn.answers.push(ans);
    }

    this.lastData = rtn;
    //console.log("getvalues looped");
    return rtn;
  }

  pagesNeeded(showIfFields: string) {
    //console.log('checking page with ' + showIfFields)
    let rtn = false;
    let parts = showIfFields.split('|');

    if(parts.length == 1) {
      let halfs = parts[0].split('=');
      rtn = this.pageNeeded(halfs[0], halfs[1]);
    } else {
      let oper = parts[0];
      let rtns = new Array<boolean>;

      for(let a = 1; a < parts.length; a++) {
        let halfs = parts[a].split('=');
        let rn = this.pageNeeded(halfs[0], halfs[1]);
        rtns.push(rn);
      }

      if(oper == 'and') rtn = true;
      for(let a = 0; a < rtns.length; a++) {
        if(rtns[a] && oper == 'or') rtn = true;
        if(!rtns[a] && oper == 'and') rtn = false;
      }
    }

    //console.log('   returned ' + rtn);
    
    return rtn;
  }

  pageNeeded(fieldName: string, cond: string): boolean {
    let rtn = false;
    let conds = cond.split(';');
    let val = '';

    let f = this.orderData.find(f => f.PdfField == fieldName)
    if(f) {
      //console.log('pg needed found data ' + JSON.stringify(f));
      val = f.FieldValue;
    }
    if(val == null) val = '';
    //console.log('checking page for ' + fieldName + ' -' + val);

    if(conds.length > 1) {
      val = val.substring(+conds[1], +conds[2]);
    }

    //console.log('  confirming ' + conds[0] + ' = ' + val);
    if(conds[0].substring(0,1) == '!') {
      if(conds[0].substring(1).toLowerCase() != val.toLowerCase()) rtn = true;
    } else {
      if(conds[0].toLowerCase() == val.toLowerCase()) rtn = true;
    }

    return rtn;
  }

  getvalue(nam: string): string {
    let rtn = '';

    let f = this.fields.find(f => f?.formField.PdfField == nam)
    if(f) rtn = f.formField.FieldValue;

    return rtn;
  }

  setSigDateValue(col: string, val: string) {
    //console.log('setting val for ' + col + ' = ' + val);
    for(let a  = 0; a < this.fields.length; a++) {
      //console.log(this.fields[a].formField.PdfField);
      if(this.fields[a].formField.PdfField == col) {
        //console.log('  found col ' + a + ' settting to ' + val);
        this.fields[a].formField.FieldValue = val;
        this.fields[a].updateDateValue();
        a = this.fields.length;
      }
    }
  }

  setValue(col: string, val: string) {
    //console.log('setting val for ' + col + ' = ' + val);
    for(let a  = 0; a < this.fields.length; a++) {
      //console.log(this.fields[a].formField.PdfField);
      if(this.fields[a].formField.PdfField == col) {
        //console.log('  found col ' + a + ' settting to ' + val);
        this.fields[a].formField.FieldValue = val;
        a = this.fields.length;
      }
    }
  }
}
