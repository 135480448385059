<div class="field-input-col">
    <div class="field-name row-title" *ngIf="(formField.FieldType != 'button')">{{formField.FieldName}} <span class="req-field-{{isRequired}}" *ngIf="(isRequired)">*</span></div>
    <form #frm="ngForm">
        <div *ngIf="(formField.FieldType=='static')" class="form-group ff-txt-lbl{{formField.DataMask}}">
            {{formField.Placeholder}}
        </div>
        <div *ngIf="(formField.FieldType=='text')" class="form-group">
            <input type="text" autocomplete="cc-csc" #FieldValue="ngModel" name="FieldValue" [(ngModel)]="formField.FieldValue" class="form-control" [disabled]="isDisabled"
                [required]="isRequired" maxlength="{{formField.SizeLimit}}" placeholder="{{formField.Placeholder}}" (ngModelChange)="valueChanged($event)"
                [ngClass]="{'is-invalid': checked && !isValid()}"/>
        </div>
        <div *ngIf="(formField.FieldType=='email')" class="form-group">
            <input type="email" email #FieldValue="ngModel" name="FieldValue" [(ngModel)]="formField.FieldValue" class="form-control" [disabled]="isDisabled"
                [required]="isRequired" maxlength="{{formField.SizeLimit}}" placeholder="{{formField.Placeholder}}" (ngModelChange)="valueChanged($event)"
                [ngClass]="{'is-invalid': checked && !isValid()}"/>
        </div>
        <div *ngIf="(formField.FieldType=='mask')" class="form-group">
            <input type="text"autocomplete="cc-csc" #FieldValue="ngModel" name="FieldValue" [(ngModel)]="formField.FieldValue" class="form-control" [disabled]="isDisabled"
                [required]="isRequired" maxlength="{{formField.SizeLimit}}" placeholder="{{formField.Placeholder}}" (ngModelChange)="valueChanged($event)"
                mask="{{maskMask}}" [hiddenInput]="maskHide" [dropSpecialCharacters]="maskDropSC" [validation]="true" [ngClass]="{'is-invalid': checked && !isValid()}"/>
        </div>
        <div *ngIf="(formField.FieldType=='password')" class="form-group input-group">
            <input type="text" autocomplete="cc-csc" #FieldValue="ngModel" name="FieldValue" [(ngModel)]="formField.FieldValue" [class]="fieldTextType ? 'form-control input-text' : 'form-control input-pwd'"
                [required]="isRequired" maxlength="{{formField.SizeLimit}}" placeholder="{{formField.Placeholder}}" (ngModelChange)="valueChanged($event)" [disabled]="isDisabled"
                [ngClass]="{'is-invalid': checked && !isValid()}"/>
            <div class="input-group-append">
                <span class="input-group-text">
                    <i class="fa" [ngClass]="{'fa-eye-slash': !fieldTextType, 'fa-eye': fieldTextType}" (click)="toggleFieldTextType()"></i>
                </span>
            </div>
        </div>
        <div *ngIf="(formField.FieldType=='phone')" class="form-group">
            <input type="tel" autocomplete="cc-csc" #FieldValue="ngModel" name="FieldValue" [(ngModel)]="formField.FieldValue" class="form-control form-control-phone"
                [ngClass]="{'is-invalid': checked && !isValid()}" [required]="isRequired" placeholder="###-###-####" mask="000-000-0000"
                [disabled]="isDisabled" (ngModelChange)="valueChanged($event)"/>
        </div>
        <div *ngIf="(formField.FieldType=='textarea')" class="form-group">
            <textarea type="text" #FieldValue="ngModel" name="FieldValue" [(ngModel)]="formField.FieldValue" class="form-control"
            [required]="isRequired" maxlength="{{formField.SizeLimit}}" rows="{{formField.DataMask}}" [disabled]="isDisabled" [ngClass]="{'is-invalid': checked && !isValid()}"></textarea>
        </div>
        <div *ngIf="(formField.FieldType=='checkbox')" class="form-group row">
            <div class="ff-div-validx-{{!isValid()}}">
                <span class="{{cbxCols}} form-check form-check-inline" *ngFor="let c of formField.ListItems">
                    <input type="checkbox" id="FieldValue" name="FieldValue" class="form-check-input" (change)="cbxChanged(c.ItemValue, $event.target.checked)" value="{{c.ItemValue}}" [disabled]="isDisabled"
                        [ngClass]="{'is-invalid': checked && !isValid()}">
                    <span class="form-check-label">{{c.ItemLabel}}</span>
                </span>
            </div>
        </div>
        <div *ngIf="(formField.FieldType=='date')" class="date-picker-row d-flex align-items-center date-margin">
            <input type="text" #DateValue="ngModel" name="DateValue" [(ngModel)]="ffDate.DateValue" class="form-control-date form-control"
                [required]="isRequired" [matDatepicker]="FieldValueDatepicker" text-date-input [disabled]="isDisabled" (ngModelChange)="dateChanged()" [ngClass]="{'is-invalid': checked && !isValid()}"/>
            <mat-datepicker #FieldValueDatepicker></mat-datepicker>
            <mat-datepicker-toggle class="form-control-datepicker" matSuffix [for]="FieldValueDatepicker"></mat-datepicker-toggle>
        </div>
        <div *ngIf="(formField.FieldType=='radio')" class="form-group row">
            <div class="ff-div-validx-{{!isValid()}}">
                <span class="{{cbxCols}} form-check form-check-inline" *ngFor="let i of formField.ListItems">
                    <input type="radio" id="{{i.ItemValue}}" value="{{i.ItemValue}}" #FieldValue="ngModel" name="FieldValue" [(ngModel)]="formField.FieldValue" class="form-check-input" (ngModelChange)="valueChanged($event)"
                        [required]="isRequired" [disabled]="isDisabled" [ngClass]="{'is-invalid': checked && !isValid()}"/>
                    <label for="{{i.ItemValue}}" class="form-check-label mt-1">{{i.ItemLabel}}</label>
                </span>
            </div>
        </div>
        <div *ngIf="(formField.FieldType=='list')" class="form-group">
            <select #FieldValue="ngModel" name="FieldValue" [(ngModel)]="formField.FieldValue" class="form-select form-control" [required]="isRequired" (ngModelChange)="valueChanged($event)"
                [disabled]="isDisabled" [ngClass]="{'is-invalid': checked && !isValid()}">
                <option *ngIf="!isRequired" value=""></option>
                <option *ngFor="let j of formField.ListItems" [value]="j.ItemValue">
                    {{j.ItemLabel}}
                </option>
            </select>
        </div>
        <div *ngIf="(formField.FieldType=='syslist')" class="form-group">
            <select #FieldValue="ngModel" name="FieldValue" [(ngModel)]="formField.FieldValue" class="form-select form-control" [required]="isRequired" (ngModelChange)="valueChanged($event)"
                [disabled]="isDisabled" [ngClass]="{'is-invalid': checked && !isValid()}">
                <option *ngIf="!isRequired" value=""></option>
                <option *ngFor="let j of syslist" [value]="j.ItemValue">
                    {{j.ItemLabel}}
                </option>
            </select>
        </div>
        <div *ngIf="(formField.FieldType=='time')" class="form-group">
            <input type="text" #FieldValue="ngModel" name="FieldValue" [ngxTimepicker]="timengx" readonly class="form-control" [(ngModel)]="formField.FieldValue"
                placeholder="{{formField.Placeholder}}" [required]="isRequired" [disabled]="isDisabled" [ngClass]="{'is-invalid': checked && !isValid()}">
            <ngx-material-timepicker #timengx></ngx-material-timepicker>
        </div>
        <div *ngIf="(formField.FieldType=='signature')" class="form-group row">
            <div class="col-sm-6">
                <input type="text" autocomplete="cc-csc" #FieldValue="ngModel" name="FieldValue" [(ngModel)]="sigField.FieldValue" class="form-control"
                    required maxlength="{{sigField.SizeLimit}}" placeholder="{{sigField.Placeholder}}" [disabled]="isDisabled"
                    [ngClass]="{'is-invalid': checked && !isValid()}"/>
                <input type="hidden" #FieldValue="ngModel" name="FieldValue" [(ngModel)]="formField.FieldValue">
            </div>
            <div class="col-sm-1">
                <button class="btn btn-sm btn-primary" (click)="signed()" [disabled]="isDisabled">Sign</button>
            </div>
            <div class="col-sm-5">
                <div class="sig-box">
                    <img [src]="sigUrl">
                </div>
            </div>
        </div>
        <div *ngIf="(formField.FieldType=='button')" class="form-group">
            <button class="btn btn-sm btn-primary" (click)="button()" [disabled]="isDisabled">{{formField.FieldName}}</button>
        </div>
        <span class="field-hint">{{formField.FieldDesc}}</span>
    </form>
</div>

