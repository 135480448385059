<div class="custmHdr fixed-top">
    <div class="container hdr-layout">
      <div class="site-logo col-sm-10">
        <a href="index.html"><img class="img-fluid" src="../../assets/logoalt.png"></a>
      </div>
      <div class="col-sm-2 hdr-right-text">
        <a class="hdr-link hdr-bgnd custmHdr2" routerLink="/login">login</a>
      </div>
    </div>
</div>
