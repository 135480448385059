import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { ModalTakerGroupComponent } from '../modal-taker-group/modal-taker-group.component';
import { Taker } from '../models/order';
import { AlertService } from '../services/alert.service';
import { OrderService } from '../services/order.service';

@Component({
  selector: 'app-taker-list',
  templateUrl: './taker-list.component.html',
  styleUrls: ['./taker-list.component.scss']
})
export class TakerListComponent implements OnInit {
  takers: Taker[];
  newTaker: Taker = null;
  showNew: boolean  = false;

  constructor(private http: HttpClient, public orderService: OrderService, public dialog: MatDialog, private alertService: AlertService) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    //console.log(" loading takers for " + this.orderService.currentOrderId);
    this.http.get(`${environment.apiUrl}/Taker/Order/` + this.orderService.currentOrderId).toPromise().then(res => this.takers = res as Taker[]);
  }

  onEdit(idx: number) {
    //console.log("editing:" + idx + " : " + this.takers[idx].TakerId);
    this.orderService.currentTaker = this.takers[idx];
    this.orderService.currentTakerGroup = this.takers[idx].TakerId;
    const dialogRef = this.dialog.open(ModalTakerGroupComponent, { minHeight: '75vh', minWidth: '75vw'});
  }

  onNew() {
    this.newTaker = new Taker();
    this.newTaker.OrderId = this.orderService.currentOrderId;
    //this.newTaker.TakerId = 0;

    this.showNew = true;
  }

  onSubmit() {
    this.orderService.postTaker(this.newTaker).subscribe(
      res => {
          this.alertService.success('Saved');

          this.takers = null;
          this.loadData();

          this.onCancel();
        },
      error => {
        this.alertService.error(error);
    });
  }

  onCancel() {
    this.newTaker = null;
    this.showNew = false;
  }
}
