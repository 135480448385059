<h3>{{formGroup.GroupTitle}} ({{pageSeq}})</h3>
<p>{{formGroup.GroupDesc}}</p>
<app-form-field *ngFor="let fld of formService.items; let i = index" [formFieldObj]="fld" [index] = "i"></app-form-field>

<div class="form-group">
    <button [disabled]="loading" type="button" class="btn btn-secondary" (click)="onBack()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Back
    </button>
    &nbsp;
    <button [disabled]="loading" type="button" class="btn btn-primary" (click)="onNext()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Next
    </button>
    &nbsp;
    <button [disabled]="loading" type="button" class="btn btn-warning" (click)="onSave()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Save
    </button>
    &nbsp;
    <button [disabled]="loading" type="button" class="btn btn-danger" (click)="onCancel()">
        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
        Cancel
    </button>
</div>
