<div class="page-top-nav">
    <div class="container">{{orderService.currentPackage.PackageName}}</div>
</div>

<div class="page-btm-nav container">
    <div class="ft-page-box" *ngFor="let pg of formService.pages; let idx = index">
        <div class="ft-page-box-top">
            <div class="ft-page-box-line{{showLine(idx, true)}}"></div>
            <div class="ft-page-box-dot ft-page-dot-color-{{dotColor(idx)}}">
                <span *ngIf="(!isPast(idx))">{{(idx + 1)}}</span>
                <i class="fas fa-check" *ngIf="(isPast(idx))"></i>
            </div>
            <div class="ft-page-box-line{{showLine(idx, false)}}"></div>
        </div>
        <div class="ft-page-box-title ft-page-txt-color-{{dotColor(idx)}}">{{pg.ProgressTitle}}</div>
    </div>
    <!--div class="ft-page-box">
        <div class="ft-ico-border">
            <div class="ft-page-box-top">
                <div class="ft-ico-box">
                    <i class="far fa-file ft-ico" (click)="onOpenPdf()"></i>
                </div>
            </div>
            <div class="ft-page-box-title ft-page-txt-color-now">View</div>
        </div>
    </div-->
</div>
<!--nav class="navbar navbar-default ff-title-nav">
    <div class="container-fluid">
        <div class="navbar-header">
            <h3>{{titleDes}}</h3>
        </div>
        <div class="nav navbar-nav navbar-right"-->
            <!--a [hidden]="preventDownload" class="ff-title-a" (click)="onInvite()" title="Invite"><i class="far fa-envelope ff-title-fa"></i></a>
            <a [hidden]="preventDownload" class="ff-title-a" (click)="onFax()" title="Fax"><i class="fas fa-fax ff-title-fa"></i></a>
            <i [hidden]="! preventDownload" class="far fa-envelope ff-title-fa ff-title-no"></i>
            <i [hidden]="! preventDownload" class="fas fa-fax ff-title-fa ff-title-no"></i>
            <i [hidden]="! preventDownload" class="far fa-file-pdf ff-title-fa ff-title-no"></i>
            <a [hidden]="preventDownload" class="ff-title-a" (click)="onOpenPdf()" title="View Document"><i class="far fa-file-pdf ff-title-fa"></i></a-->
        <!--/div>
    </div>
</nav>
<hr-->